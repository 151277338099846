<template>
	<div class="content p-4">
		<div>
			<div class="flex justify-between">
				<div>
					<h1 class="title">Create</h1>
				</div>
				<div>
					<button class="btnprimary" @click="addControl = true">Add</button>
				</div>
			</div>
			<input type="file" accept=".xls,.xlsx" class="hidden" ref="file" @change="fileReader($event)" />
			<div class="mt-4" v-if="addControl">
				<div class="p-4">
					<form @submit.prevent="onSubmit">
						<div>
							<div v-for="(controlSet, controlSetKey) in controlSets" :key="controlSetKey">
								<div>
									<div v-for="(control, controlKey) in controlSet.controlsList" :key="controlKey">
										<div class="userPopup">
											<div v-if="addControl" class="fixed inset-0 z-index justify-end items-center flex">
												<div class="relative my-6 w-full max-w-xl">
													<!--content-->
													<div class="border-0 rounded relative flex flex-col w-full bg-white overflow-y-scroll h-screen">
														<!--header-->
														<div class="flex items-start justify-between p-5 rounded">
															<h1>Controls</h1>
															<div class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="addControl = false">
																<!-- <XIcon class="h-5 w-5" /> -->
																<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
															</div>
														</div>
														<!--body-->
														<div></div>
														<div class="">
															<div class="bg-white p-4" v-if="addControl">
																<hr class="border-t-2" />
																<div class="row flex w-full">
																	<div class="col-md-6 w-3/6 p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Number</label>
																		<input type="text" v-model="control.controlNumber" class="inputboxstyling" placeholder="Control Number" />
																		<!-- {{v$.controlSets.$error}}
										{{v$.controlSets.$each.$response.$data[0].controlsList.$each.$data[controlSetKey].controlNumber.required}} -->
																		<!-- <div class="errorContainer" v-if="v$.controlSets.$error">
													<div class="text-error" v-if="!v$.controlSets.$each.$response.$data[0].controlsList.$each.$data[controlSetKey].controlNumber.required">Control Number is required.</div>
													<div class="text-error" v-if="!v$.controlSets.$each.$response.$data[0].controlsList.$each.$data[controlSetKey].controlNumber.alphaNumericAndSpecialCharacters">Invalid input</div>
												</div> -->
																	</div>
																	<div class="col-md-6 w-3/6 p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Title</label>
																		<input type="text" class="inputboxstyling" placeholder="Control Title" v-model="control.controlTitle" />

																		<!-- <div class="errorContainer" v-if="v$.controlSets.$each[controlSetKey].controlsList.$each[controlKey].controlTitle.$dirty">
													<div class="text-error" v-if="!v$.controlSets.$each[controlSetKey].controlsList.$each[controlKey].controlTitle.required">Control Title is required.</div>
													<div class="text-error" v-if="!v$.controlSets.$each[controlSetKey].controlsList.$each[controlKey].controlTitle.alphaNumericAndSpecialCharacters">Invalid input</div>
												</div> -->
																	</div>
																</div>

																<div class="row flex w-full">
																	<div class="col-md-6 w-3/6 p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Family</label>
																		<input type="text" class="inputboxstyling" placeholder="Control Family" v-model="control.controlFamily" />

																		<!-- <div class="errorContainer" v-if="$v.controlSets.$each[controlSetKey].controlsList.$each[controlKey].controlTitle.$dirty">
													<div class="text-error" v-if="!$v.controlSets.$each[controlSetKey].controlsList.$each[controlKey].controlFamily.required">Control Family is required.</div>
													<div class="text-error" v-if="!$v.controlSets.$each[controlSetKey].controlsList.$each[controlKey].controlFamily.alphaNumericAndSpecialCharacters">Invalid input</div>
												</div> -->
																	</div>
																	<div class="col-md-6 w-3/6 p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Priority</label>

																		<select class="inputboxstyling" v-model="control.controlPriority">
																			<option value="" disabled>Select</option>
																			<option value="Critical">Critical</option>
																			<option value="High">High</option>
																			<option value="Medium">Medium</option>
																			<option value="Low">Low</option>
																		</select>
																	</div>
																</div>

																<div class="row flex w-full">
																	<div class="col-md-6 w-3/6 p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Audit Methodology</label>
																		<input type="text" class="inputboxstyling" placeholder="Control Audit Methodology" v-model="control.controlAuditMethodology" />
																	</div>

																	<div class="col-md-6 w-3/6 p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Audit Sucess Criteria</label>
																		<input type="text" class="inputboxstyling" placeholder="Control Audit Sucess criteria" v-model="control.controlAuditSuccessCriteria" />
																	</div>
																</div>
																<div class="row flex w-full">
																	<div class="col-md-6 w-3/6 p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Objective</label>
																		<input type="text" class="inputboxstyling" placeholder="Control Objective" v-model="control.controlObjective" />
																	</div>

																	<div class="col-md-6 w-3/6 p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Type</label>
																		<input type="text" class="inputboxstyling" placeholder="Control Type" v-model="control.controlType" />
																	</div>
																</div>
																<div class="row flex w-full">
																	<div class="col-md-6 w-3/6 p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Class</label>
																		<input type="text" class="inputboxstyling" placeholder="Control Class" v-model="control.controlClass" />
																	</div>

																	<div class="col-md-6 w-3/6 p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Type</label>
																		<input type="text" class="inputboxstyling" placeholder="Control Type" v-model="control.controlType" />
																	</div>
																</div>
																<div class="row flex w-full">
																	<div class="col-md-6 w-3/6 p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Maintenance Task</label>
																		<input type="text" class="inputboxstyling" placeholder="Control MaintenanceTask" v-model="control.MaintenanceTask" />

																		<!-- <div class="errorContainer" v-if="$v.controlSets.$each[controlSetKey].controlsList.$each[controlKey].controlTitle.$dirty">
													<div class="text-error" v-if="!$v.controlSets.$each[controlSetKey].controlsList.$each[controlKey].controlFamily.required">Control Family is required.</div>
													<div class="text-error" v-if="!$v.controlSets.$each[controlSetKey].controlsList.$each[controlKey].controlFamily.alphaNumericAndSpecialCharacters">Invalid input</div>
												</div> -->
																	</div>
																	<div class="col-md-6 w-3/6 p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Policies</label>
																		<input type="text" class="inputboxstyling" placeholder="Control Policies" v-model="control.controlPolicies" />
																	</div>
																</div>

																<div class="row flex flex-wrap w-full">
																	<div class="col-md-12 w-full p-2.5">
																		<label class="controllabel font-normal text-sm mb-1">Control Gudiance</label>
																		<input type="text" class="inputboxstyling" placeholder="Control Gudiance" v-model="control.controlGuidance" />
																	</div>
																</div>

																<div class="flex justify-center items-center">
																	<button class="btnprimary mb-3" type="submit">Save</button>
																	<!-- <button class="btnprimary mb-3 mr-3" type="button" v-if="typeOfAction !== 'template'" @click="addNewControl(controlSetKey, controlKey)">Add Control</button>
												<button class="btnprimary mb-3" type="button" v-if="typeOfAction !== 'template'" @click="deleteControl(controlSetKey, controlKey)">Delete Control</button> -->
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div v-if="addControl" class="opacity-40 fixed inset-0 z-50 bg-black"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<loader v-if="showLoader" />
	</div>
</template>
<style  scoped>
.z-index {
	z-index: 999999;
}
.height {
	height: 620px;
}
</style>
<script lang="ts">
import { required, helpers } from '@vuelidate/validators';
const alphaNumericAndSpecialCharacters = helpers.regex('alphaNumAndDot', /^[a-z\d-_&. ]*$/i);
import loader from '../../components/loader.vue';
// import { PencilIcon, PlusCircleIcon, TrashIcon, CheckCircleIcon } from '@heroicons/vue/solid';
import _ from 'lodash';
import XLSX from 'xlsx';
import { defineComponent } from 'vue';
import { toast } from '@/main';
export default defineComponent({
	data(): any {
		return {
			isLoading: false,
			toggleSourceInput: false,
			stepId: 1,
			currentStep: 1,
			modelControlObject: {
				controlNumber: '',
				controlPriority: '',
				controlTitle: '',
				controlClass: '',
				controlFamily: '',
				controlObjective: '',
				controlGuidance: ''
			},
			addControl: false,
			controlSets: [
				{
					controlSource: 'CUSTOM',
					controlsList: [
						{
							controlNumber: '',
							controlPriority: '',
							controlTitle: '',
							controlClass: '',
							controlFamily: '',
							controlObjective: '',
							controlGuidance: '',
							showData: false
						}
					]
				}
			],
			mandatoryKeys: ['controlSource', 'controlClass', 'controlPriority', 'controlFamily', 'controlNumber', 'controlTitle', 'controlObjective'],
			fullKeys: ['controlSource', 'controlClass', 'controlPriority', 'controlFamily', 'controlNumber', 'controlTitle', 'controlObjective', 'controlGuidance']
		};
	},
	components: {
		// PencilIcon,
		// PlusCircleIcon,
		// TrashIcon,
		// CheckCircleIcon,
		loader
	},
	validations: {
		controlSets: {
			$each: helpers.forEach({
				controlsList: {
					$each: helpers.forEach({
						controlNumber: { required },
						controlPriority: { required },
						controlTitle: { required, alphaNumericAndSpecialCharacters },
						controlClass: { required, alphaNumericAndSpecialCharacters },
						controlFamily: { required, alphaNumericAndSpecialCharacters },
						controlObjective: {}
					})
				}
			})
		}
	},
	mounted() {
		this.openFileModal();
	},
	computed: {
		typeOfAction(): any {
			return this.$route.params.type;
		},
		prepareData(): any {
			let allControls: any[] = [];
			_.each(this.controlSets, (set: { controlsList: any[]; controlSource: any }) => {
				allControls.push(
					...set.controlsList.map((obj: any) => ({
						...obj,
						controlSource: set.controlSource
					}))
				);
			});
			return allControls;
		}
	},
	methods: {
		onSubmit() {
			// set all fields to touched
			// this.v$.$touch();
			// // stop here if form is invalid
			// if (this.v$.$invalid) return;
			// var obj = {};
			// delete obj._id;
			// obj.controlSource = this.controlSets[controlSetKey].controlSource;
			// obj.controlsList = [this.controlSets[controlSetKey].controlsList[controlKey]];
			this.addControls();
		},
		onReset() {
			// reset form validation errors
			this.$v.$reset();
			// reset form data
			const initialData = this.$options.data.call(this);
			Object.assign(this.$data, initialData);
		},

		openFileModal() {
			if (this.typeOfAction == 'csv') {
				this.$refs.file.click();
			}
		},
		// fileReader(oEvent: { target: { files: any[] } }) {
		// 	var oFile = oEvent.target.files[0];
		// 	var reader = new FileReader();
		// 	reader.onload = (e: any) => {
		// 		var data = e.target.result;
		// 		data = new Uint8Array(data);
		// 		var workbook = XLSX.read(data, { type: 'array' });
		// 		var first_sheet_name = workbook.SheetNames[0];
		// 		var worksheet = workbook.Sheets[first_sheet_name];
		// 		var jsonObj = XLSX.utils.sheet_to_json(worksheet, {
		// 			raw: true,
		// 			header: this.fullKeys,
		// 			range: 1
		// 		});
		// 		let total = jsonObj.length;
		// 		jsonObj.forEach((element: any) => {
		// 			element.showData = false;
		// 		});
		// 		let valid = 0;
		// 		var jsonArray = this.groupBy(jsonObj, 'controlSource');
		// 		var controlSet: any[] = [];
		// 		Object.keys(jsonArray).forEach((key) => {
		// 			var temp: any = {};
		// 			temp.controlSource = key;
		// 			//  temp.selected = false;
		// 			temp.controlsList = [];
		// 			temp.controlsList = jsonArray[key];
		// 			valid = valid + temp.controlsList.length;
		// 			controlSet.push(temp);
		// 		});
		// 		this.controlSets = controlSet;
		// 		this.$toasted.show(`Imported ${total} rows, Valid rows: ${valid}, Ignored rows: ${total - valid}`, {
		// 			theme: 'toasted-primary',
		// 			action: {
		// 				text: 'Close',
		// 				onClick: (e: any, toastObject: { goAway: (arg0: number) => void }) => {
		// 					toastObject.goAway(0);
		// 				}
		// 			}
		// 		});
		// 	};
		// 	reader.readAsArrayBuffer(oFile);
		// },
		groupBy(arr: any[], key: string) {
			return arr.reduce((storage: { [x: string]: any[] }, item: { [x: string]: any }) => {
				if (_.every(this.mandatoryKeys, _.partial(_.has, item))) {
					var group = item[key];
					// item.selected = false;
					storage[group] = storage[group] || [];
					storage[group].push(item);
				}
				return storage;
			}, {});
		},
		addNewControl(setPos: string | number, listPos: number) {
			this.modelControlObject.controlSource = this.controlSets[setPos].controlSource;
			this.modelControlObject.showData = false;
			this.controlSets[setPos].controlsList.splice(listPos + 1, 0, {
				...this.modelControlObject
			});
		},
		deleteControl(setPos: string | number, listPos: number) {
			if (this.controlSets[setPos].controlsList.length > 1) this.controlSets[setPos].controlsList.splice(listPos, 1);
			this.$toasted.error(`Deleted`, {
				theme: 'toasted-primary',
				duration: 4000
			});
		},

		async addControls() {
			try {
				for (const controlSet of this.controlSets) {
					for (const control of controlSet.controlsList) {
						var payload = {
							// controlSource: controlSet.controlSource,
							controlsList: [control]
						};
						await this.$http.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_CONTROLS_API_PATH}/api/root/controlset/${this.$route.params.id}/update`, payload.controlsList).then((result: any) => {
							toast.success(`Saved Successfully`, {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
						});

						console.log('sss', payload);
					}
				}
				// this.$router.back();
			} catch (e) {
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		}
		// async addControls() {
		// 	try {
		// 		for (const controlSet of this.controlSets) {
		// 			for (const control of controlSet.controlsList) {
		// 				var payload = {
		// 					controlSource: controlSet.controlSource,
		// 					controlsList: [control]
		// 				};
		// 				await this.$http.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_MONITOR_API_PATH}/api/org/controlset/create`, payload).then((result: any) => {
		// 					toast.success(`Saved Successfully`, {
		// 						timeout: 1000,
		// 						closeOnClick: true,
		// 						closeButton: 'button',
		// 						icon: true
		// 					});
		// 				});
		// 			}
		// 		}
		// 		// this.$router.back();
		// 	} catch (e) {
		// 		toast.error(`Something went wrong`, {
		// 			timeout: 1000,
		// 			closeOnClick: true,
		// 			closeButton: 'button',
		// 			icon: true
		// 		});
		// 	}
		// }
	}
});
</script>