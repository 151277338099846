import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/tooltip.svg'


const _withScopeId = (n: any) => (_pushScopeId("data-v-63afddc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "tooltipContainer",
    style: _normalizeStyle(_ctx.styling),
    class: "bg-white manualStyle absolute text-xs z-50 pointer-events-none border-lightgrey border"
  }, [
    _createElementVNode("img", {
      class: _normalizeClass([_ctx.imageClass, "absolute text-black h-3.5 w-3"]),
      src: _imports_0,
      alt: ""
    }, null, 2),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.tipText), 1)
  ], 4))
}