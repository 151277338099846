

<template>
	<div class="animsition page-error page-error-404 layout-full">
		<div class="page vertical-align text-center">
			<div class="page-content vertical-align-middle">
				<header>
					<h1 class="animation-slide-top text-9xl font-normal text-center">{{ pageData.errorNumber }}</h1>
					<p class="mb-7 text-3xl uppercase text-lightgrey">{{ pageData.mainDescription }}</p>
				</header>
				<p class="error-advise mb-6 text-lightgrey">{{ pageData.description }}</p>
				<a class="btn btn-primary btn-round" href="/">{{ pageData.redirectText }}</a>
			</div>
		</div>
	</div>
</template>
<style  scoped>
</style>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
	data():any {
		return {
			pageData: {
				errorNumber: 0,
				mainDescription: '',
				description: '',
				redirectText: ''
			}
		};
	},
	props: ['errorResult'],
	mounted: function () {
		if (this.$route.query.code == 400) {
			this.pageData.errorNumber = 400;
			this.pageData.mainDescription = 'Bad request';
			this.pageData.description = 'Organization does not exist';
			this.pageData.redirectText = 'GO TO HOME PAGE';
		} else if (this.$route.query.code == 403) {
			this.pageData.errorNumber = 403;
			this.pageData.mainDescription = 'Forbidden';
			this.pageData.description = 'Organization is not active.Please contact system adminstrator';
			this.pageData.redirectText = 'GO TO HOME PAGE';
		} else {
			this.pageData.errorNumber = 404;
			this.pageData.mainDescription = 'Page Not Found';
			this.pageData.description = 'Looks like you are lost!!!';
			this.pageData.redirectText = 'GO TO HOME PAGE';
		}
	}
});
</script>