<template>
  <div>
    <div class="styleEditorContent" ref="editorContainer"></div>
    <div class="flex justify-end mt-3">
        <button @click="addComment" class="mr-2" :class="disableStatus() ? 'btnprimary' : 'btndisabled pointer-events-none'">Add</button>
        <button @click="clearComment" :class="disableStatus() ? 'btnprimary' : 'btndisabled pointer-events-none'">Cancel</button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { emitter } from '@/main';
import _ from 'lodash';
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default defineComponent({
  name: 'QuillEditor',
  data() {
    return {
      quill: null,
      content: '<p><br></p>',
      dummyContent: '<p><br></p>'
    };
  },
  mounted() {
    let quillShortEditor = new Quill(this.$refs.editorContainer, {
      theme: 'snow',
      placeholder: 'Compose an epic...',
      modules: {
        toolbar: [
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          [{ 'list': 'ordered' }, { 'list': 'bullet' }]
        ]
      }
    });
    this.quill = quillShortEditor
    this.quill.root.innerHTML = this.content;
    this.dummyContent = this.content
    this.quill.on('text-change', () => {
      this.content = this.quill.root.innerHTML;
      this.$emit('input', this.content);
    });
  },
  watch: {
    value(val) {
      if (val !== this.quill.root.innerHTML) {
        this.quill.root.innerHTML = val;
      }
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  methods:{
    disableStatus(){
        let disableStatusCondition;
        if (this.content == this.dummyContent) {
            disableStatusCondition = false;
        } else {
            disableStatusCondition = true;
        }
        return disableStatusCondition;
    },
    addComment(){
        let addedComments = {
            comments: this.content
        }
        emitter.emit('addedComments', addedComments);
        this.clearComment();
    },
    clearComment(){
        // this.content = this.dummyContent
        this.content = '<p><br></p>';
        this.dummyContent= '<p><br></p>';
        this.quill.root.innerHTML = this.content;
    }
  }
});
</script>

<style scoped>
/* Add any custom styles for your editor here */
</style>