<template>
  <div class="mb-5 mx-5 mt-3">
    <div class="mb-1">
      <breadCrumbs
        :routesArray="routesArray"
      ></breadCrumbs>
    </div>
    <div>
      <div class="flex flex-row justify-between items-center">
        <div class="items-center justify-between flex">
          <div class="title flex items-center w-96">
            <h1 :class="titleLength > 40? 'table-ellipsis':''">{{ presentFindingObject.finding }}</h1>
          </div>
        </div>
        <!-- <div class="flex flex-row" v-if="approvalObj.statusCode == 10401">
          <button
            class="btnprimary items-center btnPrimaryPadding mr-2"
            @click="approveOrReject(10403)"
          >
            Approve
          </button>
          <button
            class="btnprimary items-center btnPrimaryPadding"
            @click="approveOrReject(10410)"
          >
            Reject
          </button>
        </div> -->
        <div class="flex flex-row" v-if="approvalObj.statusCode == 10401 && checkReviewer(presentFindingObject.reviewer)">
          <button class="btnprimary items-center btnPrimaryPadding mr-2" @click="approveOrReject(10403)">Approve</button>
          <button class="btnprimary items-center btnPrimaryPadding" @click="showPopupToReject()">Reject</button>
      </div>
      </div>
      <div class="mt-3">
        <p class="headerText">
          An IT Compliance or information technology Compliance is an
          investigation and evaluation of IT systems, infrastructures, policies,
          and operations. Through IT Compliances, a company can determine if the
          messing T controls protect corporate assets, ensure data integrity and
          align with the organization's business and financial controls.
        </p>
      </div>
    </div>
    <!-- causes are {{ presentFindingObject.causes.length == 0}} -->
    <!-- {{
      presentFindingObject.causes == null ||
      presentFindingObject.causes == undefined
    }} -->
    <div class="w-full minheight">
      <div class="w-full">
        <div class="text-sm">
          <div
            class="mainContainer tabStructure flex flex-row w-full bg-white border rounded-sm mt-5 px-5 pt-5 pb-3 border-lightgrey"
          >
            <div class="w-75 heightFixing pr-5">
              <div class="tabStructureDiv">
                <div
                  class="flex flex-row borderBottom justify-between cursor-pointer tab-container"
                >
                  <div
                    class="pb-1"
                    :class="
                      selectedTabIndex == tab.id
                        ? 'adjust header font-semibold text-sm primary'
                        : 'text-sm text-disabledgrey'
                    "
                    v-for="(tab, tabIndex) in tabHead"
                    :key="tabIndex"
                    @click="toggleTabs(tab)"
                  >
                    <div>
                      <div class="cursor-pointer">{{ tab.displayName }}</div>
                      <div
                        :class="{ progress: selectedTabIndex == tab.id }"
                        class="h-1 p-0.75"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="progressbarB rounded h-1"></div>
              </div>
              <div class="h-full">
                <div
                  class="overflow-y-scroll descriptionToggle"
                  v-if="selectedTabObj.id == 1"
                >
                  <div>
                    <div
                      class="border-b border-lightgrey flex justify-between mb-3 paddingBottomForFindings"
                    >
                      <div class="flex items-center justify-center">
                        <div>
                          <p class="header margining">Finding Information</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <!-- <div v-for="(obj, index) in presentFindingObject" :key="index" class="mt-3">{{ obj }} -->
                    <div class="flex flex-row">
                      <div
                        class="border-t border-l border-b p-2 header w-23 border-lightgrey rounded-tl-md"
                      >
                        Finding ID
                      </div>
                      <div
                        class="border header border-lightgrey w-77 p-2 rounded-tr-md text-sm"
                      >
                        {{ presentFindingObject.findingId }}
                      </div>
                    </div>
                    <!-- <div class="w-full flex flex-row">
                      <div
                        class="border-l w-23 border-b p-2 header border-lightgrey"
                      >
                        Framework
                      </div>
                      <div
                        class="w-27 border-b border-l border-lightgrey text-sm p-2"
                      >
                        {{ presentFindingObject.frameworks }}
                      </div>
                      <div
                        class="border-l w-23 border-b p-2 header border-lightgrey"
                      >
                        Control Number
                      </div>
                      <div
                        class="border-r w-27 border-b border-l border-lightgrey p-2"
                      >
                        <div class="pl-3 flex items-center flex-wrap">
                          <div
                            :class="getBorder()"
                            class="text-xs px-2 py-0.5 font-opensans bg-white font-bold h-auto w-fit flex items-center"
                          >
                            {{ presentFindingObject.frameWorkControl }}
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="flex flex-row">
                      <div
                        class="border-l border-b p-2 header w-23 border-lightgrey"
                      >
                        Finding
                      </div>
                      <div
                        class="border-r border-b border-l border-lightgrey w-77 text-xs p-2 text-justify"
                      >
                        {{ presentFindingObject.finding }}
                      </div>
                    </div>
                    <div class="flex flex-row">
                      <div
                        class="border-l border-b p-2 header w-23 border-lightgrey rounded-bl-md"
                      >
                        Evidence
                      </div>
                      <div
                        class="border-r border-b border-l border-lightgrey w-77 text-xs p-2 text-justify rounded-br-md"
                      >
                        {{ presentFindingObject.findingEvidence }}
                      </div>
                    </div>
                    <!-- <div class="w-full flex flex-row">
                      <div
                        class="border-l w-23 border-b p-2 header border-lightgrey rounded-bl-md"
                      >
                        Finding Category
                      </div>
                      <div
                        class="w-77 border-b border-l border-r border-lightgrey p-2 rounded-br-md"
                      >
                        <div
                          v-if="presentFindingObject.findingCategory === 1"
                          class="flex items-center justify-center rounded w-28 h-5 text-error bg-highColor border-solid border text-sm border-error p-1"
                        >
                          {{
                            getCategory(presentFindingObject.findingCategory)
                          }}
                        </div>
                        <div
                          v-if="presentFindingObject.findingCategory === 2"
                          class="flex items-center justify-center rounded w-28 h-5 text-darkred bg-veryHighColor border-solid border text-sm border-darkred p-1"
                        >
                          {{
                            getCategory(presentFindingObject.findingCategory)
                          }}
                        </div>
                        <div
                          v-if="presentFindingObject.findingCategory === 3"
                          class="flex items-center justify-center rounded w-28 h-5 text-lightgreen bg-veryLowColor border-solid border text-sm border-lightgreen p-1"
                        >
                          {{
                            getCategory(presentFindingObject.findingCategory)
                          }}
                        </div>
                        <div
                          v-if="presentFindingObject.findingCategory === 4"
                          class="flex items-center justify-center rounded w-28 h-5 text-yellow bg-moderateColor border-solid border text-sm border-yellow p-1"
                        >
                          {{
                            getCategory(presentFindingObject.findingCategory)
                          }}
                        </div>
                      </div>
                      <div
                        class="border-l w-23 border-b p-2 header border-lightgrey"
                      >
                        Control Audit Result
                      </div>
                      <div
                        class="border-r w-27 border-b border-l border-lightgrey p-2 rounded-br-md"
                      >
                        <div
                          v-if="presentFindingObject.auditResult === 'Pass'"
                          class="flex items-center justify-center rounded h-5 w-28 text-lightgreen bg-veryLowColor border-solid border border-lightgreen"
                        >
                          {{ presentFindingObject.auditResult }}
                        </div>
                        <div
                          v-if="presentFindingObject.auditResult === 'Fail'"
                          class="flex items-center justify-center rounded h-5 w-28 text-darkred bg-veryHighColor border-solid border border-darkred"
                        >
                          {{ presentFindingObject.auditResult }}
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <!-- </div> -->
                </div>
                <div
                  class="overflow-y-scroll descriptionToggle"
                  v-if="selectedTabObj.id == 2"
                >
                  <div>
                    <div
                      class="border-b border-lightgrey flex justify-between pb-2 mb-3"
                    >
                      <div class="flex items-center justify-center">
                        <div>
                          <p class="header">Cause & Action</p>
                        </div>
                      </div>
                      <div>
                        <div
                          class="buttonposition flex justify-center items-center"
                        >
                          <div
                            class="buttonposition flex justify-center items-center"
                          >
                          <button :class="TaskDetailsDropDown.findingStatus == 10418 || approvalObj.statusCode == 10403 || presentFindingObject.reviewer == userInfo._id ? 'btndisabled disabledTaskEditButtons pointer-events-none' : 'btnprimary taskEditButtons' " @click="openCausePopup()">
                              Add Cause
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-for="(obj, index) in presentFindingObject" :key="index"> -->
                 
                                        <div v-if="presentFindingObject.causes != undefined  && presentFindingObject.tasks == undefined">
                                            <div v-for="(cause, number) in presentFindingObject.causes" class="mb-3" :key="number" >
                                                <div class="border border-lightgrey bg-moderategrey">
                                                    <div class="w-full flex flex-row">
                                                        <div class="w-15 p-3 border-l-4 border-darkred header text-base flex items-center justify-center">Cause for this Finding </div>
                                                                <div class="w-70 p-3 border-x border-lightgrey text-xs text-justify">{{ presentFindingObject.causes[number].cause}}</div>
                                                        <div class="w-15 flex items-center justify-center">
                                                            <div class="flex justify-around w-full">
                                                                <img src="@/assets/editdefault.svg" class="cursor-pointer"  @click="editCauseAndAction(cause)" />
                                                                <img src="@/assets/deleteIcon.svg" class="cursor-pointer" @click="deleteCauseAndAction(cause.causeId, cause.cause)" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul>
                                                    <li v-for="(action, index) in cause.actions" :key="index">
                                                        <div class="border border-lightgrey">
                                                            <div class="w-full flex flex-row">
                                                                <div class="w-15 p-3 border-l-4 border-lightgreen header text-base flex items-center justify-center">Action{{ index + 1 }} for this Finding </div>
                                                                        <div class="w-70 p-3 border-x border-lightgrey text-xs text-justify">{{ action.action }}</div>
                                                                <div class="w-15 p-3 flex flex-row items-center justify-center">
                                                                    <!-- <img src="@/assets/link.svg" class="mr-3 cursor-pointer"/> -->
                                                                    <!-- <img src="@/assets/deleteIcon.svg" class="cursor-pointer"/> -->
                                                                     <div class="flex items-center justify-center rounded h-5 w-full bg-lightgrey border-solid border border-disabledgrey">
                                                                        <div class="font-semibold text-xs text-disabledgrey">
                                                                            Not Started
                                                                        </div>
                                                                     </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                       
                                                        </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div v-else-if="presentFindingObject.tasks != undefined">
                                            <div v-for="(task, number) in finalFindingsTasks" class="mb-3" :key="number" >
                                                <div class="border border-lightgrey bg-moderategrey">
                                                    <div class="w-full flex flex-row">
                                                        <div class="w-15 p-3 border-l-4 border-darkred header text-base flex items-center justify-center">Cause for this Finding </div>
                                                                <div class="w-70 p-3 border-x border-lightgrey text-xs text-justify">{{ number }}</div>
                                                        <div class="w-15 flex items-center justify-center p-3">
                                                          <div class="flex items-center justify-center rounded h-5 w-full evidenceUpload border-solid border border-primary">
                                                            <div class="font-semibold text-xs text-primary">
                                                                Approved
                                                            </div>
                                                          </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-for="(task1, num) in task" :key="num">
                                                <ul>
                                                    <li>
                                                        <div class="border-b border-x border-lightgrey">
                                                            <div class="w-full flex flex-row">
                                                                <div class="w-15 p-3 border-l-4 border-lightgreen header text-base flex items-center justify-center">Action{{ num + 1 }} for this Finding</div>
                                                                        <div class="w-70 p-3 border-x border-lightgrey text-xs text-justify cursor-pointer" @click="goToActionTasks(task1._id)">{{ task1.action }}</div>
                                                                <div class="w-15 p-3 flex flex-row items-center justify-center">
                                                                    <div class="flex items-center justify-center rounded h-5 w-full" :class="colorFindingBg(task1.statusCode)">
                                                                            <div class="text-xs" :class="colorFindingText(task1.statusCode)">
                                                                                {{ getStatusLabel(task1.statusCode) }}
                                                                            </div>
                                                                        </div>
                                                                </div>
                                                            </div>
                                                         </div>  
                                                    </li>
                                                </ul>
                                                </div>
                                               
                                               
 
                                            </div>
                                        </div>
 
                  <!-- </div> -->
                </div>
                <div
                  class="overflow-y-scroll descriptionToggle"
                  v-if="selectedTabObj.id == 3"
                >
                  <div>
                    <div
                      class="border-b border-lightgrey flex justify-between pb-2 mb-2"
                    >
                      <div class="flex items-center justify-center">
                        <div>
                          <p class="header">Comments</p>
                        </div>
                      </div>
                      <div>
                        <div
                          class="buttonposition flex justify-center items-center"
                        >
                          <div
                            class="buttonposition flex justify-center items-center"
                          >
                            <button
                              class="btnprimary w-24 flex taskEditButtons"
                              v-if="oldComments"
                              @click="sortComments()"
                            >
                              <img
                                src="@/assets/sortBy.svg"
                                class="h-3 mr-2.5"
                              />
                              Oldest
                            </button>
                            <button
                              class="btnprimary w-24 flex taskEditButtons"
                              v-if="!oldComments"
                              @click="sortComments()"
                            >
                              <img
                                src="@/assets/sortBy.svg"
                                class="h-3 mr-2.5 rotate-180"
                              />
                              Newest
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <!-- <div class="comment-box w-full">
                        <div class="my-3">
                          <textarea
                            placeholder="Add a Comment"
                            class="w-full text-xs border border-lightgrey rounded-sm p-2"
                            rows="4"
                            v-model="comment"
                            :disabled="approvalObj.statusCode == 10403"
                            @input="handleInput"
                          ></textarea>
                        </div>
                        <div class="flex justify-end">
                          <button
                            @click="addComment"
                            class="mr-2"
                            :class="{
                              btnprimary: !btnDisabled,
                              btndisabled: btnDisabled,
                            }"
                            :disabled="btnDisabled"
                          >
                            Add
                          </button>
                          <button
                            @click="clearComment"
                            :class="{
                              btnprimary: !btnDisabled,
                              btndisabled: btnDisabled,
                            }"
                            :disabled="btnDisabled"
                          >
                            Cancel
                          </button>
                        </div>
                      </div> -->
                      <commentsEditor />
                      <!-- dfghjkl {{ presentFindingObject[0].comments }} -->
                      <div class="comments">
                        <!-- <div v-for="(obj, index) in presentFindingObject" :key="index"> -->
                        <div
                          v-for="(
                            obj, number
                          ) in presentFindingObject.comments"
                          :key="number"
                          class="border border-lightgrey mt-2 rounded-sm flex flex-row p-3"
                        >
                          <div class="w-1/5 mr-5">
                            <div class="header">
                              {{
                                getPersonName(obj.createdBy)
                              }}
                            </div>
                            <div class="fontSize10PX">
                              {{ obj.createdAt }}
                            </div>
                          </div>
                          <div class="w-4/5 text-xs text-justify" >
                             <safeHtml :content="obj.comment"/>
                          </div>
                        </div>
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 5">
                  <div>
                                        <div class="border-b border-lightgrey flex justify-between pb-2">
                      <div class="flex items-center justify-center">
                        <div>
                          <p class="header">WorkLog</p>
                        </div>
                      </div>
                      <div>
                         <div class="buttonposition flex justify-center items-center">
                            <div class="buttonposition flex justify-center items-center">
                                <button class="btnprimary w-24 flex taskEditButtons" v-if="oldHistory" @click="sortHistory()">
                                    <img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
                                  Oldest
                                </button>
                                <button class="btnprimary w-24 flex taskEditButtons" v-if="!oldHistory" @click="sortHistory()">
                                    <img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
                                Newest
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>
                      <div v-for="work in workLogObject.findingHistory" :key="work" class="bg-white p-4 mb-0.5 border-lightgrey border-b w-full">
                                                    <!-- {{ work.additionalInfo }} -->
                                                    
                      <div class="flex">
                        <div v-if="Object.keys(work).includes('createdBy')" class="initials">
                          {{ extractInitials(getPersonName(work.createdBy)) }}
                        </div>
                        <div v-if="Object.keys(work).includes('updatedBy')" class="initials">
                          {{ extractInitials(getPersonName(work.updatedBy)) }}
                        </div>
                        <div class="logContainer w-93">
                          <div class="flex mt-1">
                           <p v-if="Object.keys(work).includes('createdBy')" class="font-semibold text-xs">
                              {{ getPersonName(work.createdBy) }}
                            </p>
                            <p v-else-if="Object.keys(work).includes('updatedBy')" class="font-semibold text-xs">
                              {{ getPersonName(work.updatedBy) }}
                            </p>

                            <p v-if="work.actionEntity == 10301" class="mx-2 text-xs">{{ 'Created the Finding on' }}</p>
                            <p v-else-if="work.statusCode != undefined && work.statusCode == 10418" class="mx-2 text-xs">{{ 'Terminated the Finding on' }}</p>
                            <p v-else-if="work.actionEntity == 10302" class="mx-2 text-xs">{{ 'Updated the Finding on' }}</p>

                            <p v-if="Object.keys(work).includes('createdAt')" class="font-semibold  text-xs">{{ formatTime(work.createdAt) }}</p>
                            <p v-if="Object.keys(work).includes('updatedAt')" class="font-semibold text-xs">{{ formatTime(work.updatedAt) }}</p>
                          </div>

                       <div class="mt-3" v-if="Object.keys(work).includes('additionalInfo')">
                            <div v-if="work.additionalInfo.comment" class="flex">
                                  <div class="font-semibold text-xs pr-2">Comment:</div>
                                  <div class="text-xs text-justify">
                                      <safeHtml :content="work.additionalInfo.comment"/>
                                  </div>
                            </div>
                            <div v-if="work.additionalInfo.cause" class="flex flex-row">
                                <div v-if="work.additionalInfo.action == 10301" class="font-semibold text-xs w-18">Created the Cause :</div>
                                <div v-else-if="work.additionalInfo.action == 10302" class="font-semibold text-xs w-18">Updated the Cause :</div>
                                <div v-else-if="work.additionalInfo.action == 10303" class="font-semibold text-xs w-18">Deleted the Cause :</div>
                                <div class="text-xs w-82 text-justify ml-2">{{ work.additionalInfo.cause}}</div>
                            </div>
                            <div class="flex" v-if="Object.keys(work.additionalInfo).includes('assignee')">
                                <p class="font-semibold text-xs">{{'Assigned the Task To -'}}</p>
                                <p class="mx-2 text-xs text-justify">{{ getPersonName(work.additionalInfo.assignee) }}</p>
                            </div>
                                                                
                            <div class="flex" v-if="Object.keys(work.additionalInfo).includes('approver')">
                                <p class="font-semibold text-xs">{{'Sent Approval for -'}}</p>
                                <p class="mx-2 text-xs text-justify">{{ getPersonName(work.additionalInfo.approver) }}</p>
                            </div>
                            <div class="flex" v-if="work.additionalInfo.approvalStatusCode == 10410">
                                <p class="font-semibold text-xs text-justify">{{'Approval is Rejected'}}</p>
                            </div>
                            <div class="flex" v-if="work.additionalInfo.approvalStatusCode == 10403">
                                <p class="font-semibold text-xs text-justify">{{'Approval is Accepted'}}</p>
                            </div>
                            <div class="flex items-center" v-if="Object.keys(work.additionalInfo).includes('previousStatus')">
                              <div>
                                  <p class="font-semibold text-xs text-justify">{{'Updated The Status :'}}</p>
                              </div>
                              <div class="flex ml-4">
                                <div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorFindingBg(work.additionalInfo.previousStatus)">
                                    <div class="text-xs" :class="colorFindingText(work.additionalInfo.previousStatus)">
                                        {{ getStatusLabel(work.additionalInfo.previousStatus) }}
                                  </div>
                                </div>
                                <div class="mx-4 flex items-center"><img src="@/assets/arrowRight.svg"/></div>
                                <div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorFindingBg(work.additionalInfo.updatedStatus)">
                                    <div class="text-xs" :class="colorFindingText(work.additionalInfo.updatedStatus)">
                                        {{ getStatusLabel(work.additionalInfo.updatedStatus) }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                </div>
                <div
                  class="overflow-y-scroll descriptionToggle"
                  v-if="selectedTabObj.id == 4"
                >
                  <div>
                    <div
                      class="border-b border-lightgrey flex justify-between pb-2"
                    >
                      <div class="flex items-center justify-center">
                        <div>
                          <p class="header">Approvals</p>
                        </div>
                      </div>
                      <div>
                        <div
                          class="buttonposition flex justify-center items-center"
                        >
                          <div
                            class="buttonposition flex justify-center items-center"
                          >
                          <button class="flex" :class="presentFindingObject.reviewer == userInfo._id ||causesLength == true || approvalObj.statusCode == 10401 || approvalObj.statusCode == 10403 ? 'btndisabled disabledTaskEditButtons pointer-events-none' : 'btnprimary taskEditButtons'"  @click="openApprovalpopup()">
                              Send Approval
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div v-for="(approval, number) in presentFindingObject.approvals" :key="number" class="bg-white flex flex-row p-4 mb-0.5 border-lightgrey border-b w-full">
                            <div v-if="approval.createdBy != undefined" class="initials">{{extractInitials(getPersonName(approval.createdBy))}}</div>
                            <div v-if="approval.createdBy != undefined" class="font-semibold mt-1 text-xs">{{ getPersonName(approval.createdBy) }}</div>
                            
                            <div v-if="approval.updatedBy != undefined" class="initials">{{extractInitials(getPersonName(approval.updatedBy))}}</div>
                            <div v-if="approval.updatedBy != undefined" class="font-semibold mt-1 text-xs">{{ getPersonName(approval.updatedBy) }}</div>

                            <div v-if="approval.statusCode == 10401" class="mx-2 mt-1 text-xs"> {{' Requested an approval '}} </div>
                            <div v-else-if="approval.statusCode == 10410" class="mx-2 mt-1 text-xs"> {{' Rejected an approval '}} </div>
                            <div v-else-if="approval.statusCode == 10403" class="mx-2 mt-1 text-xs"> {{' Approved an approval '}} </div>

                            <div v-if="approval.createdAt != undefined" class="font-semibold mt-1 text-xs">{{approval.createdAt}}</div>
                            <div v-if="approval.updatedAt != undefined" class="font-semibold mt-1 text-xs">{{approval.updatedAt}}</div>
                        </div>
                        </div>
                        </div>
                </div>
              </div>
            </div>
            <div
              class="w-25 border taskDetailsRightBar rounded-sm border-lightgrey"
            >
              <div class="m-3">
                <div class="pb-2 border-b border-lightgrey">
                  <h1>Finding Details</h1>
                </div>
                <div class="pt-1.5 overflow-y-scroll thirtyWidthToggle">
                  <div class="flex flex-col mb-2">
                    <label class="controllabel">Finding Status</label>
                   <select
                      class="custom-select cursor-pointer w-full px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
                      :class="changeBackground()"
                      :disabled="
                        true
                      "
                      v-model="TaskDetailsDropDown.findingStatus"
                      @change="
                        updateFindingStatus(TaskDetailsDropDown.findingStatus)
                      "
                    >
                      <option value="" selected disabled hidden>
                        Please select one
                      </option>
                      <option
                        v-for="i in findingStatusData"
                        :value="i.id"
                        :key="i"
                      >
                        {{ i.status }}
                      </option>
                    </select>
                    <!-- <select
                      class="custom-select cursor-pointer w-full px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
                      v-else
                     :disabled="TaskDetailsDropDown.taskStatus == 10403 || presentFindingObject.statusCode == 10403|| presentFindingObject.statusCode == 10418 || presentFindingObject.tasks != undefined"
                      
                      v-model="TaskDetailsDropDown.taskStatus"
                    >
                      <option value="" selected disabled hidden>
                        Please select one
                      </option>
                      <option
                        v-for="i in terminateArray"
                        :value="i.id"
                        :key="i"
                      >
                        {{ i.status }}
                      </option>
                    </select> -->
                  </div>

                  <div>
                    <div class="mb-1.5">
                      <label class="controllabel">Assigned To</label>
                      <multiselect
                        v-model="presentFindingObject.assignee"
                        :options="userOptions"
                        :showNoOptions="false"
                        @search-change="asyncFind"
                        :multiple="false"
                        :close-on-select="true"
                        :disabled="TaskDetailsDropDown.taskStatus == 10403 || presentFindingObject.statusCode == 10403 || presentFindingObject.statusCode == 10418"
                        :clear-on-select="true"
                        label="label"
                        open-direction="bottom"
                        :hide-selected="true"
                        track-by="value"
                        @select="updateAssignedMember(presentFindingObject.assignee)"
                        :searchable="true"
                        :hideArrow="false"
                        placeholder="Assigned-To"
                        deselectLabel=""
                        selectLabel=""
                        class="custom-multiselect"
                      >
                        <template v-slot:noResult>
                          <span>No Members Found</span>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label class="controllabel">Reviewer</label><br />
                    <div class="my-1">
                      {{ getPerson(presentFindingObject.reviewer) }}
                    </div>
                  </div>
                  <div class="mt-3">
                    <label class="controllabel">Team Owner</label><br />
                    <div class="my-1">
                      {{ getAllPersonName(presentFindingObject.teamOwner) }}
                    </div>
                  </div>
                  <div class="mt-3">
                    <label class="controllabel">Team</label><br />
                    <div class="my-1">
                      {{ getTeamName(presentFindingObject.department) }}
                    </div>
                  </div>
                  <div class="mt-3">
                    <label class="controllabel">Entity</label><br />
                    <div class="my-1">
                      {{ getLocation(presentFindingObject.department) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup v-if="showCausePopup" :popUp="showCausePopup">
      <template v-slot:header>
        <div
          class="flex items-center justify-between popupheadingcontainer rounded"
        >
          <h1>Cause and Action</h1>
          <button
            class="float-right text-3xl"
            @click="onCrossbtnForCauseAndAction()"
          >
            <img src="@/assets/cancel.svg" class="cursor-pointer" />
          </button>
        </div>
      </template>
      <div class="formcontainer px-4 pb-4 flex flex-col">
        <div class="row flex flex-row upperspacing">
          <div class="w-full">
            <label class="controllabel">Cause</label>
            <input
              class="inputboxstyling"
              type="text"
              v-model="causeActionObj.cause"
              placeholder="Cause for the Finding"
              :class="{ 'is-invalid': v$.causeActionObj.cause.$error }"
            />
            <div v-if="v$.causeActionObj.cause.$error" class="text-red-500">
              <div
                class="error-text"
                v-if="v$.causeActionObj.cause.required.$invalid"
              >
                Required
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end items-center mt-2">
          <button class="btnprimary" @click="addTextarea()">Add</button>
        </div>
        <label class="controllabel">Actions</label>
        <div v-if="textareas.length == 0" class="text-red-500">
          <div class="error-text">Enter atleast one Action</div>
        </div>
        <div
          class="w-full flex flex-row mb-2"
          v-for="(textarea, index) in textareas"
          :key="index"
        >
          <div class="w-95 mr-5">
            <textarea
              class="inputboxstyling"
              v-model="textarea.action"
              placeholder="Add an action for this Cause"
              rows="3"
              :class="{
                'is-invalid borderRed':
                  v$.textareas.$dirty &&
                  !v$.textareas.$each.$response.$data[index].action.required,
              }"
            ></textarea>
            <!-- {{v$.textareas.$dirty}} -->
            <div
              v-if="
                v$.textareas.$dirty &&
                !v$.textareas.$each.$response.$data[index].action.required
              "
              class="text-red-500"
            >
              <div class="error-text">Required</div>
              <div class="error-text" v-if="textareas.length == 0">
                atleast one Action is needed
              </div>
            </div>
            <div class="error-text" v-if="textareas.length == 0">
              atleast one Action is needed
            </div>
          </div>
          <div class="w-5">
            <div class="flex items-center justify-center h-full">
              <img
                src="@/assets/deleteIcon.svg"
                class="cursor-pointer"
                @click="clickedOnDeleteButton(index)"
              />
            </div>
          </div>
        </div>
        <!-- {{ objDummy }}dummy{{  causeActionObj }} -->
        <!-- causeActionObj {{ causeActionObj }} textareas
        {{ textareas }} textareaEditDummy {{ textareaEditDummy }} -->
        <!-- {{ (JSON.stringify(causeActionObj.actions) != JSON.stringify(objDummy.actions)) }} -->
        <div v-if="!editVariable" class="flex items-center justify-center mt-4">
          <!-- {{
            JSON.stringify(causeActionObj.cause) !=
              JSON.stringify(causeActionObjDummy.cause) ||
            JSON.stringify(textareas) != JSON.stringify(textareasDummy)
          }} -->
          <button
            class="btnprimary mr-2"
            :class="
              JSON.stringify(causeActionObj.cause) !=
                JSON.stringify(causeActionObjDummy.cause) ||
              JSON.stringify(textareas) != JSON.stringify(textareasDummy)
                ? 'btnprimary'
                : 'btndisabled pointer-events-none'
            "
            @click="cancelCauseAction()"
          >
            Cancel
          </button>
          <button
            class="btnprimary"
            :class="
              JSON.stringify(causeActionObj.cause) !=
                JSON.stringify(causeActionObjDummy.cause) &&
              JSON.stringify(textareas) != JSON.stringify(textareasDummy)
                ? 'btnprimary'
                : 'btndisabled pointer-events-none'
            "
            @click="saveCauseAction()"
          >
            Save
          </button>
        </div>
        <div v-if="editVariable" class="flex items-center justify-center mt-4">
          <button
            class="btnprimary mr-2"
            :class="
              JSON.stringify(causeActionObj.cause) !=
                JSON.stringify(objDummy.cause) ||
              JSON.stringify(textareas) !=
                JSON.stringify(causeActionObj.actions)
                ? 'btnprimary'
                : 'btndisabled pointer-events-none'
            "
            @click="cancelEditedCauseAndAction()"
          >
            Cancel
          </button>
          <button
            class="btnprimary"
            :class="
              JSON.stringify(causeActionObj.cause) !=
                JSON.stringify(objDummy.cause) ||
              (JSON.stringify(textareas) !=
                JSON.stringify(causeActionObj.actions) &&
                textareas.length > 0)
                ? 'btnprimary'
                : 'btndisabled pointer-events-none'
            "
            @click="
              updateCauseAndAction(
                causeIdOfCauseAndAction,
                createdAtForUpdate,
                createdByForUpdate
              )
            "
          >
            Update
          </button>
        </div>
      </div>
    </popup>
    <popup v-if="showApprovalPopup" :popUp="showApprovalPopup">
      <template v-slot:header>
        <div
          class="flex items-center justify-between popupheadingcontainer rounded"
        >
          <h1>Approval</h1>

          <button class="float-right text-3xl" @click="onCrossbtn()">
            <img src="@/assets/cancel.svg" class="cursor-pointer" />
          </button>
        </div>
      </template>

      <div class="popup-business">
        <div>
          <div>
            <div class="statuscontainer px-5 pb-5">
              <div class="formcontainer flex flex-col">
                <div class="row flex flex-row upperspacing">
                  <div class="w-1/2">
                    <label class="controllabel">Name of the Authority</label>
                    <!-- <Multiselect
                                                    v-model="taskObj.approval"
                                                    :options="approvingMembers"
                                                    :searchable="true"
                                                    :minChars="1"
                                                    :filterResults="true"
                                                    class="multiselect-custom mt-1"
                                                    :classes="{singleLabelText:'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full'}"
                                                    :canClear='false'
                                                    :caret='false'
                                                    placeholder="Name"
                                                ></Multiselect> -->
                    <!-- <input
                                                    class="inputboxstyling"
                                                    placeholder="Name"
                                                    v-model="taskObj.approval"
                                                /> -->
                    <div class="inputboxstyling" disabled>
                      {{ getPersonName(presentFindingObject.reviewer) }}
                    </div>
                  </div>
                </div>
                <div class="row flex flex-row upperspacing">
                  <div class="w-full">
                    <label class="controllabel"
                      >Justification or Comments for Approving</label
                    >
                    <textarea
                      class="inputboxstyling"
                      placeholder="Comments"
                      v-model="taskObj.approvalComments"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="buttonposition flex justify-center items-center mb-4 mx-0"
          >
            <button
              class="btnprimary mr-2"
              :class="
                taskObj.approvalComments == ''
                  ? 'btndisabled pointer-events-none'
                  : 'btnprimary'
              "
              @click="onCancelbtnAtApproval()"
            >
              Cancel
            </button>
            <button
              class="btnprimary"
              :class="
                taskObj.approvalComments == ''
                  ? 'btndisabled pointer-events-none'
                  : 'btnprimary'
              "
              @click="applyAuthority()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </popup>
    <popup v-if="showRejectPopup" >
      <template v-slot:header>
        <div
          class="flex items-center justify-between popupheadingcontainer rounded"
        >
          <h1>Justification to Reject the Approval</h1>

          <button class="float-right text-3xl" @click="onCrossbtnToCloseRejectionPopup()">
            <img src="@/assets/cancel.svg" class="cursor-pointer" />
          </button>
        </div>
      </template>
      <div class="popup-business">
        <div>
            <div class="statuscontainer px-4 py-4">
              <div class="formcontainer flex flex-col w-full">
                    <label class="controllabel">Justification</label>
                    <textarea
                      class="inputboxstyling"
                      placeholder="Comments"
                      v-model="rejectJustification.justification"/>
              </div>
          </div>
          <div
            class="buttonposition flex justify-center items-center mb-4 mx-0"
          >
            <button
              class="btnprimary mr-2"
              :class="
               rejectJustification.justification == ''? 'btndisabled pointer-events-none' : 'btnprimary'"
              @click="onCancelbtnToReject()"
            >
              Cancel
            </button>
            <button
              class="btnprimary"
              :class="
                rejectJustification.justification == ''? 'btndisabled pointer-events-none' : 'btnprimary'
              "
              @click="approveOrReject(10410)"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>
<style scoped>
 .table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.progress {
  background: #152a43;
  position: relative;
  border-radius: 4px;
  z-index: 2;
}
.progressbarB {
  border: 2px solid #e9e9e9;
  background: #e9e9e9;
  position: relative;
  top: -8px;
  z-index: 1;
}
.numberBorder {
  border-top: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.numberNavy {
  border-left: 1px solid #152a43;
  border-left-width: 2px;
}
.fontSize10PX{
  font-size : 10px;
}
.primaryBackground {
  background-color: rgba(21, 42, 67, 0.23);
}
.paddingBottomForFindings {
  padding-bottom: 10.5px;
}
.thirtyWidthToggle::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  background: transparent;
}
.descriptionToggle {
  height: 97%;
}
.thirtyWidthToggle::-webkit-scrollbar-thumb {
  background: transparent;
}
.thirtyWidthToggle {
  height: calc(100vh - 250px);
}
.descriptionToggle::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  background: transparent;
}
.w-13{
  width:12.5%;
}
.w-87{
  width:87.5%;
}
.descriptionToggle::-webkit-scrollbar-thumb {
  background: transparent;
}
.heightFixing {
  height: calc(100vh - 220px);
  /* height : 64vh; */
}
.tabStructure {
  height: calc(100vh - 165px);
}
.custom-select {
  background-image: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
option {
  background-color: #ffffff;
  color: #000000;
  border-color: pink transparent transparent transparent;
}
.textColorWhite {
  color: white;
}
.labelWidth {
  min-width: 7rem;
}
.pendingEvidence{
    background-color: #8600f040;
}
.evidenceUpload{
    background: rgba(21,39,60,0.24);
}
.margining{
    margin-top : 2.5px;
}
.primary{
	color:#152a43;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";
import { emitter } from "@/main";
import popup from "@/components/popup.vue";
import Multiselect from "vue-multiselect";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
import { toast } from "@/main";
import breadCrumbs from "@/components/breadCrumb.vue";
import commentsEditor from "@/components/commentsEditor.vue";
import safeHtml from "@/components/safeHtml.vue";
export default defineComponent({
  data(): any {
    return {
      selectedTabIndex: 1,
      selectedTabObj: {},
      tabHead: [
        { displayName: "Finding Info", name: "Findings Info", id: 1 },

        { displayName: "Cause&Action", name: "Cause&Action", id: 2 },

        { displayName: "Comments", name: "Comments", id: 3 },

        { displayName: "Approvals", name: "Approvals", id: 4 },

        { displayName: "History", name: "History", id: 5 },
      ],
      findingCategoryData: [
        {
          id: 1,
          value: "Minor",
        },
        {
          id: 2,
          value: "Major",
        },
        {
          id: 3,
          value: "OFI",
        },
        {
          id: 4,
          value: "Observation",
        },
      ],
      findingStatusData: [
        {
          id: 10401,
          status: "Open",
        },
        {
          id: 10415,
          status: "For Review",
        },
        {
          id: 10402,
          status: "In Progress",
        },
        {
          id: 10406,
          status: "Partial",
        },
        {
          id: 10403,
          status: "Closed",
        },
        {
          id: 10408,
          status: 'Approval'
        },
        {
          id: 10419,
          status: 'For Review'
        },
        {
          id: 10418,
          status: "Terminated",
        },
      ],
      terminateArray: [
        {
          id: 10418,
          status: "Terminated",
        },
      ],
      locationObject: [],
      userOptions: [],
      titleLength: 0,
      personObj: "",
      oldComments: true,
      oldHistory: true,
      comment: "",
      comments: [],
      orgUsersList: [],
      allOrgUsers: [],
      optionsUserArray: [],
      btnDisabled: true,
      allEntities: [],
      causesLength: false,
      presentFindingObject: {},
      routesArray: [],
      users: [],
      showApprovalPopup: false,
      finalFindingsTasks: [],
      showCausePopup: false,
      taskObj: {
        approvalComments: "",
      },
      // navIndexNums: { name: "", index: "" },
      approvingMembers: [],
      v$: useVuelidate(),
      causeActionObj: {
        cause: "",
        actions: [],
      },
      textareas: [{ action: "" }] as { action: string }[],
      textareasDummy: [{ action: "" }],
      textareasDummyForActions: [],
      causeActionObjDummy: [],
      causeAndActionSaved: false,
      validationErrors: [],
      causeActionTableData: {},
      selectCauseObj: "",
      statusCodeOfFinding: "",
      workLogObject: [],
      TaskDetailsDropDown: {
        findingStatus: "",
        assigneeDropDown: "",
      },
      approvalObj: {},
      objDummy: {},
      editVariable: false,
      disableSendApprovalButton: false,
      textareaEditDummy: [],
      showRejectPopup : false,
      rejectJustification:{
        justification : ''
      },
    };
  },
  validations() {
    return {
      causeActionObj: {
        cause: { required },
      },
      textareas: {
        $each: helpers.forEach({
          action: { required },
        }),
      },
    };
  },
  computed: {
    ...mapGetters({ userInfo: "userInfo" }),
  },
  components: {
    breadCrumbs,
    popup,
    Multiselect,
    commentsEditor,
    safeHtml
  },
  methods: {
    // getComment(obj: any){
    //     if(obj == undefined){
    //         console.log("no additional info")
    //     }

    //     else{
    //         if(Object.keys(obj).includes('comment')){
    //             return obj.comment
    //         } else if(Object.keys(obj).includes('cause')){
    //             return obj.cause
    //         } else if(Object.keys(obj).includes('actions')){
    //             return obj.actions
    //         }
    // }
    // },
    showPopupToReject(){
      this.showRejectPopup = true;
    },
    extractInitials(name: any) {
      const names = name.split(" ");
      return names
        .map((name: any) => name[0])
        .join("")
        .toUpperCase();
    },
    getPerson(person: any) {
      this.personObj = this.orgUsersList.find((user: any) => {
        return user._id == person;
      });
      if (this.personObj == undefined) {
        return "";
      } else {
        return this.personObj.name;
      }
    },
    getTeamName(team: any) {
      let teamObj = this.users.find((obj: any) => {
        return obj._id == team;
      });
      if (teamObj == undefined) {
        return "";
      } else {
        return teamObj.teamName;
      }
    },
    getLocation(teamId: any) {
      let presentteamObject: any = this.users.find((obj: any) => {
        return obj._id == teamId;
      });
      if (presentteamObject != undefined && presentteamObject.type == 20301) {
        let entityObj = this.allEntities.find((entity: any)=>{return entity.entityType == 1})
        return entityObj ? entityObj.entityName: 'N/A';
      } else {
        if (
          this.presentFindingObject.location == undefined ||
          this.presentFindingObject.location == null
        ) {
          return "N/A";
        } else {
          return this.getLocationName(
            this.presentFindingObject.location[0].entity
          );
        }
      }
    },
    getLocationName(locationId: any) {
      let locationObj = this.locationObject.find((obj: any) => {
        return obj.value == locationId;
      });
      if (locationObj == undefined) {
        let empty = "";
        return empty;
      } else {
        return locationObj.label;
      }
    },
    getStatusLabel(num: any) {
      let result = this.findingStatusData.find((obj: any) => {
        return obj.id == num;
      });
      return result.status;
    },
    asyncFind(query: any) {
      if (query == "") {
        this.userOptions = [];
      } else if (query.length > 1) {
        this.userOptions = this.optionsUserArray.filter((obj: any) => {
          return obj.label.toLowerCase().includes(query.toLowerCase());
        });
      }
    },
    async updateFindingStatus(status: any) {
      let payload: any = { statusCode: status };
      await this.updatePresentFinding(payload, 5);
    },
    async updateAssignedMember(obj: any) {
      let payload: any = { assignee: obj.value };
      await this.updatePresentFinding(payload, 4);
    },
    async toggleTabs(obj: any) {
      this.selectedTabIndex = obj.id;
      this.selectedTabObj = obj;
    },
    sortComments() {
      this.oldComments = !this.oldComments;
      if (this.presentFindingObject.comments != undefined) {
        this.presentFindingObject.comments.sort().reverse();
      }
    },

    sortHistory() {
      this.oldHistory = !this.oldHistory;
      if(this.workLogObject.findingHistory != undefined){
          this.workLogObject.findingHistory.sort().reverse();
      }
    },
    onCrossbtn() {
      this.showApprovalPopup = false;
      this.taskObj.approvalComments = "";
    },
    onCrossbtnToCloseRejectionPopup(){
      this.showRejectPopup = false;
      this.onCancelbtnToReject();
    },
    onCrossbtnForCauseAndAction() {
      this.showCausePopup = false;
        this.cancelCauseAction();
    },
    checkReviewer(){
			console.log("this.userInfo",this.userInfo)
			if(this.presentFindingObject.reviewer == this.userInfo._id){
				return true
			} else if(this.presentFindingObject.reviewer == undefined || this.presentFindingObject.reviewer == null){
				return '-'
			} else {
				return false
			}
		},
    groupBy(arr: any, keyName: any) {
      return arr.reduce((group: any, product: any) => {
          const keyValue = product[keyName];
          group[keyValue] = group[keyValue] ?? [];
          group[keyValue].push(product);
          return group;
      }, {});
    },
    getCategory(num: any) {
      let result = this.findingCategoryData.find((obj: any) => {
        return obj.id == num;
      });
      return result.value;
    },
    onCancelbtnAtApproval() {
      this.taskObj.approvalComments = "";
    },
    onCancelbtnToReject(){
      this.rejectJustification.justification = "";
    },
    openCausePopup() {
      this.editVariable = false;
      this.showCausePopup = true;
    },
    openApprovalpopup() {
      this.showApprovalPopup = true;
    },
    clearComment() {
      this.comment = "";
      this.btnDisabled = true;
    },
    formatTime(time: any) {
      let now = moment(time);
      return now.format("DD-MM-YYYY, hh:mm:ss");
    },
    getBorder() {
      return `numberBorder numberNavy`;
    },
    handleInput() {
      this.btnDisabled = this.comment.length === 0;
    },
    changeBackground() {
      if (this.TaskDetailsDropDown.findingStatus == 10401) {
        return "bg-disabledgrey textColorWhite";
      } else if (this.TaskDetailsDropDown.findingStatus == 10415) {
        return "bg-yellow textColorWhite";
      } else if (this.TaskDetailsDropDown.findingStatus == 10402) {
        return "bg-electricGreen textColorWhite";
      } else if (this.TaskDetailsDropDown.findingStatus == 10406) {
        return "bg-primary textColorWhite";
      } else if (this.TaskDetailsDropDown.findingStatus == 10403) {
        return "bg-lightgreen textColorWhite";
      } else if (this.TaskDetailsDropDown.findingStatus == 10418) {
        return "bg-error textColorWhite";
      } else {
        return "";
      }
    },
    async getallEntities() {
      this.locationObject = [];
      let result: any;
      await this.$http
        .get(
          `${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`
        )
        .then((res: any) => {
            this.allEntities = res.data;
            result = res.data;
        });
      for (var i = 0; i < result.length; i++) {
        this.locationObject.push({
          label: result[i].entityName,
          value: result[i]._id,
        });
      }
    },
    async getAllTeams() {
      this.teamObject = [];
      await this.$http
        .get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`)
        .then((res: any) => {
          this.users = res.data;
        });
      for (var i = 0; i < this.users.length; i++) {
        this.teamObject.push({
          label: this.users[i].teamName,
          value: this.users[i]._id,
        });
      }
    },
    async getallUsers() {
      await this.$http
        .get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`)
        .then((res: any) => {
          this.orgUsersList = res.data;
          this.allOrgUsers = this.orgUsersList;
          this.orgUsersList = this.orgUsersList.filter((user: any) => {
            return user.isActive == true;
          });
          for (var i = 0; i < this.orgUsersList.length; i++) {
            this.optionsUserArray.push({
              label: this.orgUsersList[i].name,
              value: this.orgUsersList[i]._id,
            });
            // if(this.userInfo._id != this.orgUsersList[i]._id){
            this.approvingMembers.push({
              label: this.orgUsersList[i].name,
              value: this.orgUsersList[i]._id,
            });
            // }
          }
        });
    },
    goToActionTasks(taskId:any){
      this.$router.push({ name: 'actionTask', params: { taskId: taskId , findingId : this.$route.params.findingId } });
    },
    // rejectingTheApprovalOnSave(statusCode: any){

    // },
    async approveOrReject(statusCode: any) {
      const payload: any = {
        statusCode: statusCode,
        updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        updatedBy: this.userInfo._id,
      };
      await this.$http
        .post(
          `${process.env.VUE_APP_MONITOR_API_URL}/api/org/finding/${this.$route.params.findingId}/update/approval`,
          payload
        )
        .then((result: { data: any }) => {
          console.log("here it ISSSSSS");
        });
      if (statusCode == 10403) {
        toast.info(`Approval Approved`, {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
        const workflowpayload = [
          {
            findingId: this.$route.params.findingId,
            createdBy: this.userInfo._id,
            createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
        ];
        await this.$http
          .post(
            `${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/compliance/findings/task`,
            workflowpayload
          )
          .then((result: { data: any }) => {
            console.log("here it ISSSSSS, the workflowpayload");
          });
      } else if (statusCode == 10410) {
        toast.info(`Approval Rejected`, {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
      // await this.getAllFindingsTasks();
      await this.getPresentFindingObject();
      await this.getFindingHistoryObject();
      this.onCrossbtnToCloseRejectionPopup()
    },
    async deleteCauseAndAction(causeId : any, cause : any) {
      if (this.approvalObj.statusCode != 10403) {
        const payload: any = {
                cause : cause,
                updatedBy: this.userInfo._id,
                updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
            };
        await this.$http
          .post(
            `${process.env.VUE_APP_MONITOR_API_URL}/api/org/finding/${this.$route.params.findingId}/cause/${causeId}/delete`,payload
          )
          .then(async (result: any) => {

            await this.getPresentFindingObject();
            await this.getFindingHistoryObject();
            toast.info(`Deleted Successfully`, {
            timeout: 1000,
            closeOnClick: true,
            closeButton: "button",
            icon: true,
          });
          });
      }
    },
    editCauseAndAction(obj: any) {
      if (this.approvalObj.statusCode == 10403) {
        this.showCausePopup = false;
      } else {
        this.editVariable = true;
        this.objDummy = { ...obj };
        this.causeIdOfCauseAndAction = this.objDummy.causeId;
        this.createdAtForUpdate = this.objDummy.createdAt;
        this.createdByForUpdate = this.objDummy.createdBy;
        delete this.objDummy.createdAt;
        delete this.objDummy.createdBy;
        delete this.objDummy.causeId;
        this.showCausePopup = true;
        let actions = this.objDummy.actions;
        this.causeActionObj.cause = this.objDummy.cause;
        this.causeActionObj.actions = [...actions];
        this.textareaEditDummy = [...actions];
        this.textareas = [];
        this.objDummy.actions.map((obj: any) => {
          this.textareas.push({ ...obj });
        });
        // this.textareaEditDummy = [...this.objDummy.actions]
        // this.causeActionObj.actions = this.objDummy.actions
      }
    },
    cancelEditedCauseAndAction() {
      this.v$.$reset();
      this.causeActionObj.cause = this.objDummy.cause;
      this.textareas = [];
      this.objDummy.actions.map((obj: any) => {
        this.textareas.push({ ...obj });
      });
      // this.textareas = this.objDummy.actions
    },
    async clickOnReject() {
      const payload: any = {
        statusCode: 10410,
        updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        updatedBy: this.userInfo._id,
      };
      await this.$http
        .post(
          `${process.env.VUE_APP_MONITOR_API_URL}/api/org/finding/${this.$route.params.findingId}/update/approval`,
          payload
        )
        .then((result: { data: any }) => {
          console.log("here it ISSSSSS");
        });
    },
    async getAllFindingsTasks(){
            await this.$http
                .get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/finding/${this.$route.params.findingId}/tasks/get`)
                .then((result: any) => {        
                    console.log('RESULTTTTTT TOOO FOR FINDING',result.data)
                    this.findingsTasksArray = result.data
                   this.finalFindingsTasks =  this.groupBy(this.findingsTasksArray,'cause')
                   console.log('findingsTasksArray',this.findingsTasksArray)
                   console.log('finalFindingsTasks',this.finalFindingsTasks)
                   console.log('finalFindingsTasks TTTTTT',this.finalFindingsTasks.tasks)
                })
        },
    async applyAuthority() {
      this.showApprovalPopup = false;
      const payload: any = {
        assignee: this.presentFindingObject.reviewer,
        comments: this.taskObj.approvalComments,
        createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        createdBy: this.userInfo._id,
      };

      await this.updatePresentFinding(payload, 3);
      await this.getPresentFindingObject();
      this.taskObj.approvalComments = "";
    },
    async getFindingHistoryObject() {
      // /api/org/finding/6684f413e3794c5eb46ee16f/history/get

      await this.$http
        .get(
          `${process.env.VUE_APP_MONITOR_API_URL}/api/org/finding/${this.$route.params.findingId}/history/get`
        )
        .then((result: any) => {
          const mainObject = result.data;
          this.workLogObject = mainObject;
        });
        if(this.workLogObject.findingHistory != undefined){
          this.workLogObject.findingHistory.sort().reverse();
      }
    },

    async addComment() {
      if (this.comment.trim() !== "") {
        const payload: any = {
          comment: this.comment,
          createdAt: new Date(),
          createdBy: this.userInfo._id,
        };

        await this.updatePresentFinding(payload, 2);
        if (this.presentFindingObject.comments != undefined) {
          this.presentFindingObject.comments.sort().reverse();
        }
        this.comment = "";
        // await this.updateFindingTask(1, newComment);
        // this.comments.push(newComment);
        this.comment = "";
        this.btnDisabled = true;
      }
    },

    async getPresentFindingObject() {
      await this.$http
        .get(
          `${process.env.VUE_APP_MONITOR_API_URL}/api/org/finding/${this.$route.params.findingId}/get`
        )
        .then((result: any) => {
          this.presentFindingObject = result.data[0];

          if(this.presentFindingObject.causes==undefined|| this.presentFindingObject.causes.length == 0){
                this.causesLength = true;
            }else {
                this.causesLength = false;
          }

          if (
            this.presentFindingObject.approvals != null ||
            this.presentFindingObject.approvals != undefined
          ) {
            const lastApproval =
              this.presentFindingObject.approvals[
                this.presentFindingObject.approvals.length - 1
              ];
            this.approvalObj = lastApproval;
          } else {
            this.approvalObj = {};
          }
        });
        await this.getAllFindingsTasks()
      this.TaskDetailsDropDown.findingStatus = this.presentFindingObject.statusCode;
    //   let teamLeaderObj: any 
    //         if(Object.keys(this.presentTaskObject).includes('assignee') && this.presentTaskObject.assignee !== undefined){
    //           teamLeaderObj = this.optionsUserArray.find((obj: any) => {
    //             return obj.value == this.presentTaskObject.assignee;
    //           });
    //         } else{
    //           teamLeaderObj = this.optionsUserArray.find((obj: any) => {
    //             return obj.value == this.presentComplianceObject.teamLeader;
    //           });
    //         }
    //         this.presentComplianceObject.teamLeader = teamLeaderObj;
      if (Object.keys(this.presentFindingObject).includes('assignee') && this.presentFindingObject.assignee != undefined) {
        let userObj: any = this.optionsUserArray.find((obj: any) => {
          return obj.value == this.presentFindingObject.assignee;
        });
        this.presentFindingObject.assignee = userObj;
      } else {
        let userObj: any = this.optionsUserArray.find((obj: any) => {
          return obj.value == this.presentFindingObject.reviewer;
        });
        this.presentFindingObject.assignee = userObj;
      }
    },
    getPersonName(person: any) {
      let personObj = this.orgUsersList.find((user: any) => {
        return user._id == person;
      });
      if (personObj == undefined) {
        return "";
      } else {
        return personObj.name;
      }
    },
    getAllPersonName(person: any) {
      let personObj = this.allOrgUsers.find((user: any) => {
        return user._id == person;
      });
      if (personObj == undefined) {
        return "";
      } else {
        return personObj.name;
      }
    },
    async updateCauseAndAction(causeId: any, createdAt: any, createdBy: any) {
      this.v$.$touch();
      let booleanValue = false;
      if (this.textareas.length > 0) {
        booleanValue = !this.textareas.some((obj: any) => obj.action === "");
        if (booleanValue) {
          this.causeActionObj.actions = [...this.textareas];
          const payload: any = {
            cause: this.causeActionObj.cause,
            actions: this.causeActionObj.actions,
            createdBy: createdBy,
            createdAt: createdAt,
            updatedBy: this.userInfo._id,
            updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          };
          await this.$http
            .post(
              `${process.env.VUE_APP_MONITOR_API_URL}/api/org/finding/${this.$route.params.findingId}/cause/${causeId}/update`,
              payload
            )
            .then(async (result: any) => {
              toast.info(`Updated`, {
                timeout: 1000,
                closeOnClick: true,
                closeButton: "button",
                icon: true,
              });
              await this.getPresentFindingObject();
              await this.getFindingHistoryObject();
            });
          // this.cancelEditedCauseAndAction()
          this.showCausePopup = false;
        }
      }
      // this.cancelEditedCauseAndAction()
    },
    async saveCauseAction() {
      this.v$.$touch();
      if (!this.v$.causeActionObj.$invalid && !this.v$.textareas.$invalid) {
        this.causeAndActionSaved = true;
        this.causeActionObj.actions = [...this.textareas];
        // "cause":"This is cause 1",
        // "actions":[
        // { action: 'ACTION 1' },
        // { action: 'ACTION 2' },
        // { action: 'ACTION 3' }
        //  ]
        const payload: any = {
          cause: this.causeActionObj.cause,
          actions: this.causeActionObj.actions,
          createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          createdBy: this.userInfo._id,
        };
        // let payload :any= {...this.causeActionObj};
        // payload.createdAt = new Date();
        // payload.createdBy = this.userInfo._id

        await this.updatePresentFinding(payload, 1);
        // this.causeActionObj = {}
        // this.showCausePopup = false
        this.onCrossbtnForCauseAndAction();
        // this.cancelCauseAction()
      }
    },
    async updatePresentFinding(payload: any, type: any) {
      // delete payload.additionalInfo;
      payload.createdBy = this.userInfo._id;
      payload.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
      if (type == 1) {
        payload.cause = payload.cause;
        // payload.additionalInfo.cause = payload.cause;
      } else if (type == 2) {
        payload.comment = payload.comment;
        // payload.additionalInfo.comment = payload.comment;
      } else if (type == 3) {
        payload.justification = payload.justification;
        // payload.additionalInfo.justification = payload.justification;
    //   }  else if (type == 4) {
        // payload.assignee = payload.assignee;
        // payload.additionalInfo.assignee = payload.assignee;
      } else if (type == 5) {
        payload.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
        payload.createdBy = this.userInfo._id;
        payload.previousStatus = this.presentFindingObject.statusCode;
        payload.statusCode = payload.statusCode;
        payload.updatedStatus = payload.statusCode;
        // payload.additionalInfo.previousStatus = this.presentFindingObject.statusCode;
        // payload.additionalInfo.updatedStatus = payload.statusCode;
      }
      try {
        await this.$http
          .post(
            `${process.env.VUE_APP_MONITOR_API_URL}/api/org/finding/${this.$route.params.findingId}/type/${type}/update`,
            payload
          )
          .then(async (result: any) => {

            toast.info(`Updated`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
            if (type === 4) {
              this.presentFindingObject.statusCode = payload.updatedStatus;
            } else if (type == 5) {
              this.presentFindingObject.assignee = payload.assignee;
            }
            await this.getPresentFindingObject();
            await this.getFindingHistoryObject();
          });
      } catch {
        toast.error(`Error`, {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
      this.presentFindingObject.statusCode = payload.updatedStatus;
    },
    colorFindingText(status: any) {
      if (status == 10401) {
        return ["font-semibold text-disabledgrey"];
      } else if (status == 10415) {
        return ["font-semibold text-yellow"];
      } else if (status == 10403) {
        return ["font-semibold text-lightgreen"];
      } else if (status == 10402) {
        return ["font-semibold mx-2 text-electricGreen"];
      } else if (status == 10406 || status == 10408) {
        return ["font-semibold mx-2 text-primary"];
      } else if (status == 10417) {
        return ["font-semibold mx-2 text-darkred"];
      }else if(status == 10419){
        return ['font-semibold mx-2 text-forReview']
      } 
    },
    colorFindingBg(status: any) {
      if (status == 10401) {
        return ["bg-lightgrey border-solid border border-disabledgrey"];
      } else if (status == 10415) {
        return ["bg-moderateColor border-solid border border-yellow"];
      } else if (status == 10403) {
        return ["bg-veryLowColor border-solid border border-lightgreen"];
      } else if (status == 10402) {
        return ["pendingEvidence border-solid border border-electricGreen"];
      } else if (status == 10406 || status == 10408) {
        return ["evidenceUpload border-solid border border-primary"];
      } else if (status == 10417) {
        return ["bg-veryHighColor border-solid border border-darkred"];
      }else if (status == 10419) {
        return ['bg-forReviewBg border-solid border border-forReview'];
      }
    },
    addTextarea() {
      this.v$.$reset();
      this.textareas.push({ action: "" });
    },
    cancelCauseAction() {
      // this.causeAndActionSaved = false;
      this.causeObjectDummy = { ...this.causeActionObj };
      this.v$.$reset();
      (this.causeActionObj.cause = ""), (this.causeActionObj.actions = []);
      this.textareas = [{ action: "" }];
      //   Reset to initial state with one textarea
    },
    save() {
      this.causeActionObj.actions = [...this.textareas];
      // Optionally, you can perform further actions like sending data to backend or resetting form state
    },
    clickedOnDeleteButton(index: number) {
      this.textareas.splice(index, 1);
    },
  },
  async mounted(){
        emitter.off('addedComments');
        emitter.on('addedComments',(data: any)=>{
            this.comment = data.comments
            this.addComment()
        })
       
    },
  async created() {
    await this.getallUsers();
    await this.getAllTeams();
    await this.getallEntities();
    this.causeActionObjDummy = { ...this.causeActionObj };
    //  this.textareasDummy = {...this.textareas}
    //  this.textareasDummyForActions = {...this.textareasDummy}
    await this.getPresentFindingObject();
    // await this.updateFindingPresentStatus(this.$route.params.findingId)
    await this.getFindingHistoryObject();

    this.teamOwnerName = this.presentFindingObject.reviewer;
    
    this.selectedTabObj = this.tabHead.find(
      (tab: any) => tab.id === this.selectedTabIndex
    );
    this.routesArray = [
      { name: 'Findings', routeName: "complianceFindings" },
      { name: this.presentFindingObject.findingId, routeName: "findings" },
    ];
    if (
      this.presentFindingObject.causes == null ||
      this.presentFindingObject.causes == undefined
    ) {
      this.disableSendApprovalButton = true;
    }
    if (this.presentFindingObject.comments != undefined) {
      this.presentFindingObject.comments.sort().reverse();
    }
    this.titleLength = this.presentFindingObject.finding ? this.presentFindingObject.finding.length:0

  },
});
</script>
 