<template>
	<div class="p-4">
			<h1 class="page-title">Configurations</h1>
		<div class="flex justify-around flex-wrap p-4">
			<div class="m-2 cursor-pointer">
				<div class="card  w-60 min-h-170">
					<div class="card-body p-6 justify-center text-center">
						<router-link :to="'configure/create/custom'" append>
							<div class="text-gray">Create Custom</div>
						</router-link>
					</div>
				</div>
			</div>
			<div class="m-2 cursor-pointer">
				<div class="card w-60 min-h-170">
					<div class="card-body p-6 justify-center text-center">
						<router-link :to="'configure/create/csv'" append>
							<div class="text-gray">Import from CSV</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style  scoped>
</style>
<script lang="ts">
import XLSX from 'xlsx';

import { defineComponent } from 'vue';
export default defineComponent({
	data():any {
		return {
			controlType: false
		};
	},

	methods: {
		controlTypechange() {
			this.controlType != '' ? this.$refs.file.click() : '';
		},
		clickToOpen() {
			this.$refs.file.click();
		},
		// fileReader(oEvent:any) {
		// 	// console.log(this.controlType);
		// 	var oFile = oEvent.target.files[0];
		// 	var reader = new FileReader();
		// 	reader.onload = (e:any) => {
		// 		// console.log(this.controlType);
		// 		var data = e.target.result;
		// 		data = new Uint8Array(data);
		// 		var workbook = XLSX.read(data, { type: 'array' });
		// 		/* DO SOMETHING WITH workbook HERE */
		// 		var first_sheet_name = workbook.SheetNames[0];
		// 		/* Get worksheet */
		// 		var worksheet = workbook.Sheets[first_sheet_name];
		// 		var jsonObj = XLSX.utils.sheet_to_json(worksheet, {
		// 			raw: true,
		// 			header: ['controlNumber', 'description', 'target', 'targetTimePeriod', 'reportingFrequency', 'weightage'],
		// 			range: 1
		// 		});
		// 		var jsonArray = this.groupBy(jsonObj, 'controlNumber');
		// 		var controlSet:any = [];
		// 		Object.keys(jsonArray).forEach((key) => {
		// 			var temp:any = {};
		// 			temp.controlNumber = key;
		// 			temp.kpi = [];
		// 			temp.kpi = jsonArray[key];
		// 			controlSet.push(temp);
		// 		});
		// 		console.log(controlSet);
		// 	};
		// 	reader.readAsArrayBuffer(oFile);
		// },
		groupBy(arr:any, key:any) {
			return arr.reduce(function (storage:any, item:any) {
				if (item.controlNumber) {
					// get the first instance of the key by which we're grouping
					var group = item[key];
					// item.selected = false;

					// set `storage` for this instance of group to the outer scope (if not empty) or initialize it
					storage[group] = storage[group] || [];

					// console.log(item);
					// if(item.controlTitle && item.controlNumber &&
					// item.controlClass && item.controlFamily && item.controlObjective)
					// add this item to its group within `storage`
					storage[group].push(item);

					// return the updated storage to the reduce function, which will then loop through the next
				}
				return storage;
			}, {});
		}
	}
});
</script>