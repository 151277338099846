<template>
    <div id="tooltipContainer" :style="styling" class="bg-white manualStyle absolute text-xs z-50 pointer-events-none border-lightgrey border">
        <img :class="imageClass" src="@/assets/tooltip.svg" alt="" class="absolute text-black  h-3.5 w-3"/>
        <div class="p-2">
            {{ tipText }}
        </div>

    </div>
</template>

<style scoped>
.manualStyle {
    position: fixed;
    max-width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
    props: ['tipText'],
    data(): any {
        return {
            mouseX: 0,
            mouseY: 0,
            rightEdgeThreshold: false,
            bottomEdgeThreshold: false,
            viewportWidth:'',
            viewportHeight:'',
            bottomAlignment:false,
            tipWidth:''
        }
    },
    computed: {
        styling(): any {
            this.viewportHeight = window.innerHeight;
             this.viewportWidth = window.innerWidth;

            this.rightEdgeThreshold = this.mouseX > this.viewportWidth * 0.75;
            this.bottomEdgeThreshold = this.mouseY > this.viewportHeight * 0.85;

            let topPosition = this.mouseY + 10;
            let leftPosition = this.mouseX + 10;

            const container = document.getElementById('tooltipContainer');
            // let tipWidth: any;
            let tipHeight: any;

            if (container) {
                const tip = container.getBoundingClientRect();
                this.tipWidth = tip.width;
                tipHeight = tip.height;
            }

            if (this.rightEdgeThreshold && this.tipWidth > 100) {                
                this.bottomAlignment = false
                leftPosition = this.mouseX - this.tipWidth - 10;
            }

            if (this.bottomEdgeThreshold && tipHeight > 25) {
                this.bottomAlignment= true
                topPosition = this.mouseY - tipHeight - 10;
            }

            return {
                top: `${topPosition}px`,
                left: `${leftPosition}px`
            };
        },
        imageClass():any {
            // return this.mouseX > this.viewportWidth * 0.75 ? 'right-0' : 'left-0';
            if(this.mouseX> this.viewportWidth * 0.75 && this.bottomAlignment == false && this.tipWidth > 100){                
                return 'right-0 bottom-full -mb-1'
            }else if(this.mouseX < this.viewportWidth*0.75 && this.bottomAlignment == false &&this.tipWidth > 100 ){
                return 'left-0 bottom-full -mb-1'
            }else if(this.mouseY > this.viewportHeight * 0.85&& this.mouseX < this.viewportWidth*0.75&& this.bottomAlignment == true){
                return 'bottom-0 rotate-180 -mb-2.5'
            }else if(this.mouseY > this.viewportHeight * 0.85 && this.mouseX > this.viewportWidth*0.75 && this.bottomAlignment == true){
                return 'bottom-0 right-0 rotate-180 -mb-2.5'
            }else if(this.tipWidth < 100){                
                return 'left-0  bottom-full -mb-1'
            }else if(this.tipHeight < 25){
                return 'right-0  bottom-full -mb-1'
            }
            this.bottomAlignment=''
        }
    },
    mounted() {
        document.addEventListener('mousemove', this.updateMousePosition);
    },
    beforeDestroy() {
        document.removeEventListener('mousemove', this.updateMousePosition);
    },
    methods: {
        updateMousePosition(event: any) {
            this.mouseX = event.clientX;
            this.mouseY = event.clientY;
        },
    },
})
</script>