import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "animsition page-error page-error-404 layout-full" }
const _hoisted_2 = { class: "page vertical-align text-center" }
const _hoisted_3 = { class: "page-content vertical-align-middle" }
const _hoisted_4 = { class: "animation-slide-top text-9xl font-normal text-center" }
const _hoisted_5 = { class: "mb-7 text-3xl uppercase text-lightgrey" }
const _hoisted_6 = { class: "error-advise mb-6 text-lightgrey" }
const _hoisted_7 = {
  class: "btn btn-primary btn-round",
  href: "/"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("header", null, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.pageData.errorNumber), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.pageData.mainDescription), 1)
        ]),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.pageData.description), 1),
        _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.pageData.redirectText), 1)
      ])
    ])
  ]))
}