<template>
  <div class="pt-3 pl-5 pr-5">
    <div v-if="isLoading">
      <div class="row w-full justify-between flex" style="align-items: center">
        <h1 class="title">Import Controls</h1>
        <div class="cardbody flex">
          <div class="flex flex-row-reverse items-center">
            <button
              class="flex"
              @click="filterOpen"
              :class="
                tableRow.length == 0
                  ? 'filterButtondisabled pointer-events-none'
                  : 'filterButton'
              "
            >
              Filters
              <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
            </button>
            <button
              class="btnprimary mr-2 flex items-center"
              v-if="this.controlsList.length > 0"
              @click="saveImportedControls()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div class="row flex flex-row justify-between items-center w-full upperspacing flex-wrap" v-if="this.tableRow.length == 0">
        <div class="w-6/12 pr-2">
          <label class="controllabel">Departments</label>
          <Multiselect
            v-model="controlDept.selectedDepartment"
            :options="deptOptions"
            :showNoOptions="false"
            @search-change="asyncFind"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :hide-selected="true"
            open-direction="bottom"
            label="label"
            track-by="value"
            :searchable="true"
            :hideArrow="false"
            placeholder="Departments"
            class="custom-multiselect userSelect"
            :class="{
              'is-invalid borderRed': v$.controlDept.selectedDepartment.$error,
            }"
          >
          </Multiselect>
          <div v-if="v$.controlDept.selectedDepartment.$error" class="text-red-500">
            <div class="error-text" v-if="v$.controlDept.selectedDepartment.required.$invalid">
              Required
            </div>
          </div>
        </div>
        <div class="w-6/12 pl-2">
          <label class="controllabel">Import File</label>
          <div
            class="w-auto bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden inputFieldHeight"
          >
            <!-- <label htmlFor="upload" class="controllabel">Upload File</label> -->
            <input
              type="file"
              ref="fileInput"
              class="hidden"
              id="upload"
              :class="controlDept.selectedDepartment.length == 0 ? 'btndisabled pointer-events-none' : ''"
              @change="fileReader($event, controlDept)"
            />
            <label
              for="upload"
              class="float-right cursor-pointer text-white flex justify-center items-center w-9 h-7 bg-primary" :class="controlDept.selectedDepartment.length == 0 ? 'btndisabled pointer-events-none' : ''"
              ><img src="@/assets/Browse.svg"
            /></label>
            <!-- <div v-if="fileName" class="text-xs p-1">{{ fileName }}</div> -->
          </div>
          <!-- <input
            type="file"
            accept=".xls,.xlsx"
            class="hidden"
            ref="file"
            @change="fileReader($event)"
          /> -->
        </div>
      </div>
      <div>
        <tableData :tableRowData="tableRow" :headersData="headers"></tableData>
      </div>
    </div>
    <loader v-if="showLoader" />
  </div>
</template>
<style  scoped>
.multiselect-tag {
  font-size: 12px;
  font-weight: 600;
}
.inputFieldHeight{
  height: 30px;
}
.multiselect-tag.is-disabled {
  background-color: #059669 !important;
}
.multiselect.is-disabled {
  background-color: white !important;
}
.tbodylast:last-child {
  border-bottom: 1px solid #e9e9e9;
}
::-webkit-scrollbar {
  width: 3px !important;
}
.borderRed {
  border: 1px solid red !important;
}
</style>
<script lang="ts">
import { emitter, toast } from "@/main";
import * as XLSX from "xlsx";
import _ from "lodash";
import { defineComponent } from "vue";
import loader from "../../components/loader.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { groupBy } from "lodash";
import Multiselect from "vue-multiselect";
import tableData from "@/components/tableData.vue";
export default defineComponent({
  data(): any {
    return {
      headers: ["departments", "number", "controlImportTitle", "class", "family", "deleteIcon"],
      filteredControlFamilyOptions: [],

      v$: useVuelidate(),
      showBlock: false,
      showDropdown: false,
      isLoading: false,
      showLoader: false,

      showFilters: false,
      showModal: false,

      dummyList: [],
      controlsList: [],
      departmentsList: [],
      selectedControlForKPI: {},
      kpiListForControl: [],
      selectedControl: {},
      ControlFamilyArray: [],

      filters: {},

      tableRow: [],
      controlFamilies: [],
      nonTableRow: [],
      allDeptOptions: [],
      columnObject: false,
      priority: ["Critical", "High", "Moderate", "Low"],
      priorityArray: [
        { label: "Very Low", id: 1 },
        { label: "Low", id: 2 },
        { label: "Moderate", id: 3 },
        { label: "High", id: 4 },
        { label: "Critical", id: 5 },
      ],
      editObject: {},
      deptOptions: [],
      internalControlsArray: [],
      dummyInternalControls: [],
      dummyNonInternalControls: [],
      controlDepartmentArr: [],
      controlTypeArray: [
        { label: "Preventive", id: 1 },
        { label: "Detective", id: 2 },
        { label: "Corrective", id: 3 },
        { label: "Deterrent", id: 4 },
        { label: "Directive", id: 5 },
        { label: "Recovery", id: 6 },
        { label: "Compensating", id: 7 },
      ],
      controlDept: {
        selectedDepartment: []
      },
      controlFrequency: [
        { label: "Transactional", id: 1 },
        { label: "Daily", id: 2 },
        { label: "Weekly", id: 3 },
        { label: "Monthly", id: 4 },
        { label: "Quarterly", id: 5 },
        { label: "Semi Annualy", id: 6 },
        { label: "Annualy", id: 7 },
        { label: "Ad-Hoc", id: 8 },
      ],
      optionsValue: [
        {
          id: 1,
          value: "KPI",
          route: "/manageKPI",
          presentRoute: "/org/controls/active",
        },
      ],
      dummyControlList: [],
      fullKeys: [
        "controlNumber",
        "controlTitle",
        "controlGuidance",
        "controlClass",
        "controlFamily",
        "controlType",
        "controlPriority"
      ],
    };
  },
  validations: {
    controlDept: {
      selectedDepartment: { required },
    }
  },
  components: {
    loader,
    Multiselect,
    tableData,
  },
  async mounted() {
    emitter.on("*", (type, booleanValue) => {
      if (type == "apps-sidemenubar" || type == "toggle-sidebar") {
        this.showBlock == true
          ? ((this.showBlock = false), (this.showDropdown = false))
          : "";
      } else {
        if (this.showBlock == true) {
          if (this.showDropdown == false) {
            this.showDropdown = true;
          } else {
            this.showDropdown = false;
            this.showBlock = false;
          }
        } else {
          this.showDropdown = false;
        }
      }
    });
    emitter.off("deleteControlFromTable");
    emitter.on("deleteControlFromTable", (data: any) => {
      this.clickedOnDeleteButton(data.index);
    });
  },
  async created() {
    await this.orgFetchControlsInfo();
    await this.orgFetchInternalControlsInfo();
    await this.fetchDepartmentsList();
  },
  computed: {
    disableStatus: function (): any {
      let disableStatusCondition;
      if (this.controlObject._id) {
        if (
          JSON.stringify(this.controlObject) == JSON.stringify(this.editObject)
        ) {
          disableStatusCondition = false;
        } else {
          disableStatusCondition = true;
        }
      } else {
        let val: any = [];
        Object.values(this.controlObject).forEach((value: any) => {
          val.push(value);
        });
        for (let n of val) {
          if (n != "") {
            disableStatusCondition = true;
          }
        }
      }
      return disableStatusCondition;
    },
  },
  methods: {
    asyncFind(query: any) {
      if (query == "") {
        this.allDeptOptions = [];
      } else if (query.length > 1) {
        this.allDeptOptions = this.deptOptions.filter((obj: any) => {
          return obj.label.toLowerCase().includes(query.toLowerCase());
        });
      }
    },
    clickedOnDeleteButton(index: number) {
      this.tableRow.splice(index, 1);
      this.controlsList.splice(index, 1);
    },
    async addControlSource(action: any, controlObject: any) {
      try {
        let payloadForControlIds: any = [];
        let controlRouteIds: any[] = [];
        if(this.dummyNonInternalControls && this.dummyNonInternalControls.length > 0){
          let payload: any = [];
          let framework = 'InternalControls'
          const temp = {
            controlFrameWork: framework,
            controlsList: this.dummyNonInternalControls,
          };
          payload.push(temp);
          // let departmentId = controlObject.departments
          payload[0].controlsList.forEach((obj: any) => {
            if(obj.departments){
              delete obj.departments;
            }
          });
          await this.$http
          .post(
            `${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controlset/create`,
            payload
          )
          .then(async (result: any) => {
            controlRouteIds = Object.values(result.data.insertedIds);
          });
        }
        if((this.dummyInternalControls && this.dummyInternalControls.length > 0) || controlRouteIds && controlRouteIds.length > 0){
          let departmentRouteId = controlObject.value;
          this.dummyInternalControls.forEach((control:any) =>{
            controlRouteIds.push(control._id)
          })
          // if(controlRouteIds != null || controlRouteIds != undefined){
          //   controlRouteIds.map((obj: any)=>{
          //     controlRouteIds.push(obj)
          //   });
          // }
          payloadForControlIds = [
            {
              departmentId: departmentRouteId,
              controlIds: controlRouteIds,
            },
          ];
          await this.$http
            .post(
              `${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/manageDepartments`,
              payloadForControlIds
            )
            .then(() => {
              if (action == "toast") {
                toast.info(`Saved Successfully`, {
                  timeout: 1000,
                  closeOnClick: true,
                  closeButton: "button",
                  icon: true,
                });
              }
              this.$router.push({ name: 'Active-Controls' });
          });
        }  
        // await this.makeTable();
      } catch (e) {
        toast.error(`Something went wrong`, {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
    },
    makeTable() {
      this.tableRow = [];
      if (this.controlsList.length > 0) {
        this.controlsList.forEach((item: any) => {
          let tableObj: any;
          tableObj = {
            departments: this.controlDept.selectedDepartment.label,
            number: item.controlNumber ? item.controlNumber : "N/A",
            controlImportTitle: item.controlTitle ? item.controlTitle : "N/A",
            class: item.controlClass ? item.controlClass : "N/A",
            family: item.controlFamily ? item.controlFamily : "N/A",
          };

          this.tableRow.push(tableObj);
        });

        this.columnObject = false;
      } else {
        this.columnObject = true;
        this.tableRow.push({
          departments: "",
          controlImportTitle: "",
          class: "",
          family: "",
        });
      }
    },
    async orgFetchControlsInfo() {
      this.controlsList = [];
      this.showLoader = true;
      this.isLoading = false;
      await this.$http
        .get(
          `${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active`
        )
        .then((result: { data: any }) => {
          this.isLoading = true;
          this.showLoader = false;
          // result.data.forEach((item: any) => {
          // 	// if (item.isInternalControl) {
          // 		this.controlsList.push(item);
          // 	// }
          // });
          this.dummyControlList = [...result.data];
        })
        .catch((error: any) => {
          this.isLoading = false;
          (this.showLoader = false),
            toast.error(`Save failed with ${error}`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
        });
      //this.makeTable();
    },
    async orgFetchInternalControlsInfo() {
      this.controlsList = [];
      this.showLoader = true;
      this.isLoading = false;
      await this.$http
        .get(
          `${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls?frameWork=InternalControls`
        )
        .then((result: { data: any }) => {
          this.isLoading = true;
          this.showLoader = false;
          // result.data.forEach((item: any) => {
          // 	// if (item.isInternalControl) {
          // 		this.controlsList.push(item);
          // 	// }
          // });
          this.internalControlsArray = [...result.data];
        })
        .catch((error: any) => {
          this.isLoading = false;
          (this.showLoader = false),
            toast.error(`Save failed with ${error}`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
        });
      //this.makeTable();
    },
    async saveImportedControls() {
      let total = this.tableRow.length;
      let valid = 0;
      let controlSets: any = [];
      let newArray: any = []
      let framework = 'InternalControls'
      if (this.internalControlsArray) {
        this.controlsList.forEach((item: any) => {
          let obj1 = this.internalControlsArray.find((control: any) => control.controlNumber === item.controlNumber);
          if (obj1) {
            this.dummyInternalControls.push(obj1);
          } else {
            this.dummyNonInternalControls.push(item);
          }
        });
      }
      let dummyLength = 0
      let dummynonLength = 0

      if(this.dummyInternalControls && this.dummyInternalControls.length > 0){
        dummyLength = this.dummyInternalControls.length
      }
      if(this.dummyNonInternalControls && this.dummyNonInternalControls.length > 0){
        dummynonLength = this.dummyNonInternalControls.length
      }
      controlSets = dummynonLength + dummyLength;
      valid = valid + controlSets;
      await this.addControlSource("toast", this.controlDept.selectedDepartment);
      if (controlSets > 0) {
        toast.info(
          `Imported ${total} rows, Valid rows: ${valid}, Ignored rows: ${
            total - valid
          }`,
          {
            timeout: 1000,
            closeOnClick: true,
            closeButton: "button",
            icon: true,
          }
        );
      } else {
        toast.error(`Something went wrong`, {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
    },
    fileReader(oEvent: any): any {
      // this.v$.$touch();
			// if (!this.v$.controlDept.$invalid) {
        var oFile = oEvent.target.files[0];
        var reader = new FileReader();
        reader.onload = async (e: any) => {
          let data: any = e.target.result;
          data = new Uint8Array(data);
          var workbook = XLSX.read(data, { type: "array" });
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          var jsonObj = XLSX.utils.sheet_to_json(worksheet, {
            raw: true,
            header: this.fullKeys,
            range: 1,
          });
          let jsonArray: any = [];
          let objectArr: any;
          let framework = "";
          const uniqueControlNumbers = new Set<string>();
          let booleanValue = true;
          for (let i = 0; i < jsonObj.length; i++) {
            let item: any;
            item = jsonObj[i];
            let arr = [
              "controlNumber",
              "controlFamily",
              "controlClass",
              "controlTitle",
              "controlPriority",
              "controlType"
            ];
            let mandatoryCondition = arr.every((key: any) => {
              return Object.keys(item).includes(key) && item[key];
            });
            let PriorityObj = this.priorityArray.find((obj: any) => {
              return obj.label == item.controlPriority;
            });
            if (PriorityObj != undefined || PriorityObj != null) {
              item.controlPriority = PriorityObj.id;
            }

            // let TypeObj = this.controlTypeArray.find((obj: any) => {
            // 	return obj.label == item.controlType;
            // });
            // item.controlType = TypeObj.id;
            let controlCheck;
            
            //   let deptId = this.departmentsList.find((obj: any) => {
            //     return obj.teamName === item.departments;
            //   });
            //   if (deptId != undefined || deptId != null) {
            //     item.departments = deptId._id;
            //   }
            if (mandatoryCondition) {
              if ((this.dummyControlList != undefined || this.dummyControlList != null) && this.dummyControlList.length != 0) {
                controlCheck = this.dummyControlList.some((control: any) => {
                  if(control.departments == this.controlDept.selectedDepartment.value){
                    return control.controlNumber == item.controlNumber;
                  }
                });
                
              }
              if (!uniqueControlNumbers.has(item.controlNumber)) {
                booleanValue = false;
                framework = "InternalControls";
                //   objectArr.push(item);
                // jsonArray[item.departments] = jsonArray[item.departments] || [];
                if(!controlCheck){
                  jsonArray.push(item);
                }
                // else {
                //   jsonArray = this.dummyInternalControls
                // }
                uniqueControlNumbers.add(item.controlNumber);
              }
            }
          }
          this.controlsList = jsonArray;
          if (this.controlsList && this.controlsList.length == 0) {
            toast.error(`Control Numbers should be unique for same department`, {
              timeout: 5000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
          }
          this.makeTable();
        };
        reader.readAsArrayBuffer(oFile);
      // }
    },
    async fetchDepartmentsList() {
      this.showLoader = true;
      await this.$http
        .get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`)
        .then((res: { data: any }) => {
          this.isLoading = true;
          this.showLoader = false;
          this.departmentsList = res.data;
        })
        .catch((error: any) => {
          this.isLoading = false;
          (this.showLoader = false),
            toast.error(`Save failed with ${error}`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
        });
      this.deptOptions = this.departmentsList.map((item: any) => {
        return { value: item._id, label: item.teamName };
      });
    },
    filterOpen() {
      if (this.columnObject == false) {
        this.showFilters = !this.showFilters;
        emitter.emit("visible", this.showFilters);
      }
    },
  },
});
</script>