<template>
  <div class="pr-5 pt-3 pl-5">
    <div class="mb-2">
      <breadCrumb :routesArray="routesArray"></breadCrumb>
    </div>
    <div>
      <div class="items-center justify-between flex">
        <div class="title flex items-center">
          <h1 :class="titleLength > 40? 'heading-ellipsis':''">{{presentControlObject.controlTitle}}</h1>
        </div>
        <div class="flex flex-row-reverse">
          <button
            class="flex"
            @click="filterOpen"
            :class="
              (this.selectedTabIndex == 0 &&
                this.sortedAppliedKpisData.length != 0) ||
              (this.selectedTabIndex == 1 && this.sortedData.length != 0)
                ? 'filterButton'
                : 'filterButtondisabled pointer-events-none'
            "
          >
            Filters
            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
          </button>
          <button class="mr-3" v-if="selectedTabIndex == 0" :class=" showTotalKpisWeightage < 100 ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addKPI(kpi)">
            Add
          </button>
        </div>
      </div>
      <div class="mt-5 px-5 pb-5 card">
        <div class="mr-2.5">
          <div
            class="pt-5 flex flex-row borderBottom justify-between overflow-hidden cursor-pointer" :class=" widthofTabStructure() ? 'w-6/12': 'w-full'"
          >
		  
            <div
              class="pb-1 w-auto zIndex mr-1"
              :class="selectedTabIndex == tab.id ? 'adjust header font-semibold text-sm primary':'text-sm text-disabledgrey'"
              v-for="(tab, tabIndex) in carouselHead"
              :key="tabIndex"
              @click="toggleTabs(tab.id)"
            >
              <div>
                <div ref="actualIndex">{{ tab.displayName }}</div>
                <div
                  :class="selectedTabIndex == tab.id ? 'progress' : ''"
                  class="h-1 p-0.75"
                ></div>
              </div>
            </div>
          </div>
          <div class="progressbarSI h-1"></div>
        </div>

        <div>
          <div v-if="selectedTabIndex == 0">
            <div class="tableCornerRadius bg-white">
              <table>
                <thead>
                  <th
                    scope="col"
                    v-for="column in table1Heads"
                    :key="column"
                    :class="getColumnHeadWidth(column)"
                    class="bg-white cursor-pointer capitalize"
                  >
                    <div @click="sort(column)">
                      <div class="flex">
                        <div v-if="column == 'description'">KPI Name</div>
                        <div v-else-if="column == 'weightage'">
                          weightage (%)
                        </div>
                        <!-- <div v-else-if="column == 'kpi_id'">KPI Id</div> -->
                        <!-- <div v-else-if="column == 'target'">Target (%)</div>
											<div v-else-if="column == 'targetTimePeriod'">Target Time Period</div>
											<div v-else-if="column == 'reportingFrequency'">Reporting Frequency</div> -->
                        <!-- <div v-else>{{ column }}</div> -->
                        <div class="sort cursor-pointer" v-if="column != 'deleteIcon'" :class=" (className == column && this.selectedTabIndex == 0 && this.sortedAppliedKpisData.length != 0) || (this.selectedTabIndex == 1 && this.sortedData.length != 0) ? classSort : 'opacity-50'">
                        </div>
                      </div>
                    </div>
                    <div v-if="column == 'deleteIcon'"></div>
                  </th>
                  <tr v-if="showFilters">
                    <td v-for="name in table1Heads" class="bg-white" :key="name">
                      <select
                        class="inputboxstyling resize"
                        v-if="
                          name == 'targetTimePeriod' ||
                          name == 'reportingFrequency'
                        "
                        v-model="filters[name]"
                      >
                        <option :value="filters.name" selected disabled hidden>
                          Select
                        </option>
                        <option
                          v-for="(value, index) in controlRecurringArray"
                          :key="index"
                          :value="value.id"
                        >
                          {{ value.label }}
                        </option>
                      </select>
                      <div v-else-if="name == 'deleteIcon' || name == 'weightage'"></div>
                      <input
                        v-else
                        type="text"
                        class="searchForInputBoxStyling"
                        placeholder="Search"
                        v-model="filters[name]"
                      />
                    </td>
                    <td v-if="name == 'deleteIcon'"></td>
                  </tr>
                </thead>
                <tbody
                  v-if="columnObject || sortedAppliedKpisData.length === 0"
                >
                  <tr class="cursor-pointer rowHeight">
                    <td class="relative h-10" :colspan="table1Heads.length">
                      <div class="flex justify-center">
                        <p class="text-xs font-normal">No Records Found</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else class="tbodylast">
                  <tr
                    v-for="(kpiData, index) in sortedAppliedKpisData"
                    :key="kpiData._id"
                    :class="index % 2 == 0 ? 'odd' : 'even'"
                    
                  >
                    <td
                      v-for="column in table1Heads"
                      :key="column"
                      @mouseout="doFalse()"
                      class="cursor-pointer relative"
                      @click="showEditPopUp(kpiData, column)"
                    >
                      <div
                        v-if="
                          column == 'targetTimePeriod' ||
                          column == 'reportingFrequency'"
                      >
                        <div class="table-ellipsis">
                          <div class="inline">
                            {{
                              controlRecurringArray.find((itemOne) => {
                                return itemOne.id == kpiData[column];
                              }).label
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-else-if="column == 'deleteIcon'">
                        <div class="flex justify-center items-center h-7 w-7">
                          <div class="h-5 w-5"> <img src="@/assets/deleteIcon.svg" class="cursor-pointer h-5 w-5" @click="deleteKpiForControl(kpiData)"/>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="column == 'weightage'">
                        <div class="table-ellipsis pl-10">
                          <div class="inline">
                            {{ kpiData[column] }}
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="table-ellipsis">
                          <div class="inline" @mousemove="toolTip(column, $event, index, kpiData, 'text')">
                            {{ kpiData[column] }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td v-if="column == 'deleteIcon'">
									</td>
                  </tr>
                </tbody>
              </table>
              <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
              </div>
              <div v-if="sortedAppliedKpisData.length != 0">
                <pagination
                  :dynamicPagination="dynamicPagination"
                  @currentPageUpdate="paginationUpdations"
                  :sortedData="sortedAppliedKpisData"
                ></pagination>
              </div>
            </div>
          </div>
          <div v-if="selectedTabIndex == 1">
            <div class="tableCornerRadius bg-white">
              <table>
                <thead>
                  <th class="header w-24" v-if="allRootKpis.length != 0"></th>
                  <th
                    scope="col"
                    v-for="column in table2Heads"
                    :key="column"
                    class="bg-white cursor-pointer capitalize"
                    @click="sort(column)"
                  >
                    <div>
                      <div class="flex">
                        <div v-if="column == 'description'">KPI Name</div>
                        <div v-else-if="column == 'weightage'">
                          weightage (%)
                        </div>
                        <!-- <div v-else-if="column == 'target'">Target (%)</div>
											<div v-else-if="column == 'targetTimePeriod'">Target Time Period</div>
											<div v-else-if="column == 'reportingFrequency'">Reporting Frequency</div> -->
                        <div v-else>{{ column }}</div>
                        <div
                          class="sort cursor-pointer"
                          :class="
                            (className == column &&
                              this.selectedTabIndex == 0 &&
                              this.sortedAppliedKpisData.length != 0) ||
                            (this.selectedTabIndex == 1 &&
                              this.sortedData.length != 0)
                              ? classSort
                              : 'opacity-50'
                          "
                        ></div>
                      </div>
                    </div>
                  </th>
                  <tr v-if="showFilters">
									<td v-if="allRootKpis.length != 0"></td>
									<td v-for="name in table2Heads" class="bg-white" :key="name">
										<select class="inputboxstylingForSelect resize" v-if="name == 'targetTimePeriod' || name == 'reportingFrequency'" v-model="filters[name]">
											<option :value="filters.name" selected disabled hidden>Select</option>
											<option v-for="(value, index) in controlRecurringArray" :key="index" :value="value.id">{{ value.label }}</option>
										</select>
                    <div v-else-if="name == 'weightage'"></div>
										<input v-else type="text" class="searchForInputBoxStyling" placeholder="Search" v-model="filters[name]" />
									</td>
								</tr>
                </thead>
                <tbody v-if="columnObject || sortedData.length === 0">
                  <tr class="cursor-pointer rowHeight">
                    <td class="relative h-10" :colspan="table2Heads.length">
                      <div class="flex justify-center">
                        <p class="text-xs font-normal">No Records Found</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else class="tbodylast">
                  <tr
                    v-for="(kpiData, index) in sortedData"
                    :key="kpiData._id"
                    :class="index % 2 == 0 ? 'odd' : 'even'"
                  >
                    <td class="checkBoxTd">
                      <div class="flex items-center ml-4">
                        <input
                          type="checkbox"
                          name="checkbox"
                          class="checkbox cursor-pointer"
                          :value="kpiData"
                          v-model="selectedRootKpis"
                          @change="calculateWeightAge(1, $event, kpiData.weightage)"/>
                      </div>
                    </td>
                    <td v-for="column in table2Heads" :key="column" @mouseout="doFalse()" class="cursor-pointer relative">
                      <div v-if=" column == 'targetTimePeriod' || column == 'reportingFrequency'" >
                        <div class="table-ellipsis">
                          <div class="inline">
                            {{
                              controlRecurringArray.find((itemOne) => {
                                return itemOne.id == kpiData[column];
                              }).label
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-else-if="column == 'deleteIcon'"></div>
                      <div v-else-if="column == 'weightage'">
                        <div class="table-ellipsis pl-10">
                          <div class="inline">
                            {{ kpiData[column] }}
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="table-ellipsis" @mousemove="toolTip(column, $event, index, kpiData, 'text')">
                          <div class="inline">
                            {{ kpiData[column] }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
              </div>
              <pagination
                :dynamicPagination="dynamicPagination"
                @currentPageUpdate="paginationUpdations"
                :sortedData="sortedData"
                v-if="sortedData.length != 0"
              ></pagination>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between text-sm mt-6">
          Total Weightage is: {{ showTotalKpisWeightage }} %
        

          <div
            v-if="selectedTabIndex == 1 && sortedData.length != 0"
            class="flex justify-end"
          >
            <button
              class="btnprimary mt-2"
              :class="selectedRootKpis.length > 0 ? 'btnprimary' : 'btndisabled pointer-events-none'"
              @click="saveKpisForPresentOrgControl(selectedRootKpis, 'direct')" 
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <popup v-if="showPopup" :popUp="showPopup">
        <template v-slot:header>
          <div class="flex items-center justify-between py-4 px-2 rounded">
            <h1 class="pl-2">Manage KPI</h1>
            <button
              class="float-right text-3xl"
              @click="
                onCancelbtn();
                showPopup = false;
              "
            >
              <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
            </button>
          </div>
        </template>
        <fieldset>
          <div>
            <div>
              <div class="statuscontainer popupbodycontainer">
                <div class="formcontainer flex flex-col">
                  <!-- <div class="row flex flex-row w-full upperspacing">
                    <div class="w-full">
                      <label class="controllabel">KPI ID</label>
                      <input
                        type="text"
                        class="inputboxstyling"
                        v-model="addingKpiObj.kpi_id"
                        placeholder="KPI ID"
                        disabled
                      />
                    </div>
                  </div> -->
                  <div class="row flex flex-row w-full upperspacing">
                    <div class="w-full">
                      <label class="controllabel">KPI Name</label>
                      <textarea
                        type="text"
                        class="inputboxstyling h-20 max-h-48 mt-2"
                        v-model="addingKpiObj.description"
                        placeholder="KPI Name"
                        :class="{
                          'is-invalid': v$.addingKpiObj.description.$error,
                        }"
                      ></textarea>
                      <div
                        v-if="v$.addingKpiObj.description.$error"
                        class="text-red-500"
                      >
                        <div
                          class="error-text"
                          v-if="v$.addingKpiObj.description.required"
                        >
                          Required
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- {{v$.addingKpiObj.weightage.$error}}{{validtaionWeightageError}}{{numberLessThanZero}}

                  {{addingKpiObj}} -->
                  <div class="row flex flex-row w-full mt-3">
                    <div class="w-6/12">
                      <label class="controllabel">Weightage (%)</label>
                      <input
                        type="number"
                        class="inputboxstyling mt-2"
                        v-model="addingKpiObj.weightage"
                        maxlength="3"
                        ref="editWeight"
                        placeholder="Weightage (%)"
                        @keyup="weightageLessThanZero(addingKpiObj.weightage) || weightageGreaterThanZero(addingKpiObj) && handleInput"
                        :class="{
                          'is-invalid': v$.addingKpiObj.weightage.$error || validtaionWeightageError || numberLessThanZero,
                        }"
                      />
                      <div
                        v-if="v$.addingKpiObj.weightage.$error || validtaionWeightageError || numberLessThanZero"
                        class="text-red-500"
                      >
                        <div
                          class="error-text"
                          v-if="v$.addingKpiObj.weightage.required.$invalid"
                        >
                          Weightage (%) is required
                        </div>
                        <div
                          class="error-text"
                          v-if="(validtaionWeightageError && !v$.addingKpiObj.weightage.required.$invalid) || (numberLessThanZero == true && !v$.addingKpiObj.weightage.required.$invalid)"
                        >
                          Weightage(%) is Invalid
                        </div>
                      </div>
                    </div>
                    <!-- <div class="w-6/12 p-2">
											<label class="controllabel">Target (%)</label>
											<input
												type="number"
												class="inputboxstyling"
												v-model="addingKpiObj.target"
												placeholder="Target (%)"
												maxlength="3"
												:class="{
													'is-invalid': v$.addingKpiObj.target.$error
												}"
											/>
											<div v-if="v$.addingKpiObj.target.$error" class="text-red-500">
												<div class="error-text" v-if="v$.addingKpiObj.target.required">Target (%) is Required</div>
												<div class="error-text" v-else-if="v$.addingKpiObj.target.numeric.$invalid">Target (%) is Invalid</div>
											</div>
										</div> -->
                  </div>
                  <!-- <div class="row flex flex-row w-full">
										<div class="w-6/12 p-2">
											<label class="controllabel">Reporting Frequency</label>
											<select
												class="inputboxstyling"
												v-model="addingKpiObj.reportingFrequency"
												@change="emptyTargetTimePeriod(addingKpiObj)"
												:class="{
													'is-invalid': v$.addingKpiObj.reportingFrequency.$error
												}"
											>
												<option value="" selected disabled hidden>Select</option>
												<option v-for="value in controlRecurringArray" :key="value" :value="value.id">{{ value.label }}</option>
											</select>
											<div v-if="v$.addingKpiObj.reportingFrequency.$error" class="text-red-500">
												<div class="error-text" v-if="v$.addingKpiObj.reportingFrequency.required">Required</div>
											</div>
										</div>
										<div class="w-6/12 p-2">
											<label class="controllabel">Target Time Period</label>
											<select
												class="inputboxstyling"
												v-model="addingKpiObj.targetTimePeriod"
												:class="{
													'is-invalid': v$.addingKpiObj.targetTimePeriod.$error
												}"
												:disabled="!addingKpiObj.reportingFrequency"
											>
												<option value="" selected disabled hidden>Select</option>
												 <option v-for="value in filteredTimePeriods" :key="value" :value="value.id">{{ value.label }}</option> 
												<option v-for="value in controlRecurringArray" :key="value" :value="value.id" :disabled="value.id < addingKpiObj.reportingFrequency">{{ value.label }}</option>
											</select>
											<div v-if="v$.addingKpiObj.targetTimePeriod.$error" class="text-red-500">
												<div class="error-text" v-if="v$.addingKpiObj.targetTimePeriod.required">Required</div>
											</div>
										</div>
									</div> -->
                  <!-- <h1 v-if="hideFormula">Metrics</h1> -->
                  <!-- <div class="row flex w-full gap-16">
										<div class="w-4/6">
											<div class="p-2.5">
												<div v-for="(metric, metricIndex) in addingKpiObj.metrics" :key="metricIndex">
													<label class="controllabel">{{ metric.key }}</label>
													<input
														type="text"
														class="inputboxstyling"
														v-model="addingKpiObj.metrics[metricIndex].label"
														:class="{
															'is-invalid': v$.addingKpiObj.metrics.$error && !v$.addingKpiObj.metrics.$each.$response.$data[metricIndex].label.required
														}"
													/>
												</div>
												<div class="text-red-500" v-if="v$.addingKpiObj.metrics.$error">
													<div class="error-text" v-if="v$.addingKpiObj.metrics.$dirty || addingKpiObj.metrics.length != 2">Metrics A and B are required</div>
												</div>
											</div>
										</div>

										<div class="w-2/6 flex justify-center items-center" v-if="hideFormula">
											<div class="">
												<label class="controllabel font-normal text-sm mb-1">Formula</label>
												<input type="text" class="inputboxstyling" :disabled="true" v-model="addingKpiObj.isformula" placeholder="Formula" />
											</div>
										</div>
									</div> -->
                </div>
              </div>
            </div>
            <div
              v-if="addingKpiObj._id"
              class="buttonposition flex justify-center items-center mb-4 mx-0"
            >
              <button
                class="mr-1.5"
                :class="
                  disableStatus
                    ? 'btnprimary'
                    : 'btndisabled pointer-events-none'
                "
                @click="onCancelbtn()"
              >
                Cancel
              </button>
              <button
                :class="
                  disableStatus
                    ? 'btnprimary'
                    : 'btndisabled pointer-events-none'
                "
                @click="addOrUpdateKpiForControlManual(addingKpiObj)"
              >
                Update
              </button>
            </div>
            <div
              v-else
              class="buttonposition flex justify-center items-center mb-4 mx-0"
            >
              <!-- <button class="mr-3" :class="addingKpiObj.metrics.length >= 2 ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="addMetrics(addingKpiObj)">Add Metrics</button> -->
              <button
                class="mr-1.5"
                :class="
                  disableStatus
                    ? 'btndisabled pointer-events-none'
                    : 'btnprimary'
                "
                @click="onCancelbtn()"
              >
                Cancel
              </button>
              <button
                :class="
                  disableStatus
                    ? 'btndisabled pointer-events-none'
                    : 'btnprimary'
                "
                @click="addOrUpdateKpiForControlManual(addingKpiObj)"
              >
                Save
              </button>
            </div>
          </div>
        </fieldset>
      </popup>
    </div>
  </div>
</template>
<style scoped>
.zIndex{
	z-index: 1;
}
.Vue-Toastification__container {
  z-index: 999999999 !important;
}
.checkBoxTh:first-child,
.checkBoxTd:first-child {
  width: 75px;
}
.table-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.heading-ellipsis {
  width: 50%;
  display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.anime-left {
  animation-name: moveLeft;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}
@keyframes moveLeft {
  0% {
    opacity: 1;
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}
.anime-right {
  animation-name: moveRight;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}
@keyframes moveRight {
  0% {
    opacity: 1;
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0);
  }
}
.progressbarSI {
  border: 2px solid #e9e9e9;
  border-radius: 16px;
  margin-right: 4px;
  background: #e9e9e9;
  position: relative;
  top: -8px;
  /* margin-top: 3px; */
}
.progress {
  border-radius:16px;
  background: #152a43 !important;
}
.tbodylast:last-child {
  border-bottom: 1px solid #e9e9e9;
}
.primary{
  color:#152a43;
}
</style>

<script lang="ts">
import { required, requiredIf } from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
const { withParams } = helpers;
import loader from "../../components/loader.vue";
const numeric = helpers.regex("numeric", /^[0-9]+$/i);
const lessthanHundred = helpers.regex("lessthan", /^[1-9][0-9]?$|^100$/i);
const validateMetricsArray = withParams({ type: "array" }, (value: any) => {
  return Array.isArray(value) && value.length > 0;
});
const customValidator = withParams({ type: "array" }, validateMetricsArray);

import _ from "lodash";
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { emitter, toast } from "@/main";
import tableData from "@/components/tableData.vue";
import breadCrumb from "@/components/breadCrumb.vue";
import popup from "@/components/popup.vue";
import pagination from "@/components/pagination.vue";
import toolTip from "@/components/toolTip.vue";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
  data(): any {
    return {
      // kpiClone: _.cloneDeep(this.addingKpiObj), // Deep clone the kpi object
      // Apply validation to the cloned object

      v$: useVuelidate(),

      showFilters: false,
      dummyList: [],
      hideFormula: false,
      addingKpiObj: {
        kpi_id: "",
        weightage: "",
        description: "",
      },
      dummyObj: [],
      showPopup: false,
      showBlockindex: '',
      showBlock: false,
      columnObject: false,
      actionBtn: false,
      showDropDown: false,
      selectedRowIndex: null,
      controlRecurringArray: [
        { label: "Daily", id: 1 },
        { label: "Weekly", id: 2 },
        { label: "Monthly", id: 3 },
        { label: "Quarterly", id: 4 },
        { label: "Half-Yearly", id: 5 },
        { label: "Yearly", id: 6 },
      ],
      tableDataActionOptions: [
        {
            id: 1,
            value: 'Delete'
        },
      ],
      routesArray: [],
      allOrgControls: [],
      allRootControls: [],
      presentControlObject: {},
      allRootKpis: [],
      kpisListFromOrgPresentControl: [],
      allRootKpisUnfiltered: [],
      tipText: '',
			tipColumn: '',
			tipIndex: '',
      toolTipActive : false,
			toolTipText : '',
      carouselHead: [
        { displayName: "Active KPIs", name: "Kpis", id: 0 },
        { displayName: "Related KPIs", name: "SuggestedKpis", id: 1 },
      ],
      selectedTabIndex: null,
      validtaionWeightageError: false,
      sideButton: false,
      titleLength: 0,
      styling: { left: "", bottom: "" },
      allKpisList: [],
      pageSize: 20,
      currentPage: 1,
      dynamicPagination: "",
      currentSort: "name",
      currentSortDir: "asc",
      classSort: "",
      className: "",
      filters: {},
      selectedRootKpis: [],
      pop: false,
      showTotalKpisWeightage: 0,
      table1Heads: ["description", "weightage","deleteIcon"],
      table2Heads: ["description", "weightage"],
      tableRow:[],
      isLoading:false,
			showLoader: false,
      numberLessThanZero : false,
      inputValue: '',
    };
  },
  components: {
    pagination,
    popup,
    breadCrumb,
    toolTip
  },
  validations: {
    addingKpiObj: {
      weightage: { required },
      description: { required },
    },
  },
  async created() {
    // await this.getAllRootKpis();
    await this.fetchAllKpisInfo();
    await this.fetchKpisInfo()
    await this.getAllOrgControls();
    const savedTabKey = sessionStorage.getItem('selectedTab');
      if (savedTabKey) {
        const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
        if (savedTab) {
          console.log("Hiiii")
            await this.toggleTabs(savedTab.id);
        }
    } else {
        await this.toggleTabs(0);
    }
    let routeControlId = this.$route.params.id;
    let mainObj = this.allOrgControls.find((eachItem: any) => {
      return routeControlId == eachItem._id;
    });
    this.titleLength = mainObj.controlTitle ? mainObj.controlTitle.length:0
    this.routesArray = [
      { name: "Active Controls", routeName: "Active-Controls" },
      { name: mainObj.clientUniqueId, routeName: "manageControlMap" },
    ];
    emitter.off('activiteToolTip');
		emitter.on('activiteToolTip', (data: any) => {
			this.toolTipActive = true;
			this.toolTipText = data.tipText
		});
		emitter.off('deActiviteToolTip');
		emitter.on('deActiviteToolTip', (data: any) => {
			this.toolTipActive = false;
			this.toolTipText = ''
		});
  },
  mounted(){
  emitter.off('deleteData');
        emitter.on('deleteData', (isData: any) => {
            this.showDeletePopUp(isData.entityData);
        });
  },
  computed: {
    sortedData: function (): any {
      return [...this.allRootKpis]
        .filter((row: any) => {
          for (const key in this.filters) {
            const inputValue = this.filters[key];
            if (inputValue !== "" || row[key]) {
              if (Number(inputValue) && isNaN(row[key])) {
                if (!String(row[key]).includes(inputValue)) {
                  return false;
                }
              } else {
                if (
                  !row[key]
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toString().toLowerCase())
                ) {
                  return false;
                }
              }
            }
          }
          return true;
        })
        .sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        })
        .filter((row, index) => {
          this.dynamicPagination = index + 1;
          let start = (this.currentPage - 1) * this.pageSize;
          let end = this.currentPage * this.pageSize;
          if (index >= start && index < end) return true;
        });
    },
    widthClass() {
      return window.innerWidth >= 768 ? 'w-6/12' : 'w-full';
    },
    sortedAppliedKpisData: function (): any {
      return [...this.kpisListFromOrgPresentControl]
        .filter((row: any) => {
          for (const key in this.filters) {
            const inputValue = this.filters[key];
            if (inputValue !== "" || row[key]) {
              if (Number(inputValue) && isNaN(row[key])) {
                if (!String(row[key]).includes(inputValue)) {
                  return false;
                }
              } else {
                if (
                  !row[key]
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toString().toLowerCase())
                ) {
                  return false;
                }
              }
            }
          }
          return true;
        })
        .sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        })
        .filter((row, index) => {
          this.dynamicPagination = index + 1;
          let start = (this.currentPage - 1) * this.pageSize;
          let end = this.currentPage * this.pageSize;
          if (index >= start && index < end) return true;
        });
    },
    disableStatus: function (): any {
      let output;
      if (this.editPop) {
        let objCopy = Object.assign({}, this.addingKpiObj);
        if (_.isEqual(objCopy, this.dummyObj)) {
          output = false;
        } else {
          output = true;
        }
      } else {
        if (_.isEqual(this.addingKpiObj, this.dummyObj)) {
          output = true;
        } else {
          output = false;
        }
      }
      return output;
    },
    filteredTimePeriods(): any {
      if (this.addingKpiObj.reportingFrequency) {
        return this.controlRecurringArray.filter(
          (value: any) => value.id >= this.addingKpiObj.reportingFrequency
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    showBlockAction(data: any, index: any) {
			if (this.showBlockindex === index) {
				this.showBlock = !this.showBlock;
			} else {
				this.showBlock = true;
				this.showBlockindex = index;
			}
			this.showDropdown = false;
		},
    toolTip(column: any, e: any, index: any, row: any, status: any) {
			this.tipText = row[column];
			this.tipColumn = column;
			this.tipIndex = index;
      

			switch (status) {
				case 'text':
					this.handleTextTooltip(e);
					break;
				
			}
		},
		handleTextTooltip(e: any) {
			const container = e.currentTarget.closest('.table-ellipsis');
			// console.log("tooltip",container)
			 if (container && this.isTextOverflowed(container)) {
				let obj:any = {
					tipText : this.tipText
				}
				emitter.emit('activiteToolTip',obj)
                
        } 
		},
		isTextOverflowed(element: any) {
			return element.scrollHeight > element.clientHeight;
		},
    sort(tableHead: any) {
			this.className = tableHead;
			if (tableHead === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = tableHead;
			this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
		},
    openPopup(data: any, index: any, actionBtn: any) {
			this.showBlockAction(data, index);
			let obj = {
				entityData: data,
				indexx: index,
				action: actionBtn
			};
    },
    // async optionClickedOnAction(state: any, row:any){
    //   if(state == 'delete'){
    //     await this.deleteKpiForControl(row);
    //   }
    // },
    getColumnHeadWidth(columnName:any){
			if(columnName == "deleteIcon"){
				return 'w-24';
			}
    },
    deleteAndShowRowData(sortedData:any, index:any) {
      console.log('Row Data:', sortedData);
      this.kpiList.splice(index, 1);
    },
    showTotalKpisWeightageLessThan100() {
    let totalWeightage = 0;
    return totalWeightage + this.addingKpiObj.weightage < 100;
    },
    emptyTargetTimePeriod(kpi: any) {
      if (kpi.targetTimePeriod < kpi.reportingFrequency) {
        kpi.targetTimePeriod = "";
      }
    },
    async deleteKpiForControl(kpiObj: any){
      console.log("kpiObj", kpiObj)
      // /api/org/control/:controlId/kpi/:kpiId/delete
      await this.$http
        .delete(
          `${process.env.VUE_APP_CONTROLS_API_URL}/api/org/control/${this.$route.params.id}/kpi/${kpiObj._id}/delete`
        )
        .then(async () => {
          toast.info("Deleted", {
            timeout: 5000,
            closeOnClick: true,
            closeButton: "button",
            icon: true,
          });
          this.selectedRootKpis = [];
          await this.fetchKpisInfo();
          await this.getAllOrgControls();
          await this.toggleTabs(0);
          this.showPopup = false;
        });
    },
    async fetchKpisInfo() {
			this.allRootKpis = [];
			this.tableRow = [];
			try {
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/kpis/get`).then((result: any) => {
					this.isLoading = true;
					this.showLoader = true;
          // result.data.forEach((kpi:any)=>{
          //   let eachKpi = this.allKpisList.find((everyKpi: any) => {
          //     return Object.keys(everyKpi).includes('referenceKpiId') && everyKpi.referenceKpiId !== kpi._id
          //   });
          //   if (eachKpi) {
          //     this.allRootKpis.push(eachKpi);
          //   }
          // })
					this.allRootKpis = result.data;
          if(this.allRootKpis != undefined || this.allRootKpis != null){
            this.dummyList = this.allRootKpis;
          } else {
            console.log("this.allRootKpis",this.allRootKpis)
          }
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.showLoader = false;
			
		},
    async makeTable() {
			if (this.allRootKpis.length > 0) {
				this.allRootKpis.forEach(async (eachKPI: any) => {
					let finalKPIObj: any = {
						// _id: eachKPI._id,
						// departments: eachKPI.departments,
						'KPI Name': eachKPI.description,
						'Weightage (%)': parseInt(eachKPI.weightage)
						// 'Target (%)': parseInt(eachKPI.target),
						// 'Target Time Period': Number(eachKPI.targetTimePeriod) ? parseInt(eachKPI.targetTimePeriod) : eachKPI.targetTimePeriod,
						// 'Reporting Frequency': Number(eachKPI.reportingFrequency) ? parseInt(eachKPI.reportingFrequency) : eachKPI.reportingFrequency
					};
					await this.tableRow.push(finalKPIObj);

				});
			} else {
				let finalKPIObj = {
					'KPI Name': '',
					'Weightage (%)': ''
					// 'Target (%)': '',
					// 'Target Time Period': '',
					// 'Reporting Frequency': ''
				};
				await this.tableRow.push(finalKPIObj);
			}},
    async addKPI() {
      if (this.kpisListFromOrgPresentControl.length < 10) {
        let numeric = 1;
        this.allKpisList.forEach((item: any) => {
          if (item.kpi_id) {
            const parts = item.kpi_id.split(" ");
            if (parts.length > 2) {
              const intNumber = parseInt(parts[2]);
              if (!isNaN(intNumber)) {
                numeric = Math.max(numeric, intNumber);
              }
            }
          }
        });
        numeric++; // Increment numeric outside the loop to get the next KPI number
        let id = "KPI - " + numeric;
        this.addingKpiObj = { kpi_id: id, weightage: "", description: "" };
        this.hideFormula = false;
        this.showPopup = true;
        this.editPop = false;
        this.dummyObj = { ...this.addingKpiObj };
      } else {
        toast.error("Maximum KPIs should be 10", {
          timeout: 4000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
    },
    onReset() {
      // reset form validation errors
      this.v$.$reset();
      // reset form data
      const initialData = this.$options.data.call(this);
      Object.assign(this.$data, initialData);
    },
    showEditPopUp(showObj: any, column: any) {
      if(column != 'deleteIcon'){
        this.v$.$reset();
        this.showPopup = true;
        // showObj.isformula = 'A/B'
        // let id = uuidv4();
        // this.addingKpiObj.kpi_id=id;
        this.editPop = true;
        this.addingKpiObj = { ...showObj };
        this.dummyObj = { ...this.addingKpiObj };
        this.hideFormula = true;
      }
    },
    filterOpen() {
      if (this.columnObject == false) {
        this.showFilters = !this.showFilters;
        emitter.emit("visible", this.showFilters);
      }
    },
    paginationUpdations(currentPage: any, pageSize: any) {
      this.currentPage = currentPage;
      this.pageSize = pageSize;
    },
    // addMetrics(kpi: { metrics: { label: string; key: string; value: string }[] }) {
    // 	this.hideFormula = true;
    // 	this.addingKpiObj.isformula = 'A/B';
    // 	this.addingKpiObj.metrics.push({ label: '', key: 'A', value: '' });
    // 	this.addingKpiObj.metrics.push({ label: '', key: 'B', value: '' });
    // },
    onCancelbtn() {
      this.v$.$reset();
      this.validtaionWeightageError = false;
      this.numberLessThanZero = false;
      if (!this.addingKpiObj._id) {
        Object.keys(this.addingKpiObj).forEach((prop) => {
          if (prop != "kpi_id") {
            Array.isArray(this.addingKpiObj[prop])
              ? (this.addingKpiObj[prop] = [])
              : (this.addingKpiObj[prop] = "");
          }
          // Array.isArray(this.addingKpiObj[prop]) ? (this.addingKpiObj[prop] = []) : (this.addingKpiObj[prop] = '');
        });
        this.hideFormula = false;
      } else {
        this.addingKpiObj = { ...this.dummyObj };
      }
    },
    addRelatedKPIs() {
      if (this.appliedKPIs.length + this.relatedKPIs.length > 10) {
        this.$toast.error('You can only have a maximum of 10 KPIs.');
        return;
      }
      
      // Add related KPIs to appliedKPIs array
      this.appliedKPIs.push(...this.relatedKPIs);
      
      // Clear the selection or reset relatedKPIs array
      this.relatedKPIs = [];
    },
    async getAllRootControls() {
			// this.presentControlObject = {};
			this.kpisListForPresentControl = [];
			try {
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/controls/getall`).then((result: { data: any }) => {
					this.allControls = result.data;
					let routeControlId = this.$route.params.id;
					let mainObj = result.data.find((eachItem: any) => {
						return routeControlId == eachItem._id;
					});
          // if(mainObj != undefined){
          //   this.presentControlObject = mainObj;
          // }
					if (mainObj != undefined && Object.keys(mainObj).includes('kpis')) {
						// this.presentControlObject = mainObj;
						this.kpisListForPresentControl = mainObj.kpis;

						let mappedKpis: any = [];
						this.kpisListForPresentControl.map((obj: any) => {
							mappedKpis.push(obj.description);
						});

						if (mappedKpis.length > 0) {
							let filteredRootKpis = this.allRootKpis.filter((obj: any) => {
								return !mappedKpis.includes(obj.description);
							});
							this.allRootKpis = filteredRootKpis;
						}
					} else {
						this.kpisListForPresentControl = [];
					}
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
    widthofTabStructure(){
       return window.innerWidth >= 768;
    },
    async getAllOrgControls() {
      this.presentControlObject = {};
      this.kpisListFromOrgPresentControl = [];
      try {
        await this.$http
          .get(
            `${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active`
          )
          .then(async (result: { data: any }) => {
            this.allOrgControls = result.data;
            let routeControlId = this.$route.params.id;
            let mainObj = result.data.find((eachItem: any) => {
              return routeControlId == eachItem._id;
            });
            if(mainObj != undefined){
              this.presentControlObject = mainObj;
            }
            if (mainObj != undefined && Object.keys(mainObj).includes("kpis")) {
              // this.presentControlObject = mainObj;
              this.kpisListFromOrgPresentControl = mainObj.kpis;
              let mappedKpis: any = [];
              this.kpisListFromOrgPresentControl.map((obj: any) => {
                mappedKpis.push(obj.description);
              });
              if (mappedKpis.length > 0) {
                console.log("this.allRootKpis",this.allRootKpis)
                if(this.allRootKpis !== undefined || this.allRootKpis !== null){
                  let filteredOrgKpis =
                    this.allRootKpis.filter((obj: any) => {
                      return !mappedKpis.includes(obj.description);
                    });
                  this.allRootKpis = filteredOrgKpis;
                }else{
                  console.log("this.allRootKpis",this.allRootKpis)
                }
                
              }
            } else {
              this.kpisListFromOrgPresentControl = [];
            }
            await this.makeTable();
          });
      } catch (e) {
        toast.error("Error", {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
    },
    doFalse() {
      // this.pop = false;
      emitter.emit('deActiviteToolTip')
    },
    async fetchAllKpisInfo() {
      try {
        await this.$http
          .get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/kpis/getall`)
          .then((result: { data: any }) => {
            this.isLoading = true;
            this.showLoader = true;
            result.data.forEach((eachKpi: any) => {
              if (eachKpi.kpis && eachKpi.kpis.length) {
                this.allKpisList.push(...eachKpi.kpis);
              }
            });
            console.log("allKpisList", this.allKpisList)
            // result.data.map((eachKpi: any) => {
            //   if (eachKpi.kpis != null && eachKpi.kpis.length != 0) {
            //     eachKpi.kpis.map((kpiEach: any)=>{
            //       // console.log("kpiEach", kpiEach)
            //       this.allKpisList.push(kpiEach);
            //     })
            //   }
            // });
          });
      } catch (e) {
        toast.error("error", {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
      this.showLoader = false;
    },
    // async getAllRootKpis() {
    //   this.allRootKpis = [];
    //   // this.allRootKpisUnfiltered = [];
    //   try {
    //     await this.$http
    //       .get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/kpis/get`)
    //       .then((result: { data: any }) => {
    //         // console.log("allKpisList", this.allKpisList)
    //         // console.log("result.data", result.data)
    //         result.data.forEach((kpi:any)=>{
    //           const eachKpi = this.allKpisList.find((everyKpi: any) => everyKpi.referenceKpiId !== kpi._id);
    //           if (eachKpi) {
    //             this.allRootKpis.push(eachKpi);
    //           }
    //         })
    //         console.log("allRootKpis", this.allRootKpis)
    //         console.log("allOrgControls", this.allOrgControls)
    //         // this.allRootKpisUnfiltered = [...this.allRootKpis];
    //       });
    //   } catch (e) {
    //     toast.error("error", {
    //       timeout: 1000,
    //       closeOnClick: true,
    //       closeButton: "button",
    //       icon: true,
    //     });
    //   }
    // },
    async toggleTabs(navIndex: any) {
      this.selectedTabIndex = navIndex;
      this.calculateWeightAge(0);
      this.selectedRootKpis = [];
      this.filters = {};
      this.showFilters = false;
      sessionStorage.setItem('selectedTab', navIndex);
    },
    newWeightage(kpiObj: any, previousWeightage: number = 0) {
    const totalWeightage = this.showTotalKpisWeightage - previousWeightage + kpiObj.weightage;
    return totalWeightage;
    },
     handleInput(event : any) {
     console.log('event',event)
      this.addingKpiObj.weightage = event.target.value.replace(/[^\d]/g, '');

    },
    weightageLessThanZero(weightage : any){
      if(weightage < 0 || weightage == 0){
          this.numberLessThanZero = true
      }else{
         this.numberLessThanZero = false
      }
    },
    weightageGreaterThanZero(kpi: any){
        this.validtaionWeightageError = false;
         let previousWeightage = 0;
          let kpiId = kpi._id;
          if (kpiId){
            const existingKpi = this.kpisListFromOrgPresentControl.find((eachKPI: any) => eachKPI._id === kpiId);
            console.log('existingKpi',existingKpi)
            console.log("kpisListFromOrgPresentControl",this.kpisListFromOrgPresentControl)
            if (existingKpi) {
              previousWeightage = existingKpi.weightage;
            }
          }
        const valid = this.newWeightage(kpi, previousWeightage);
        console.log('valid',valid)
        console.log("validvalidvalidvalid", valid);
         if (valid <= 100) {
            this.validtaionWeightageError = false;
            // this.showTotalKpisWeightage = valid;
         }else {
            this.validtaionWeightageError = true;
        }
    },
    async addOrUpdateKpiForControlManual(kpi: any) {
    this.v$.$touch();
    // this.validtaionWeightageError = false;
    if (!this.v$.$invalid && this.numberLessThanZero == false && this.validtaionWeightageError == false) {
      let previousWeightage = 0;
      let kpiId = kpi._id;

      if (kpiId) {
      // Find the previous weightage if KPI is being updated
      const existingKpi = this.kpisListFromOrgPresentControl.find((eachKPI: any) => eachKPI._id === kpiId);
      console.log("kpisListFromOrgPresentControl",this.kpisListFromOrgPresentControl)
       console.log('existingKpi',existingKpi)
      if (existingKpi) {
        previousWeightage = existingKpi.weightage;
      }
    }

    const valid = this.newWeightage(kpi, previousWeightage);
    console.log("validvalidvalidvalid", valid);

    if (valid <= 100) {
      // this.validtaionWeightageError = false;

      if (kpiId) {
        // Update the KPI
        await this.updateKpiForControl(kpi);
      } else {
        // Add new KPI
        await this.saveKpisForPresentOrgControl(kpi, 'manual');
      }

      // Update showTotalKpisWeightage
      this.showTotalKpisWeightage = valid;

      this.showPopup = false;
      this.v$.$reset();
    } else {
      // this.validtaionWeightageError = true;
    }
    } else {
      toast.error(`error`, {
        timeout: 1000,
        closeOnClick: true,
        closeButton: "button",
        icon: true,
      });
    }
  },

  async saveKpisForPresentOrgControl(kpiObj: any, action: any) {
  let finalArr: any[] = [];
  let totalWeightage = 0;
  
  if (action === 'manual') {
    totalWeightage = this.showTotalKpisWeightage + kpiObj.weightage;
  } else if (action === 'direct') {
    totalWeightage = this.showTotalKpisWeightage;
  }

  if (totalWeightage <= 100) {
    if (this.selectedTabIndex == 1) {
      this.selectedRootKpis.map((obj: any) => {
        let numeric = 1;
        this.allKpisList.forEach((item: any) => {
          if (item.kpi_id) {
            const parts = item.kpi_id.split(" ");
            if (parts.length > 2) {
              const intNumber = parseInt(parts[2]);
              if (!isNaN(intNumber)) {
                numeric = Math.max(numeric, intNumber);
              }
            }
          }
        });
        numeric++; // Increment numeric outside the loop to get the next KPI number
        let id = "KPI - " + numeric;
        obj.kpi_id = id;
        obj.referenceKpiId = obj._id;
        delete obj._id;
        finalArr.push(obj);
      });
    } else {
      finalArr.push(kpiObj);
    }

    await this.$http
      .post(
        `${process.env.VUE_APP_CONTROLS_API_URL}/api/org/control/${this.$route.params.id}/kpis/add`,
        finalArr
      )
      .then(async () => {
        toast.info("Saved", {
          timeout: 5000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
        this.selectedRootKpis = [];
        await this.getAllOrgControls();
        await this.toggleTabs(0);
        await this.fetchAllKpisInfo();
      });
      } else if (action === 'direct') {
          toast.error("Total Weightage should be less than 100", {
            timeout: 5000,
            closeOnClick: true,
            closeButton: "button",
            icon: true,
          });
        }
      },

    calculateWeightAge(tabIndex: any, e: any, weightage: any) {
      if (tabIndex == 1) {
        const checked = e.target.checked;
        this.showTotalKpisWeightage == 0
          ? (this.showTotalKpisWeightage = 0)
          : "";

        if (checked) {
          this.showTotalKpisWeightage = this.showTotalKpisWeightage + weightage;
        } else {
          this.showTotalKpisWeightage = this.showTotalKpisWeightage - weightage;
        }
        // this.selectedRootKpis.map((obj:any)=>{this.showTotalKpisWeightage = this.showTotalKpisWeightage + obj.weightage})
      } else {
        this.showTotalKpisWeightage = 0;
        if(this.kpisListFromOrgPresentControl != undefined || this.kpisListFromOrgPresentControl != null || this.kpisListFromOrgPresentControl.length !== 0){
          this.kpisListFromOrgPresentControl.map((obj: any) => {
            this.showTotalKpisWeightage = this.showTotalKpisWeightage + obj.weightage;
          });
        }else {
          console.log("this.kpisListFromOrgPresentControl",this.kpisListFromOrgPresentControl)
        }
        
      }
    },
    async updateKpiForControl(kpiObj: any) {
      let routeControlId = this.$route.params.id;
      let totalWeightage = 0
      let kpiId = kpiObj._id
      if (totalWeightage <= 100) {
      delete kpiObj._id;
      await this.$http
        .post(
          `${process.env.VUE_APP_CONTROLS_API_URL}/api/org/control/${routeControlId}/kpi/${kpiId}/update`,
          kpiObj
        )
        .then(async () => {
          toast.info("Updated", {
            timeout: 5000,
            closeOnClick: true,
            closeButton: "button",
            icon: true,
          });
          this.selectedRootKpis = [];
          await this.getAllOrgControls();
          await this.toggleTabs(0);
          // this.showPopup = false;
        });
      } else {
        toast.error("Total Weightage should be less than 100", {
          timeout: 5000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
    },
  },
  beforeUnmount() {
		sessionStorage.removeItem('selectedTab')
	}
});
</script>