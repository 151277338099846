<template>
  <div class="pt-3 pl-5 pr-5">
    <div v-if="isLoading">
      <div
        class="row w-full justify-between flex"
        style="align-items: center"
      >
        <h1 class="title">Active Controls</h1>
        <div class="cardbody flex">
          <div class="flex flex-row-reverse mr-2">
            <input type="file" accept=".xls,.xlsx" class="hidden" ref="file" @change="fileReader($event)" />
						<button class="btnprimary btns flex justify-center items-center" @click="showBlock = !showBlock">Add <img src="@/assets/Collapse.svg" alt="" class="ml-2" /></button>
						<ul class="card shadow-sm z-50 absolute cursor-pointer top-24" v-if="showBlock && showDropdown">
							<li @click="openFileModal" class="px-4 py-2 cursor-pointer border-b h-7 border-lightgrey hover:bg-lightgrey text-sm flex items-center">
								<div>Import</div>
							</li>
							<li @click="addControls" class="px-4 py-2 cursor-pointer border-b h-7 border-lightgrey hover:bg-lightgrey text-sm flex items-center">
								<div>Create</div>
							</li>
						</ul>
					</div>
          <div class="flex flex-row-reverse items-center">
            <button class="flex" @click="filterOpen" :class="tableRow.length == 0 ? 'filterButtondisabled pointer-events-none' : 'filterButton'">
              Filters
              <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <tableData
          :tableRowData="tableRow"
          :headersData="headers"
        ></tableData>
        <div v-if="toolTipActive">
          <tool-tip :tipText="toolTipText"></tool-tip>
        </div>
      </div>
      <popup v-if="showModal" :popUp="showModal">
        <template v-slot:header>
          <div class="flex items-center justify-between py-4 px-2 rounded">
            <!-- <h1 class="pl-2">{{ controlObject.controlNumber }}</h1> -->
            <h1 class="pl-2">Active Control</h1>
            <button class="float-right text-3xl" @click="onCancelbtn('close')">
              <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
            </button>
          </div>
        </template>
        <div>

      
        <fieldset>
          <div>
            <div class="statuscontainer popupbodycontainer">
              <div class="formcontainer flex flex-col">
                <div class="row flex flex-row w-full upperspacing">
                  <div class="w-full">
                    <label class="controllabel">Departments</label>
                
                            <!-- <Multiselect
                                v-model="row['auditee']"
                                :options="controlDepartmentArr"
                                :multiple="false"
                                :close-on-select="true"
                                :clear-on-select="true"
                                open-direction="bottom"
                                label="label"
                                track-by="value"  
                                :hide-selected="true"
                                @select="checkAfterRequired('check')"  
                                :searchable="true"
                                :hideArrow="false"
                                placeholder="Auditee"
                                class="custom-multiselect"
                                 
                            >
                            </Multiselect> -->
                            <!-- {{deptOptions}} -->
                            <multiselect
                              v-model="controlObject.departments"
                              :options="allDeptOptions"
                              :showNoOptions="false"
                              @search-change="asyncFind"
                              :multiple="false"
                              :close-on-select="true"
                              :clear-on-select="true"
								              :hide-selected="true"
                              open-direction="bottom"
                              label="label"
                              track-by="value"  
                              :searchable="true"
                              :hideArrow="false"
                              placeholder="Departments"
                              class="custom-multiselect userSelect mt-2"
                              :class="{
                              'is-invalid borderRed': v$.controlObject.departments.$error
                            }"
                          >
                          </multiselect>
                          <div v-if="v$.controlObject.departments.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.departments.required.$invalid">Required</div>
										</div>
                  </div>

                </div>
                  <div
                  class="border-b mt-4 border-solid border-lightgrey"
                ></div>
                <div class="row flex flex-row w-full upperspacing">
                   <div class="w-full">
                      <label class="controllabel">Title</label>
                      <textarea
                        type="text-area"
                        class="inputboxstyling mt-2"
                        placeholder="Title"
                        v-model.trim="controlObject.controlTitle"
                        :disabled="controlObject._id"
                        :class="{
												'is-invalid': v$.controlObject.controlTitle.$error
											}"
                      ></textarea>
                      <div v-if="v$.controlObject.controlTitle.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlTitle.required.$invalid">Required</div>
										</div>
                    </div>
                </div>

              
                <div class="formcontainer flex flex-col upperspacing">
                  <div class="row flex flex-row w-full">
                    <div class="w-6/12">
                      <label class="controllabel">Number</label>
                      <input
                        type="text"
                        :disabled="controlObject._id"
                        class="inputboxstyling mt-2"
                        v-model.trim="controlObject.controlNumber"
                        placeholder="Number"
                        :class="{
												'is-invalid': v$.controlObject.controlNumber.$error
											}"
                        
                      />
                      	<div v-if="v$.controlObject.controlNumber.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlNumber.required.$invalid">Required</div>
										</div>
                    </div>
                    <div class="w-3/6 leftspacing">
                      <label class="controllabel">Frequency</label>
                      <select class="inputboxstyling mt-2" :class="{ 'is-invalid': v$.controlObject.controlFrequency.$error }" v-model="controlObject.controlFrequency">
                        <option value="" selected disabled hidden>Select</option>
                        <option v-for="(value, index) in controlFrequency" :key="index" :value="value.id">{{ value.label }}</option>
                      </select>
                      <div v-if="v$.controlObject.controlFrequency.$error" class="text-red-500">
                        <div class="error-text" v-if="v$.controlObject.controlFrequency.required.$invalid">Required</div>
                      </div>
                  
                    </div>
                   
                  </div>
                </div>
                <div class="formcontainer flex flex-col upperspacing">
                  <div class="row flex flex-row w-full">
                    <div class="w-6/12">
                      <!-- <div
                        v-for="(dataValue, indexNum) in controlTypeArray"
                        :key="indexNum"
                      > -->
                        <div>
                          <label class="controllabel">Type</label>
                         <div class="mt-2"> 
                          <input
                            type="text"
                            class="inputboxstyling"
                            
                            v-model.trim="controlObject.controlType"
                            placeholder="Type"
                          />
                          </div>
                          <!-- :class="{'is-invalid': v$.controlObject.controlType.$error}" -->
                          <!-- <div v-if="v$.controlObject.controlType.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlType.required.$invalid">Required</div>
										</div> -->
                        </div>
                      <!-- </div> -->
                    </div>
                  <div class="w-6/12 col-md-6 leftspacing">
										<label class="controllabel">Priority</label>
										<select class="inputboxstyling mt-2" v-model="controlObject.controlPriority">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="(value, index) in priorityArray" :key="index" :value="value.id">{{ value.label }}</option>
										</select>
                    <!-- :class="{ 'is-invalid': v$.controlObject.controlPriority.$error }" -->
                    <!-- <div v-if="v$.controlObject.controlPriority.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlPriority.required.$invalid">Required</div>
										</div> -->
									
									</div>
                  </div>
                </div>
                <div class="formcontainer flex flex-col upperspacing">
                  <div class="row flex flex-row w-full">
                    <div class="w-6/12">
                      <label class="controllabel">Family</label>
                       <multiselect
                              v-model="controlObject.controlFamily"
                              :options="filteredControlFamilyOptions"
                              :showNoOptions="false"
                              @search-change="asyncFindControlFamily"
                              @select="selectedOption"
                              :multiple="false"
                              :searchable="true"
                              :hide-selected="true"
                              open-direction="bottom"
                              :hideArrow="false"
                              placeholder="Family"
                              class="custom-multiselect userSelect mt-2"
                          >
                          </multiselect>
                          <!-- :class="{
                              'is-invalid borderRed': v$.controlObject.controlFamily.$error
                            }" -->
                      <!-- <div v-if="v$.controlObject.controlFamily.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlFamily.required.$invalid">Required</div>
										</div> -->
                    </div>
                    <div class="w-3/6 leftspacing">
                      <label class="controllabel">Class</label>
                      <input
                        type="text"
                        class="inputboxstyling mt-2"
                        placeholder="Class"
                        v-model.trim="controlObject.controlClass"
                        
                      />
                      <!-- :class="{
												'is-invalid': v$.controlObject.controlClass.$error
											}" -->
                      <!-- <div v-if="v$.controlObject.controlClass.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlClass.required.$invalid">Required</div>
										</div> -->
                    </div>
                  </div>
                </div>
                <!--  <div class="row flex flex-row w-full upperspacing">
									<div class="w-full">
										<label class="controllabel">Departments</label>
										<Multiselect
											v-model="controlDepartmentArr"
											mode="tags"
											:close-on-select="false"
											:options="deptOptions"
											:caret="false"
											:showOptions="false"
											:classes="{
												container: 'relative h-fit mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-lightgrey rounded bg-white text-base leading-snug outline-none'
											}"
											:canClear="false"
											:disabled="true"
											placeholder="Departments"
										>
											<template v-slot:tag="{ option, handleTagRemove, disabled }">
												<div
													class="multiselect-tag"
													:class="{
														'is-disabled': disabled
													}"
												>
													{{ option.label }}
													<span v-if="!disabled" class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
														<span class="multiselect-tag-remove-icon"></span>
													</span>
												</div>
											</template>
										</Multiselect>
									</div>
								</div> -->

                <div class="row flex flex-row w-full upperspacing">
                  <div class="w-full">
                    <label class="controllabel">Audit Methodology</label>
                    <textarea
                      type="text-area"
                      class="inputboxstyling mt-2"
                      placeholder="Audit Methodology"
                      v-model.trim="controlObject.controlAuditMethodology"
                      
                    >
                    </textarea>
                  </div>
                </div>

                <div class="row flex flex-row w-full upperspacing">
                  <div class="w-full">
                    <label class="controllabel">Audit Success Criteria</label>
                    <textarea
                      type="text-area"
                      class="inputboxstyling h-24 mt-2"
                      placeholder="Audit Success Criteria"
                      v-model.trim="controlObject.controlAuditSuccessCriteria"
                      
                    >
                    </textarea>
                  </div>
                </div>
                <div class="row flex flex-row w-full upperspacing">
                  <div class="w-full">
                    <label class="controllabel">Maintenance Task</label>
                    <textarea
                      type="text-area"
                      class="inputboxstyling h-24 mt-2"
                      placeholder="Maintenance Task"
                      v-model.trim="controlObject.controlMaintenanceTask"
                      
                    >
                    </textarea>
                  </div>
                </div>
                <div class="row flex flex-row w-full upperspacing">
                  <div class="w-full">
                    <label class="controllabel">Policies</label>
                    <textarea
                      type="text-area"
                      class="inputboxstyling h-24 mt-2"
                      placeholder="Policies"
                      v-model.trim="controlObject.controlPolicies"
                      
                    >
                    </textarea>
                  </div>
                </div>
                <div class="row flex flex-row w-full upperspacing">
                  <div class="w-full">
                    <label class="controllabel">Guidance</label>
                    <textarea
                      type="text-area"
                      class="inputboxstyling h-24 mt-2"
                      placeholder="Guidance"
                      v-model.trim="controlObject.controlGuidance"
                      
                    >
                    </textarea>
                  </div>
                </div>
                	<div class="w-full col-md-6 upperspacing">
									<label class="controllabel">Frameworks</label>
									<!-- <select class="inputboxstyling" @change="getFrameworkControls(controlObject.controlOptionFrameWork)" v-model="controlObject.controlOptionFrameWork">
										<option value="" selected disabled hidden>Select</option>
										<option v-for="data in frameWorksList" :key="data.label" :value="data.label">{{ data.label }}</option>
									</select> -->
                  <multiselect
                  v-model="controlObject.controlOptionFrameWork"
                  @select="getFrameworkControls(controlObject.controlOptionFrameWork)"
                  :multiple="false"
                  :options="frameWorksList"
                  :searchable="true"
                  :hide-selected="true"
                  open-direction="bottom"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Frameworks"
                  class="custom-multiselect mt-2"
                >
                </multiselect>
									<!-- <div v-if="v$.controlObject.controlOptionFrameWork.$error" class="text-red-500">
										<div class="error-text" v-if="v$.controlObject.controlOptionFrameWork.required.$invalid">Required</div>
									</div> -->
								</div>
								<div class="w-full col-md-6 upperspacing">
									<label class="controllabel">Controls</label>
									<!-- <select class="inputboxstyling" v-model="controlObject.frameworkControls" :disabled="Object.keys(controlObject).includes('controlOptionFrameWork') && controlObject.controlOptionFrameWork == ''">
										<option value="" selected disabled hidden>Select</option>
										<option v-for="data in frameworkControls" :key="data.control_number" :value="data.control_number">{{ data.control_number + data.control_title }}</option>
									</select> -->
                  <multiselect
                  v-model="controlObject.frameworkControls"
                  :disabled="Object.keys(controlObject).includes('controlOptionFrameWork') && controlObject.controlOptionFrameWork == ''"
                  :multiple="false"
                  :options="frameworkControls"
                  :searchable="true"
                  :hide-selected="true"
                  open-direction="bottom"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Controls"
                  class="custom-multiselect mt-2"
                  :class="{
                    'is-invalid borderRed': v$.controlObject.frameworkControls.$error
                  }"
                >
                </multiselect>
									<div v-if="v$.controlObject.frameworkControls.$error" class="text-red-500">
										<div class="error-text" v-if="v$.controlObject.frameworkControls.required.$invalid">Required</div>
									</div>
								</div>
              </div>
            </div>
          </div>
        </fieldset>
          </div>
          <div class="buttonposition flex justify-center items-center my-4 mx-0">
							<button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn('cancel')">Cancel</button>
							<button class="btnprimary" v-if="!controlObject._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="saveCustomControl(controlObject)">Save</button>
							<button class="btnprimary" v-else :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="updateControl(controlObject)">Update</button>
						</div>
      </popup>
    </div>
    <loader v-if="showLoader" />
  </div>
</template>
<style  scoped>
.multiselect-tag {
  font-size: 12px;
  font-weight: 600;
}
.multiselect-tag.is-disabled {
  background-color: #059669 !important;
}
.multiselect.is-disabled {
  background-color: white !important;
}
.tbodylast:last-child {
  border-bottom: 1px solid #e9e9e9;
}
::-webkit-scrollbar {
  width: 3px !important;
}
.borderRed {
  border: 1px solid red !important;
}
</style>
<script lang="ts">
import { emitter, toast } from "@/main";
import * as XLSX from 'xlsx';
import _ from "lodash";
import { defineComponent } from "vue";
import loader from "../../components/loader.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { groupBy } from "lodash";
import Multiselect from 'vue-multiselect';
import popup from "@/components/popup.vue";
import tableData from "@/components/tableData.vue";
import { requiredIf } from '@vuelidate/validators';
import toolTip from "@/components/toolTip.vue";
export default defineComponent({
  data(): any {
    return {
      headers: [
        "_id",
        "departments",
        "frameWork",
        "cCID",
        "activeControlsTitle",
        "Class",
        "KPIs",
        "actionButtonForKPIs"
      ],
      filteredControlFamilyOptions:[],

      v$: useVuelidate(),
      showBlock: false,
			showDropdown: false,
      isLoading: false,
      showLoader: false,
      toolTipActive : false,
      showFilters: false,
      showModal: false,
      toolTipText : '',
      dummyList: [],
      controlsList: [],
      departmentsList: [],
      selectedControlForKPI: {},
      kpiListForControl: [],
      selectedControl: {},
      ControlFamilyArray:[],

      filters: {},

      tableRow: [],
      controlFamilies:[],
      columnNames: {
        controlNumber: "Control Number",
        controlTitle: "Control Title",
        controlFamily: "Control Family",
        controlClass: "Control Class",
        kpi: "KPI's",
        link: "link",
      },
      nonTableRow: [],
      allDeptOptions:[],
      columnObject: false,
      priority: ["Critical", "High", "Moderate", "Low"],
      priorityArray: [
        { label: "Very Low", id: 1 },
        { label: "Low", id: 2 },
        { label: "Moderate", id: 3 },
        { label: "High", id: 4 },
        { label: "Critical", id: 5 },
      ],
      editObject: {},
      deptOptions: [],
      controlDepartmentArr: [],
      controlTypeArray: [
        { label: "Preventive", id: 1 },
        { label: "Detective", id: 2 },
        { label: "Corrective", id: 3 },
        { label: "Deterrent", id: 4 },
        { label: "Directive", id: 5 },
        { label: "Recovery", id: 6 },
        { label: "Compensating", id: 7 },
      ],
      controlFrequency: [
				{ label: 'Transactional', id: 1 },
				{ label: 'Daily', id: 2 },
				{ label: 'Weekly', id: 3 },
				{ label: 'Monthly', id: 4 },
				{ label: 'Quarterly', id: 5 },
				{ label: 'Semi Annualy', id: 6 },
				{ label: 'Annualy', id: 7 },
				{ label: 'Ad-Hoc', id: 8 }
			],
      optionsValue: [
        {
          id: 1,
          value: "KPI",
          route: "/manageKPI",
          presentRoute: "/org/controls/active",
        },
      ],
      frameWorksList: [],
      frameworkControls: [],
      dummyControlList:[],
      relatedFrameControls: [],
      controlObject: {
        controlFrequency:"",
        controlNumber: "",
        controlPriority: "",
        controlTitle: "",
        controlClass: "",
        controlFamily: "",
        controlGuidance: "",
        controlAuditMethodology: "",
        controlAuditSuccessCriteria: "",
        controlMaintenanceTask: "",
        controlPolicies: "",
        controlType: "",
        departments: [],
        controlOptionFrameWork: '',
				frameworkControls: ''
      },
      fullKeys:['departments', 'controlNumber', 'controlTitle', 'controlGuidance', 'controlClass', 'controlFamily', 'controlType', 'controlPriority', 'controlFrameWork'],
    };
  },
 validations(){
        return{
			controlObject:{
				departments:{required},
        controlNumber: { required },
        controlTitle: { required },
        controlFrequency: { required },
				frameworkControls:{
					required: requiredIf(() => {
						return Object.keys(this.controlObject).includes('controlOptionFrameWork') && this.controlObject.controlOptionFrameWork != '';
					})
				},
				
			},
        }
    },
  
  components: {
    loader,
    popup,
    Multiselect,
    tableData,
    toolTip
  },
  async mounted() {
    		await this.orgFetchControlsInfo();

    await this.getAllControls();
    	emitter.on('*', (type, booleanValue) => {
			if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
				this.showBlock == true ? ((this.showBlock = false), (this.showDropdown = false)) : '';
			} else {
				if (this.showBlock == true) {
					if (this.showDropdown == false) {
						this.showDropdown = true;
					} else {
						this.showDropdown = false;
						this.showBlock = false;
					}
				} else {
					this.showDropdown = false;
				}
			}
		});
	
    emitter.on("activeControlPopup", (isData: any) => {
      let comingId = isData.entityData._id;
      let showObj = this.controlsList.find((obj: any) => {
        return obj._id === comingId;
      });
      this.showEditPopUp(showObj, isData.indexx);
    });
    
  },
  async created() {
    await this.fetchDepartmentsList();
    await this.fetchControlsInfo();
    emitter.off('activiteToolTip');
    emitter.on('activiteToolTip', (data: any) => {
        this.toolTipActive = true;
        this.toolTipText = data.tipText
    });
    emitter.off('deActiviteToolTip');
    emitter.on('deActiviteToolTip', (data: any) => {
        this.toolTipActive = false;
        this.toolTipText = ''
    });
  },
  computed:{
    disableStatus: function (): any {
			let disableStatusCondition;
			if (this.controlObject._id) {
				if (JSON.stringify(this.controlObject) == JSON.stringify(this.editObject)) {
					disableStatusCondition = false;
				} else {
					disableStatusCondition = true;
				}
			} else {
				let val: any = [];
				Object.values(this.controlObject).forEach((value: any) => {
					val.push(value);
				});
				for (let n of val) {
					if (n != '') {
						disableStatusCondition = true;
					}
				}
			}
			return disableStatusCondition;
		}

  },
  methods: {
    asyncFind(query:any){
      if(query == ''){
          this.allDeptOptions = []
      }else if(query.length > 1){
          this.allDeptOptions = this.deptOptions.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
      }
      console.log("deptOptionsdeptOptionsdeptOptions",this.deptOptions)
    },
    selectedOption(opt:any){
      let isNewOption = !this.ControlFamilyArray.includes(opt);
      isNewOption==true ? this.ControlFamilyArray.push(opt) : ''
    },
    asyncFindControlFamily(query:any) {
        query = query.trim();
        if (query == '') {
				this.filteredControlFamilyOptions = [];
			} else {
				console.log("this.ControlFamilyArray",this.ControlFamilyArray)
				this.filteredControlFamilyOptions = this.ControlFamilyArray.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.ControlFamilyArray.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.filteredControlFamilyOptions.push(query);
				}
			}
        // // let isNewOption = !this.ControlFamilyArray.includes(query);
        // let isNewOption = !(this.ControlFamilyArray.some((item:any) => {return item?.toLowerCase().includes(query.toLowerCase())}))

  	    // if(isNewOption == true && query !=''){
        //   // this.ControlFamilyArray.push(query);
        //   this.filteredControlFamilyOptions=[]
        //   this.filteredControlFamilyOptions.push(query)
        // }else if (query == '') {
        //     this.filteredControlFamilyOptions = [];
        // } else if (query.length > 1) {
        //     this.filteredControlFamilyOptions = this.ControlFamilyArray.filter((item:any) => {return item?.toLowerCase().includes(query.toLowerCase())});
        // }
    },
    addCustomTag(newTag:any) {
      console.log('addCustomTag called with newTag:', newTag);
      // if (!this.filteredControlFamilyOptions.includes(newTag)) {
        this.filteredControlFamilyOptions.push(newTag);
      // }
    },
    getControlNumberAndTitle(name: any){
      const result: any = Object.values(this.jsonArray).flatMap(x => x).find((y: any) => y.controlNumber === name);
      if (result) {
        return `${result.controlNumber}--${result.controlTitle}`;
      }
    },
    getFrameworkControls(name:any){
      this.frameworkControls=[];
      let array=Object.values(this.jsonArray)
      array.forEach((x:any)=>{
        x.forEach((y:any)=>{
          if(y.controlFrameWork == name){
            this.frameworkControls.push(`${y.controlNumber}` + `--` + `${y.controlTitle }`);
          }
        })
      })
		},
    async getAllControls(frameName: any) {
			emitter.emit('visible', false);
			this.showFilters = false;
			try {
				this.isLoading = false;
				const result = await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`);
				this.isLoading = true;
				this.columnObject = false;
				this.rowData = result.data;
				//  jsonArray: any = [];
				let arr: any = [];
        if((this.rowData != null || this.rowData != undefined) && this.rowData.length != 0){
          this.rowData.forEach((item: any) => {
            if (item.controlFrameWork && item.controlNumber && Object.keys(item).includes('controlFrameWork') && Object.keys(item).includes('controlNumber') && !Object.keys(item).includes('isInternalControl')) {
              arr.push(item);
              this.jsonArray = this.groupByFrameworks(arr);
            }
          });
          // let array=Object.values(this.jsonArray)
          // array.forEach((data:any)=>{
          // 	data.forEach(())
          // })
          
          let displayFrameName = '';	
          if(this.jsonArray != undefined){
            Object.keys(this.jsonArray).forEach((key, index) => {
              if (frameName === index) {
                displayFrameName = key;
              }
              this.frameWorksList.push(key);
            });
          }
          // Object.values(jsonArray).forEach((value, index) => {
          // 	this.frameworkControls = value
          // 	// if (frameName === index) {
          // 	// 	displayFrameName = key;
          // 	// }
          // 	// this.frameWorksList.push({value: index, displayName: key });
          // });

          // this.frameworkControls = [];
          // this.frameworkControls = arr
          //   .filter((control: any) => control.controlFrameWork && control.controlFrameWork === displayFrameName)
          //   .map(
          //     (control: any) => ({ value: control._id, label: control.controlNumber + control.controlTitle })
          //     // .push({value: control._id, label: control.controlNumber + control.controlTitle})
          //   );
        }
			} catch (e) {
				this.isLoading = false;
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
    groupByFrameworks(arr: any[]) {
			return arr.reduce((group: any, product: any) => {
				const { controlFrameWork } = product;
				group[controlFrameWork] = group[controlFrameWork] ?? [];
				group[controlFrameWork].push(product);
				return group;
			}, {});
		},
    	addControls() {
			// (this.controlObject.controlFrameWork = ''),
			// 	(this.controlObject.controlMapFrameWork = ''),
			// 	(this.controlObject.controlNumber = ''),
			// 	(this.controlObject.controlPriority = ''),
			// 	(this.controlObject.controlTitle = ''),
			// 	(this.controlObject.controlClass = ''),
			// 	(this.controlObject.controlFamily = ''),
			// 	(this.controlObject.controlGuidance = ''),
			// 	(this.controlObject.controlAuditMethodology = ''),
			// 	(this.controlObject.controlAuditSuccessCriteria = ''),
			// 	(this.controlObject.controlMaintenanceTask = ''),
			// 	(this.controlObject.controlPolicies = ''),
			// 	(this.controlObject.controlType = ''),
			// 	(this.controlObject.controlFrequency = '');
			// 	this.controlObject.controlOptionFrameWork='';
			// 	this.controlObject.frameworkControls=[];

			this.v$.$reset();
			// this.editObject = {};
			Object.keys(this.controlObject).forEach((prop) => {
				this.controlObject[prop] = '';
			});
			Object.keys(this.editObject).forEach((prop) => {
				this.editObject[prop] = '';
			});
			// delete this.controlObject._id;
			this.showModal = true;
		},
    
    onCancelbtn(status: any) {
      // this.showModal = false;
			this.v$.$reset();
			this.showBlock = false;
			if (status === 'close') {
				Object.keys(this.controlObject).forEach((prop) => {
          Array.isArray(this.controlObject[prop])
            ? (this.controlObject[prop] = [])
            : (this.controlObject[prop] = "");
        });
        delete this.controlObject._id
				this.showModal = false;
			} else if (status == 'cancel') {
       Object.keys(this.controlObject).forEach((prop) => {
          Array.isArray(this.controlObject[prop])
            ? (this.controlObject[prop] = [])
            : (this.controlObject[prop] = "");
        });
				this.controlObject = { ...this.editObject };
       
        
			}
		},
    async updateControl(controlObject: any) {
			this.v$.$touch();
			if (!this.v$.$invalid) {
				// let uniqueControlNumber = this.dummyControlList.some((item: any) => item.controlNumber === this.controlObject.controlNumber);

				// if (uniqueControlNumber) {
					this.showModal = false;
					let objId = this.controlObject._id;
          if(this.controlObject.controlOptionFrameWork && this.controlObject.frameworkControls){
            let arr = []
            let frameWork
            if(controlObject.controlOptionFrameWork){
              frameWork = controlObject.controlOptionFrameWork
            }
            
            // let controls = controlObject.frameworkControls
            let controls 
            if(controlObject.frameworkControls){
              let parts = controlObject.frameworkControls.split("--");
              controls = parts[0];
            }
            delete this.controlObject.frameworkControls
            delete this.controlObject.controlOptionFrameWork
            let obj = {
              framework: frameWork,
              controls: [controls]
            }
            arr.push(obj)
            this.controlObject.frameworks = arr
          }
          this.controlObject.departments = this.controlObject.departments.value
					// delete controlObject._id;
					try {
						await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controlset/update/${objId}`, _.omit(this.controlObject, ['_id'])).then((result: any) => {
							toast.info(`Updated Successfully`, {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
						});
					} catch (e) {
						toast.error(`Something went wrong`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
          this.v$.$reset();
					await this.fetchControlsInfo();
				// } else {
				// 	this.showModal = false;
				// 	toast.error(`Control Number should be unique`, {
				// 		timeout: 1000,
				// 		closeOnClick: true,
				// 		closeButton: 'button',
				// 		icon: true
				// 	});
				// }
			}
		},
    async orgFetchControlsInfo() {
			this.controlsList = [];
			this.showLoader = true;
			this.isLoading = false;
			await this.$http
				.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls?frameWork=InternalControls`)
				.then((result: { data: any }) => {
					this.isLoading = true;
					this.showLoader = false;
					result.data.forEach((item: any) => {
						if (item.isInternalControl) {
							this.controlsList.push(item);
						}
					});
          this.editObject = {...this.controlObject}
					this.dummyControlList = [...this.controlsList];
				})
				.catch((error: any) => {
					this.isLoading = false;
					(this.showLoader = false),
						toast.error(`Save failed with ${error}`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
				});
			//this.makeTable();
		},
    	controlNumberUnique(controlObject: any) {
			let uniqueControlNumber: any;
			uniqueControlNumber = this.dummyControlList.some((item: any) => item.controlNumber === controlObject.controlNumber);
			if (uniqueControlNumber) {
				toast.error(`Control Number should be unique`, {
					timeout: 2000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
    async saveCustomControl(controlObject: any) {
			this.v$.$touch();
			if (!this.v$.controlObject.$invalid) {
				let uniqueControlNumber: any;
				uniqueControlNumber = this.dummyControlList.some((item: any) => item.controlNumber === controlObject.controlNumber);
				let arr = []
        let frameWork
        if(controlObject.controlOptionFrameWork){
          frameWork = controlObject.controlOptionFrameWork
        }
				
				// let controls = controlObject.frameworkControls
        let controls 
        if(controlObject.frameworkControls){
          let parts = controlObject.frameworkControls.split("--");
          controls = parts[0];
        }
				delete controlObject.frameworkControls
				delete controlObject.controlOptionFrameWork
        if(frameWork != "" && controls != ""){
          let obj = {
					  framework: frameWork,
            controls: [controls]
          }
          arr.push(obj)
          controlObject.frameworks = arr
        }
				if (uniqueControlNumber == false) {
					this.showModal = false;
					let payload;
					let framework = 'InternalControls';
					payload = {
						controlFrameWork: framework,
						controlsList: [{ ...controlObject, isInternalControl: true }]
					};

					await this.addControlSource([payload], 'toast', controlObject);
				} else {
					this.showModal = false;
					toast.error(`Control Number should be unique`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			}
		},
    async addControlSource(payload: any, action: any, controlObject: any) {
			try {
        // let departmentId = controlObject.departments
        payload[0].controlsList.forEach((obj: any)=>{
          delete obj.departments
        })
        delete payload.frameworkControls
				delete payload.controlOptionFrameWork
        console.log("departments", payload)
				await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controlset/create`, payload).then(async (result: any) => {
          
          let payloadForControlIds: any = [];
            let controlRouteId: any[] = [];
            controlRouteId = result.data.insertedIds[0];
            let departmentRouteId = controlObject.departments;
            // console.log("payload", payload[0].controlsList);
            // payload[0].controlsList.map((obj: any)=>{
            //   console.log("objj", obj);
            //   let departmentRouteId = '';
            //   departmentRouteId =  obj.departments
            //   payloadForControlIds = [
            //     {
            //       departmentId: departmentRouteId,
            //       controlIds: obj._id
            //     }
            //   ];
            // });
            // console.log("payloadForControlIds", payloadForControlIds)
            payloadForControlIds = [
            	{
            		departmentId: departmentRouteId.value,
            		controlIds: [controlRouteId]
            	}
            ];
            await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/manageDepartments`, payloadForControlIds).then(() => {
            	
            });
					if (action == 'toast') {
            toast.info(`Saved Successfully`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: 'button',
              icon: true
            });
					}
				});
        await this.fetchControlsInfo();
        await this.makeTable();
			} catch (e) {
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
    showEditPopUp(data: any, index: any) {
      console.log('Dep',data);   
      this.departmentsList.find((item: any) => {
        if(item._id == data.departments){
          data.departments = {value: item._id, label: item.teamName };
        }
      });
      if(Object.keys(data).includes('frameworks') && data.frameworks.length != 0){
        data.controlOptionFrameWork = data.frameworks[0].framework ? data.frameworks[0].framework:''
        this.getFrameworkControls(data.controlOptionFrameWork)
        data.frameworkControls = data.frameworks[0].controls[0]? this.getControlNumberAndTitle(data.frameworks[0].controls[0]) : ''
      }
      if(data.controlFrequency == undefined || data.controlFrequency == null || data.controlFrequency == ''){
        data.controlFrequency = ''
      } if(data.controlPriority == undefined || data.controlPriority == null || data.controlPriority == ''){
        data.controlPriority = ''
      }
      console.log('frequenvy',data.controlFrequency,data.controlPriority)
      this.controlObject = { ...data };
      this.editObject = { ...this.controlObject };
      this.showModal = true;
    },
    openFileModal() {
      this.$router.push({ name: 'controlsImport' });
			// this.$refs.file.click();
			// this.showBlock = false;
		},
    makeTable() {
      this.tableRow = [];
      if (this.controlsList.length > 0) {
        this.controlsList.forEach((item: any) => {
          const kpisLength = item.kpis ? item.kpis.length : 0;
          let frameName;
          let tableObj:any
          frameName = item.frameworks ? item.frameworks[0].framework : "N/A";
          let deptName = this.departmentsList.find((eachdepartment: any) => {return item.departments == eachdepartment._id});

          tableObj = {
            _id: item._id,
            departments: deptName ? deptName.teamName : 'N/A',
            frameWork: frameName ? frameName : 'N/A',
            cCID: item.clientUniqueId ? item.clientUniqueId : 'N/A',
            activeControlsTitle: item.controlTitle  ? item.controlTitle : 'N/A',
            Class: item.controlClass ? item.controlClass : 'N/A',
            "KPIs": kpisLength,
          }

          this.tableRow.push(tableObj);
        });

        this.columnObject = false;
      } else {
        this.columnObject = true;
        // this.tableRow.push({
        //   _id: "",
        //   departments: "",
        //   frameWork: "",
        //   cCID: "",
        //   activeControlsTitle: "",
        //   Class: "",
        //   "KPIs": "",
        // });
      }
    },
    fileReader(oEvent: any): any {
			var oFile = oEvent.target.files[0];
			var reader = new FileReader();
			reader.onload = async (e: any) => {
				let data: any = e.target.result;
				data = new Uint8Array(data);
				var workbook = XLSX.read(data, { type: 'array' });
				var first_sheet_name = workbook.SheetNames[0];
				var worksheet = workbook.Sheets[first_sheet_name];
				var jsonObj = XLSX.utils.sheet_to_json(worksheet, {
					raw: true,
					header: this.fullKeys,
					range: 1
				});

				let total = jsonObj.length;
				let valid = 0;
				const jsonArray: any = [];
				let framework = '';
				const uniqueControlNumbers = new Set<string>();
				let booleanValue = true;
				for (let i = 0; i < jsonObj.length; i++) {
					let item: any;
					item = jsonObj[i];
					let arr = ['departments', 'controlNumber', 'controlFamily', 'controlClass', 'controlTitle', 'controlPriority', 'controlType'];
					let mandatoryCondition = arr.every((key: any) => {
						return Object.keys(item).includes(key) && item[key];
					});
					let PriorityObj = this.priorityArray.find((obj: any) => {
						return obj.label == item.controlPriority;
					});
					item.controlPriority = PriorityObj.id;

					// let TypeObj = this.controlTypeArray.find((obj: any) => {
					// 	return obj.label == item.controlType;
					// });
					// item.controlType = TypeObj.id;
          let controlCheck
          if((this.dummyControlList != undefined || this.dummyControlList != null) && this.dummyControlList.length > 0){
            controlCheck = this.dummyControlList.some((control: any)=>{
              return control.controlNumber === item.controlNumber
            })
          }
          console.log("controlCheck", controlCheck)
          let deptId = this.departmentsList.find((obj: any) => {
            return obj.teamName === item.departments
					});
          if(deptId != undefined || deptId != null){
            item.departments = deptId._id;
          }
					if (mandatoryCondition && (deptId != undefined || deptId != null) && !controlCheck) {
						if (!uniqueControlNumbers.has(item.controlNumber)) {
							booleanValue = false;
							framework = 'InternalControls';
							// objectArr.push(item);
              jsonArray[item.departments] = jsonArray[item.departments] || [];
							jsonArray[item.departments].push(item);
							uniqueControlNumbers.add(item.controlNumber);
						}
					}
				}
				if (booleanValue) {
					toast.error(`Control Number should be unique`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
        console.log("jsonArray", jsonArray)
				let controlSets: any = [];
				let controlsFinal: any = [];
				Object.keys(jsonArray).forEach((key) => {
					// const temp = {
					// 	controlFrameWork: framework,
					  jsonArray[key].map((item: any) => {
              controlsFinal.push({ ...item, isInternalControl: true })
            });
					// };
					valid = valid + controlsFinal.length;
					
				});
        const temp = {
          controlFrameWork: framework,
          controlsList: controlsFinal
        };
        controlSets.push(temp);
				// this.addControl = true;
        console.log("controlSets", controlSets)
				await this.addControlSource(controlSets, 'toast');
				if (controlSets.length > 0) {
					toast.info(`Imported ${total} rows, Valid rows: ${valid}, Ignored rows: ${total - valid}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				} else {
					toast.error(`Something went wrong`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			};
			reader.readAsArrayBuffer(oFile);
		},
    async fetchControlsInfo() {
      this.controlsList = [];
      this.tableRow = [];
      this.dummyList = [];
      this.showLoader = true;
      await this.$http
        .get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active`)
        .then((result: { data: any }) => {
          this.isLoading = true;
          this.showLoader = false;
          // _.each(
          //   result.data,
          //   (control: { department: any; departments: string | any[] }) => {
          //     // if (control.departments.length > 0) {
          //     //   _.each(control.departments, (dept: any) => {
          //     //     control.department = dept;
          //     //   });
          //     // }
          //     let updatedObject = Object.assign({}, control);
          //     controlList.push(updatedObject);
          //   }
          // );
          this.controlsList = result.data;
          this.familyData = result.data.filter((obj: any) => {
            return Object.keys(obj).includes("controlFamily");
          });
          console.log("familyData",this.familyData);
          const groupedControlsFamily = groupBy(
            this.familyData,
            "controlFamily"
          );
          console.log("groupedControlsFamilygroupedControlsFamily",groupedControlsFamily)
          const listOfFamily = Object.keys(groupedControlsFamily);
          listOfFamily.forEach((key) => {
            if (key != "" || key != null || key != undefined) {
              this.ControlFamilyArray.push(key);
            }
            console.log("ControlFamilyArray",this.ControlFamilyArray)
          });
          this.dummyList = {...this.controlsList};
        })
        .catch((error: any) => {
          this.isLoading = false;
          (this.showLoader = false),
            this.$toasted.error(error, {
              theme: "toasted-primary",
              duration: 1000,
            });
        });
      await this.makeTable();
    },
    async fetchDepartmentsList() {
      this.showLoader = true;
      await this.$http
        .get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`)
        .then((res: { data: any }) => {
          this.isLoading = true;
          this.showLoader = false;
          this.departmentsList = res.data;
        })
        .catch((error: any) => {
          this.isLoading = false;
          (this.showLoader = false),
            toast.error(`Save failed with ${error}`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
        });
      this.deptOptions = this.departmentsList.map((item: any) => {
        return { value: item._id, label: item.teamName };
      });
    },
    filterOpen() {
      if (this.columnObject == false) {
        this.showFilters = !this.showFilters;
        emitter.emit("visible", this.showFilters);
      }
    },
  },
});
</script>