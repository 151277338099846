<template>
	<div class="pt-3 pl-5 pr-5">
		<div class="mb-2">
			<breadCrumb :routesArray="routesArray"></breadCrumb>
		</div>
		<div>
			<div class="items-center justify-between flex">
				<div class="title flex items-center">
					<h1 class="title" :class="titleLength > 40? 'heading-ellipsis':''">{{presentControlObject.controlTitle}}</h1>
				</div>
				<div class="flex flex-row-reverse">
					<button class="flex" @click="filterOpen" :class="(this.selectedTabIndex == 0 && this.kpisListForPresentControl.length != 0) || (this.selectedTabIndex == 1 && this.allRootKpis.length != 0) ? 'filterButton' : 'filterButtondisabled pointer-events-none'">
						Filters
						<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
					</button>
					<button class="mr-3" v-if="selectedTabIndex == 0" :class="showTotalKpisWeightage < 100 ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addKPI(kpi)">Add</button>
				</div>
			</div>
			<div class="mt-5 px-5 pb-5 card">
            <div class="mr-2.5">
				<div ref="carouselContainer" class="carousel-container">
				<div class="w-3/12 pt-5 flex flex-row borderBottom justify-between overflow-hidden cursor-pointer">
					<div class="ProgessStyle mr-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in carouselHead" :key="tabIndex" @click="toggleTabs(tab.id)">
						<div>
							<div ref="actualIndex">{{ tab.displayName }}</div>
							<div :class="selectedTabIndex == tab.id ? 'progress' : ''" class="h-1 p-0.75"></div>
						</div>
					</div>
				</div>
				</div>
				<div class="progressbarSI h-1 mr-2.5"></div>
			</div>
			
			
			<div class="button-adjust flex justify-between w-full" v-if="carouselHead.length > pageItems">
				<div class="marginprev" @click="leftButton">
					<img src="@/assets/leftArrowFilled.svg" class="w-4 h-4" :class="cantGoBack ? 'disabled pointer-events-none' : 'mr-2.5'" @click="prevPage" />
				</div>
				<div class="marginnext" @click="rightButton">
					<img src="@/assets/rightArrowFilled.svg" class="w-4 h-4" :class="cantGoForward ? 'disabled pointer-events-none' : 'ml-2.5'" @click="nextPage" />
				</div>
			</div>
			<div>
				<div v-if="selectedTabIndex == 0">
					<div class="ResponsivetableCornerRadius bg-white">
						<!-- :class="sortedAppliedKpisData.length == 0 ? 'border-separate' : 'border-collapse'" -->
						<table>
							<thead>
								<th scope="col" v-for="column in tableHeads" :key="column" class="bg-white cursor-pointer capitalize" @click="sort(column)">
									<div>
										<div class="flex">
											<div v-if="column == 'description'">KPI Name</div>
											<div v-else-if="column == 'weightage'">weightage (%)</div>
											<!-- <div v-else-if="column == 'target'">Target (%)</div>
											<div v-else-if="column == 'targetTimePeriod'">Target Time Period</div>
											<div v-else-if="column == 'reportingFrequency'">Reporting Frequency</div> -->
											<div v-else>{{column}}</div>
											<div class="sort cursor-pointer" :class="className == column && (this.selectedTabIndex == 0 && this.kpisListForPresentControl.length != 0) || (this.selectedTabIndex == 1 && this.allRootKpis.length != 0) ? classSort : 'opacity-50'"></div>
										</div>
									</div>
								</th>
								<tr v-if="showFilters">
									<td v-for="name in tableHeads" class="bg-white" :key="name">
										<select class="inputboxstyling resize" v-if="name == 'targetTimePeriod' || name == 'reportingFrequency'" v-model="filters[name]">
											<option :value="filters.name" selected disabled hidden>Select</option>
											<option v-for="(value, index) in controlRecurringArray" :key="index" :value="value.id">{{ value.label }}</option>
										</select>
										<input v-else type="text" class="inputboxstyling" placeholder="Search" v-model="filters[name]" />
									</td>
								</tr>
							</thead>
							<tbody v-if="columnObject || sortedAppliedKpisData.length === 0">
								<tr class="cursor-pointer rowHeight">
									<td class="relative h-10" :colspan="tableHeads.length">
										<div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
									</td>
								</tr>
							</tbody>
							<tbody v-else class="tbodylast">
								<tr v-for="(kpiData, index) in sortedAppliedKpisData" :key="kpiData._id" @click="showEditPopUp(kpiData)" :class="index % 2 == 0 ? 'odd' : 'even'">
									<td v-for="column in tableHeads" :key="column" @mouseout="doFalse()" class="whitespace-nowrap cursor-pointer relative">
										<div v-if="column == 'targetTimePeriod' || column == 'reportingFrequency'">
											<div class="overflow-hidden table-ellipsis">
												<div class="inline">
													{{
														controlRecurringArray.find((itemOne) => {
															return itemOne.id == kpiData[column];
														}).label
													}}
												</div>
											</div>
										</div>
										<div v-else>
											<div class="overflow-hidden table-ellipsis">
												<div class="inline">
													{{ kpiData[column] }}
												</div>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<div v-if="sortedAppliedKpisData.length != 0">
							<pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedAppliedKpisData"></pagination>
						</div>
					</div>
				</div>

				<div v-if="selectedTabIndex == 1">
					<div class="ResponsivetableCornerRadius bg-white">
						<table>
							<thead>
								<th class="header w-24" v-if="allRootKpis.length != 0"></th>
								<th scope="col" v-for="column in tableHeads" :key="column" class="bg-white cursor-pointer capitalize" @click="sort(column)">
									<div>
										<div class="flex">
											<div v-if="column == 'description'">KPI Name</div>
											<div v-else-if="column == 'weightage'">weightage (%)</div>
											<!-- <div v-else-if="column == 'target'">Target (%)</div>
											<div v-else-if="column == 'targetTimePeriod'">Target Time Period</div>
											<div v-else-if="column == 'reportingFrequency'">Reporting Frequency</div> -->
											<div v-else>{{column}}</div>
											<div class="sort cursor-pointer" :class="className == column && (this.selectedTabIndex == 0 && this.kpisListForPresentControl.length != 0) || (this.selectedTabIndex == 1 && this.allRootKpis.length != 0) ? classSort : 'opacity-50'"></div>
										</div>
									</div>
								</th>
								<tr v-if="showFilters">
									<td v-if="allRootKpis.length != 0"></td>
									<td v-for="name in tableHeads" class="bg-white" :key="name">
										<select class="inputboxstyling resize" v-if="name == 'targetTimePeriod' || name == 'reportingFrequency'" v-model="filters[name]">
											<option :value="filters.name" selected disabled hidden>Select</option>
											<option v-for="(value, index) in controlRecurringArray" :key="index" :value="value.id">{{ value.label }}</option>
										</select>
										<input v-else type="text" class="inputboxstyling" placeholder="Search" v-model="filters[name]" />
									</td>
								</tr>
							</thead>
							<tbody v-if="columnObject || sortedData.length === 0">
								<tr class="cursor-pointer rowHeight">
									<td class="relative h-10" :colspan="tableHeads.length">
										<div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
									</td>
								</tr>
							</tbody>
							<tbody v-else class="tbodylast">
								<tr v-for="(kpiData, index) in sortedData" :key="kpiData._id" :class="index % 2 == 0 ? 'odd' : 'even'">
									<td>
										<div class="flex items-center ml-4">
											<input type="checkbox" name="checkbox" class="checkbox cursor-pointer" :value="kpiData" v-model="selectedRootKpis" @change="calculateWeightAge(1, $event, kpiData.weightage)" />
										</div>
									</td>
									<td v-for="column in tableHeads" :key="column" @mouseout="doFalse()" class="whitespace-nowrap cursor-pointer relative">
										<div v-if="column == 'targetTimePeriod' || column == 'reportingFrequency'">
											<div class="overflow-hidden table-ellipsis">
												<div class="inline">
													{{
														controlRecurringArray.find((itemOne) => {
															return itemOne.id == kpiData[column];
														}).label
													}}
												</div>
											</div>
										</div>
										<div v-else>
											<div class="overflow-hidden table-ellipsis">
												<div class="inline">
													{{ kpiData[column] }}
												</div>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<div v-if="sortedData.length != 0">
							<pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData" v-if="!columnObject"></pagination>
						</div>
					</div>
				</div>
			</div>

			<div class="flex items-center justify-between text-sm mt-6">
				<div class="ml-2">Total Weightage is: {{ showTotalKpisWeightage }} %</div>

				<div v-if="selectedTabIndex == 1">
					<button class="mr-2" @click="saveKpisForPresentRootControl(selectedRootKpis)" :class="selectedRootKpis.length > 0 ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
				</div>
			</div>
 </div>
			<popup v-if="showPopup" :popUp="showPopup">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2">Manage KPI</h1>
						<button
							class="float-right text-3xl"
							@click="
								onCancelbtn();
								showPopup = false;
							"
						>
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<fieldset>
					<div>
						<div>
							<div class="statuscontainer popupbodycontainer">
								<div class="formcontainer flex flex-col">
									<!-- <div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel">KPI ID</label>
											<input
												type="text"
												class="inputboxstyling"
												v-model="addingKpiObj.kpi_id"
												placeholder="KPI ID"
												disabled
												
											/>
											
										</div>
									</div> -->
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel">KPI Name</label>
											<textarea
												type="text"
												class="inputboxstyling h-20 max-h-48"
												v-model="addingKpiObj.description"
												placeholder="KPI Name"
												:class="{
													'is-invalid': v$.addingKpiObj.description.$error
												}"
											></textarea>
											<div v-if="v$.addingKpiObj.description.$error" class="text-red-500">
												<div class="error-text" v-if="v$.addingKpiObj.description.required">Required</div>
											</div>
										</div>
									</div>
						
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-6/12">
											<label class="controllabel">Weightage (%)</label>
											<input
												type="number"
												class="inputboxstyling mt-2"
												v-model="addingKpiObj.weightage"
												maxlength="3"
												ref="editWeight"
												placeholder="Weightage (%)"
												@keyup="weightageLessThanZero(addingKpiObj.weightage) || weightageGreaterThanZero(addingKpiObj) && handleInput"
												:class="{
												'is-invalid': v$.addingKpiObj.weightage.$error || validtaionWeightageError || numberLessThanZero,
												}"
											/>
											<div v-if="v$.addingKpiObj.weightage.$error || validtaionWeightageError || numberLessThanZero" class="text-red-500">
												<div class="error-text" v-if="v$.addingKpiObj.weightage.required.$invalid">
													Weightage (%) is required
												</div>
												<div class="error-text" v-if="(validtaionWeightageError && !v$.addingKpiObj.weightage.required.$invalid) || (numberLessThanZero == true && !v$.addingKpiObj.weightage.required.$invalid)">
													Weightage(%) is Invalid
												</div>
											</div>
											<!-- <input
												type="number"
												class="inputboxstyling"
												v-model="addingKpiObj.weightage"
												maxlength="3"
												ref="editWeight"
												placeholder="Weightage (%)"
												:class="{
													'is-invalid': v$.addingKpiObj.weightage.$error
												}"
											/>
											<div v-if="v$.addingKpiObj.weightage.$error" class="text-red-500">
												<div class="error-text" v-if="v$.addingKpiObj.weightage.required">Weightage (%) is required</div>
												<div class="error-text" v-else-if="v$.addingKpiObj.weightage.numeric.$invalid">Weightage(%) is Invalid</div>
											</div> -->
										</div>
										<!-- <div class="w-6/12 p-2">
											<label class="controllabel">Target (%)</label>
											<input
												type="number"
												class="inputboxstyling"
												v-model="addingKpiObj.target"
												placeholder="Target (%)"
												maxlength="3"
												:class="{
													'is-invalid': v$.addingKpiObj.target.$error
												}"
											/>
											<div v-if="v$.addingKpiObj.target.$error" class="text-red-500">
												<div class="error-text" v-if="v$.addingKpiObj.target.required">Target (%) is Required</div>
												<div class="error-text" v-else-if="v$.addingKpiObj.target.numeric.$invalid">Target (%) is Invalid</div>
											</div>
										</div> -->
									</div>
									<!-- <div class="row flex flex-row w-full">
										<div class="w-6/12 p-2">
											<label class="controllabel">Reporting Frequency</label>
											<select
												class="inputboxstyling"
												v-model="addingKpiObj.reportingFrequency"
												@change="emptyTargetTimePeriod(addingKpiObj)"
												:class="{
													'is-invalid': v$.addingKpiObj.reportingFrequency.$error
												}"
											>
												<option value="" selected disabled hidden>Select</option>
												<option v-for="value in controlRecurringArray" :key="value" :value="value.id">{{ value.label }}</option>
											</select>
											<div v-if="v$.addingKpiObj.reportingFrequency.$error" class="text-red-500">
												<div class="error-text" v-if="v$.addingKpiObj.reportingFrequency.required">Required</div>
											</div>
										</div>
										<div class="w-6/12 p-2">
											<label class="controllabel">Target Time Period</label>
											<select
												class="inputboxstyling"
												v-model="addingKpiObj.targetTimePeriod"
												:class="{
													'is-invalid': v$.addingKpiObj.targetTimePeriod.$error
												}"
												:disabled="!addingKpiObj.reportingFrequency"
											>
												<option value="" selected disabled hidden>Select</option>
												 <option v-for="value in filteredTimePeriods" :key="value" :value="value.id">{{ value.label }}</option>
												<option v-for="value in controlRecurringArray" :key="value" :value="value.id" :disabled="value.id < addingKpiObj.reportingFrequency">{{ value.label }}</option>
											</select>
											<div v-if="v$.addingKpiObj.targetTimePeriod.$error" class="text-red-500">
												<div class="error-text" v-if="v$.addingKpiObj.targetTimePeriod.required">Required</div>
											</div>
										</div>
									</div> -->
									<!-- <h1 v-if="hideFormula">Metrics</h1> -->
									<!-- <div class="row flex w-full gap-16">
										<div class="w-4/6">
											<div class="p-2.5">
												<div v-for="(metric, metricIndex) in addingKpiObj.metrics" :key="metricIndex">
													<label class="controllabel">{{ metric.key }}</label>
													<input
														type="text"
														class="inputboxstyling"
														v-model="addingKpiObj.metrics[metricIndex].label"
														:class="{
															'is-invalid': v$.addingKpiObj.metrics.$error && !v$.addingKpiObj.metrics.$each.$response.$data[metricIndex].label.required
														}"
													/>
												</div>
												<div class="text-red-500" v-if="v$.addingKpiObj.metrics.$error">
													<div class="error-text" v-if="v$.addingKpiObj.metrics.$dirty || addingKpiObj.metrics.length != 2">Metrics A and B are required</div>
												</div>
											</div>
										</div>

										<div class="w-2/6 flex justify-center items-center" v-if="hideFormula">
											<div class="">
												<label class="controllabel font-normal text-sm mb-1">Formula</label>
												<input type="text" class="inputboxstyling" :disabled="true" v-model="addingKpiObj.isformula" placeholder="Formula" />
											</div>
										</div>
									</div> -->
								</div>
							</div>
						</div>
						<div v-if="addingKpiObj._id" class="buttonposition flex justify-center items-center mb-4 mx-0">
							<button class="mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn()">Cancel</button>
							<button :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdateKpiForControlManual(addingKpiObj)">Update</button>
						</div>
						<div v-else class="buttonposition flex justify-center items-center mb-4 mx-0">
							<!-- <button class="mr-3" :class="addingKpiObj.metrics.length >= 2 ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="addMetrics(addingKpiObj)">Add Metrics</button> -->
							<button class="mr-1.5" :class="disableStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="onCancelbtn()">Cancel</button>
							<button :class="disableStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="addOrUpdateKpiForControlManual(addingKpiObj)">Save</button>
						</div>
					</div>
				</fieldset>
			</popup>
		</div>
	</div>
</template>
<style scoped>
.Vue-Toastification__container {
	z-index: 999999999 !important;
}
.anime-left {
	animation-name: moveLeft;
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-fill-mode: backwards;
}
@keyframes moveLeft {
	0% {
		opacity: 1;
		transform: translateX(100vw);
	}
	100% {
		transform: translateX(0);
	}
}
.anime-right {
	animation-name: moveRight;
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-fill-mode: backwards;
}
@keyframes moveRight {
	0% {
		opacity: 1;
		transform: translateX(-100vw);
	}
	100% {
		transform: translateX(0);
	}
}
.progressbarSI {
	border: 2px solid #e9e9e9;
	background: #e9e9e9;
	position: relative;
	top: -8px;
	border-radius:16px;
}
.progress {
	border-radius:16px;
    background: #152a43;
}
.tbodylast:last-child {
	border-bottom: 1px solid #e9e9e9;
}
.ResponsivetableCornerRadius{
	margin-top: 20px;
    border:1px solid #e9e9e9;
    border-radius: 4px !important;
    padding: 1px;
}
@media only screen and (max-width: 768px) {
  .ResponsivetableCornerRadius
  {
    margin-left:0px !important;
  }
}
.button-adjust {
	position: relative;
	top: -30px;
	z-index: 1;
	cursor: pointer;
}
.marginprev {
	margin-left: -20px;
}
.ProgessStyle{
	padding-bottom: 4px;
	z-index: 1;
}
.heading-ellipsis {
  width: 50%;
  display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>

<script lang="ts">
import { required } from '@vuelidate/validators';
import { helpers } from '@vuelidate/validators';
const { withParams } = helpers;
import loader from '../../components/loader.vue';
const numeric = helpers.regex('numeric', /^[0-9]+$/i);
const lessthanHundred = helpers.regex('lessthan', /^[1-9][0-9]?$|^100$/i);
const validateMetricsArray = withParams({ type: 'array' }, (value: any) => {
	return Array.isArray(value) && value.length > 0;
});
const customValidator = withParams({ type: 'array' }, validateMetricsArray);

import _ from 'lodash';
import { defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { emitter, toast } from '@/main';
import tableData from '@/components/tableData.vue';
import breadCrumb from '@/components/breadCrumb.vue';
import popup from '@/components/popup.vue';
import pagination from '@/components/pagination.vue';
import {v4 as uuidv4 } from 'uuid';

export default defineComponent({
	data(): any {
		return {
			// kpiClone: _.cloneDeep(this.addingKpiObj)	, // Deep clone the kpi object
			v$: useVuelidate(),
			showFilters: false,
			dummyList: [],
			hideFormula: false,
			addingKpiObj: {
				// kpi_id:'',
				weightage: '',
				description: '',
			
			},
			dummyObj: [],
			showPopup: false,
			columnObject: false,
			controlRecurringArray: [
				{ label: 'Daily', id: 1 },
				{ label: 'Weekly', id: 2 },
				{ label: 'Monthly', id: 3 },
				{ label: 'Quarterly', id: 4 },
				{ label: 'Half-Yearly', id: 5 },
				{ label: 'Yearly', id: 6 }
			],
			routesArray: [],
			allControls: [],
			presentControlObject: {},
			allRootKpis: [],
			kpisListForPresentControl: [],
			allRootKpisUnfiltered: [],
			carouselHead: [
				{ displayName: 'Active KPIs', name: 'Kpis', id: 0 },
				{ displayName: 'Related KPIs', name: 'SuggestedKpis', id: 1 }
			],
			selectedTabIndex: '',
			sideButton: false,
			styling: { left: '', bottom: '' },
			pageSize: 20,
			currentPage: 1,
			dynamicPagination: '',
			currentSort: 'name',
			currentSortDir: 'asc',
			classSort: '',
			className: '',
			validtaionWeightageError: false,
			numberLessThanZero : false,
			filters: {},
			selectedRootKpis: [],
			titleLength: 0,
			pop: false,
			showTotalKpisWeightage: 0,
			tableHeads: ['description', 'weightage'],
			pageItems: 6,
			currentItems: 1,
		};
	},
	components: {
		pagination,
		popup,
		breadCrumb
	},
	validations: {
		addingKpiObj: {
			weightage: { required },
			description: { required },
			
		}
	},
	async created() {
		await this.getAllRootKpis();
		await this.getAllRootControls();
		await this.toggleTabs(0);
		let routeControlId = this.$route.params.id;
		let controlObj;
		controlObj = this.allControls.find((item: any) => routeControlId === item._id);
		this.titleLength = controlObj.controlTitle ? controlObj.controlTitle.length:0
		if (this.$route.query.from == 'frameworks') {
			this.routesArray = [
				{ name: 'Frameworks', routeName: 'root-Frameworks' },
				{ name: controlObj.controlNumber, routeName: 'manageRootControlMapping' }
			];
		} else if (this.$route.query.from == 'internalControls') {
			this.routesArray = [
				{ name: 'Internal Controls', routeName: 'root-controls' },
				{ name: controlObj.controlNumber, routeName: 'manageRootControlMapping' }
			];
		}
	},
	computed: {
		sortedData: function (): any {
			return [...this.allRootKpis]
				.filter((row: any) => {
					for (const key in this.filters) {
						const inputValue = this.filters[key];
						if (inputValue !== '' || row[key]) {
							if (Number(inputValue) && isNaN(row[key])) {
								if (!String(row[key]).includes(inputValue)) {
									return false;
								}
							} else {
								if (!row[key].toString().toLowerCase().includes(inputValue.toString().toLowerCase())) {
									return false;
								}
							}
						}
					}
					return true;
				})
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					this.dynamicPagination = index + 1;
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		sortedAppliedKpisData: function (): any {
			return [...this.kpisListForPresentControl]
				.filter((row: any) => {
					for (const key in this.filters) {
						const inputValue = this.filters[key];
						if (inputValue !== '' || row[key]) {
							if (Number(inputValue) && isNaN(row[key])) {
								if (!String(row[key]).includes(inputValue)) {
									return false;
								}
							} else {
								if (!row[key].toString().toLowerCase().includes(inputValue.toString().toLowerCase())) {
									return false;
								}
							}
						}
					}
					return true;
				})
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					this.dynamicPagination = index + 1;
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		getControlId(): any {
			return this.$route.params.id;
		},
		disableStatus: function (): any {
			let output;
			if (this.editPop) {
				let objCopy = Object.assign({}, this.addingKpiObj);
				if (_.isEqual(objCopy, this.dummyObj)) {
					output = false;
				} else {
					output = true;
				}
			} else {
				if (_.isEqual(this.addingKpiObj, this.dummyObj)) {
					output = true;
				} else {
					output = false;
				}
			}
			return output;
		},
		cantGoBack(): any {
			return this.currentItems === 1;
		},
		cantGoForward(): any {
			return this.carouselHead.length / this.pageItems <= this.currentItems;
		},
		tabHead():any {
        const start = (this.currentItems - 1) * this.pageItems;
        const end = this.currentItems * this.pageItems;
        return this.carouselHead.slice(start, end);
       },
		// filteredTimePeriods(): any {
		// 	if (this.addingKpiObj.reportingFrequency) {
		// 		return this.controlRecurringArray.filter((value: any) => value.id >= this.addingKpiObj.reportingFrequency);
		// 	} else {
		// 		return [];
		// 	}
		// }
	},
	methods: {
		updateSelectedFrame() {
            // Update selectedFrame based on the first tab in the current page
            this.selectedFrame = this.tabHead[0]?.name || null;
        },

		async nextPage() {
        if ((this.currentItems * this.pageItems) < this.carouselHead.length) {
            this.currentItems = this.currentItems + 1;
            this.updateSelectedTabIndex(); // Update selectedTabIndex after navigation
            this.updateCarouselClass(); // Update carousel layout after navigation
        }
    },
      async prevPage() {
        if (this.currentItems > 1) {
            this.currentItems = this.currentItems - 1;
            this.updateSelectedTabIndex(); // Update selectedTabIndex after navigation
            this.updateCarouselClass(); // Update carousel layout after navigation
        }
    },
	updateSelectedTabIndex() {
        // Update selectedTabIndex based on the first tab in the current page
        // Adjust index based on the current page and pageItems
        this.selectedTabIndex = (this.currentItems - 1) * this.pageItems;
    },
	newWeightage(kpiObj: any, previousWeightage: number = 0) {
		const totalWeightage = this.showTotalKpisWeightage - previousWeightage + kpiObj.weightage;
		return totalWeightage;
    },
    handleInput(event : any) {
     	console.log('event',event)
      	this.addingKpiObj.weightage = event.target.value.replace(/[^\d]/g, '');
    },
	weightageLessThanZero(weightage : any){
		if(weightage < 0 || weightage == 0){
			this.numberLessThanZero = true
		}else{
			this.numberLessThanZero = false
		}
    },
	weightageGreaterThanZero(kpi: any){
        this.validtaionWeightageError = false;
        let previousWeightage = 0;
        let kpiId = kpi._id;
        if (kpiId){
            const existingKpi = this.kpisListForPresentControl.find((eachKPI: any) => eachKPI._id === kpiId);
            console.log('existingKpi',existingKpi)
            console.log("kpisListForPresentControl",this.kpisListForPresentControl)
            if (existingKpi) {
            	previousWeightage = existingKpi.weightage;
            }
        }
        const valid = this.newWeightage(kpi, previousWeightage);
        console.log('valid',valid)
        console.log("validvalidvalidvalid", valid);
        if (valid <= 100) {
            this.validtaionWeightageError = false;
            // this.showTotalKpisWeightage = valid;
        }else {
        	this.validtaionWeightageError = true;
        }
    },


		 updateCarouselClass() {
         const x = window.innerWidth;
         let className = '';

         if (x > 1280) {
         className = 'page-items-7';
         this.pageItems = 7;
         } else if (x >= 1024 && x <= 1280) {
          className = 'page-items-6';
         this.pageItems = 6;
         } else if (x >= 899 && x < 1024) {
          className = 'page-items-5';
          this.pageItems = 5;
         } else if (x >= 640 && x < 899) {
         className = 'page-items-4';
         this.pageItems = 4;
         } else {
         className = 'page-items-1';
         this.pageItems = 1;
         }

        const carouselContainer = this.$refs.carouselContainer;
        if (carouselContainer) {
        carouselContainer.className = '';
        carouselContainer.classList.add(className);
        }

    // Adjust currentItems if it exceeds the number of pages
    const totalItems = this.carouselHead.length;
    const totalPages = Math.ceil(totalItems / this.pageItems);
    if (this.currentItems > totalPages) {
      this.currentItems = totalPages;
    }
    if (this.currentItems < 1) {
      this.currentItems = 1;
    }
  },
		emptyTargetTimePeriod(kpi: any) {
			if (kpi.targetTimePeriod < kpi.reportingFrequency) {
				kpi.targetTimePeriod = '';
			}
		},
		addKPI() {
			if (this.kpisListForPresentControl.length < 10) {
				let numeric = 1;
				// this.allRootKpis.forEach((item: any) => {
				// 	if (Object.keys(item).includes("kpi_id") && item.kpi_id) {
				// 		const number = item.kpi_id.split(" ");
				// 		if (parseInt(number[2]) && !isNaN(parseInt(number[2]))) {
				// 		let intNumber = parseInt(number[2]);
				// 		numeric = Math.max(numeric, intNumber);
				// 		numeric++;
				// 		}
				// 	}
				// });
				// let id = "KPI - " + numeric;
				this.addingKpiObj = { weightage: '', description: '' };
				// this.addingKpiObj = { kpi_id: id, weightage: '', description: '' };
				this.hideFormula = false;
				this.showPopup = true;
				this.editPop = false;
				this.dummyObj = {...this.addingKpiObj};
				
			} else {
				toast.error('Maximum KPIs should be 10', {
					timeout: 4000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		onReset() {
			// reset form validation errors
			this.v$.$reset();
			// reset form data
			const initialData = this.$options.data.call(this);
			Object.assign(this.$data, initialData);
		},
		showEditPopUp(showObj: any) {
			this.v$.$reset();
			this.showPopup = true;
			// let id = uuidv4();
			// showObj.isformula = 'A/B';
			// console.log("idid", id);
			// this.addingKpiObj.kpi_id = id;
			// this.dummyObj = _.cloneDeep();
			this.editPop = true;
			this.addingKpiObj = { ...showObj };
			this.dummyObj = { ...this.addingKpiObj }
			this.hideFormula = true;
		},
		filterOpen() {
			if (this.columnObject == false) {
				this.showFilters = !this.showFilters;
				this.filters = {};
				emitter.emit('visible', this.showFilters);
			}
		},
		paginationUpdations(currentPage: any, pageSize: any) {
			this.currentPage = currentPage;
			this.pageSize = pageSize;
		},
		sort: function (s: any) {
			this.className = s;
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = s;
			this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
		},
		// addMetrics(kpi: { metrics: { label: string; key: string; value: string }[] }) {
		// 	this.hideFormula = true;
		// 	// this.addingKpiObj.isformula = 'A/B';
		// 	this.addingKpiObj.metrics.push({ label: '', key: 'A', value: '' });
		// 	this.addingKpiObj.metrics.push({ label: '', key: 'B', value: '' });
		// },
		onCancelbtn() {
			this.v$.$reset();
			this.validtaionWeightageError = false;
			this.numberLessThanZero = false;
			if (!this.addingKpiObj._id) {
				Object.keys(this.addingKpiObj).forEach((prop) => {
					Array.isArray(this.addingKpiObj[prop]) ? (this.addingKpiObj[prop] = []) : (this.addingKpiObj[prop] = '');
				});
				this.hideFormula = false;
			} else {
				this.addingKpiObj = {...this.dummyObj};
			}
		},
		async addOrUpdateKpiForControlManual(kpi: any) {
			this.v$.$touch();
			// this.validtaionWeightageError = false;
			if (!this.v$.$invalid && this.numberLessThanZero == false && this.validtaionWeightageError == false) {
			let previousWeightage = 0;
			let kpiId = kpi._id;

			if (kpiId) {
			// Find the previous weightage if KPI is being updated
			const existingKpi = this.kpisListForPresentControl.find((eachKPI: any) => eachKPI._id === kpiId);
			console.log("kpisListForPresentControl",this.kpisListForPresentControl)
			console.log('existingKpi',existingKpi)
			if (existingKpi) {
				previousWeightage = existingKpi.weightage;
			}
			}

			const valid = this.newWeightage(kpi, previousWeightage);
			console.log("validvalidvalidvalid", valid);

			if (valid <= 100) {
			// this.validtaionWeightageError = false;

			if (kpiId) {
				// Update the KPI
				await this.updateKpiForControl(kpi);
			} else {
				// Add new KPI
				await this.saveKpisForPresentRootControl(kpi, 'manual');
			}

			// Update showTotalKpisWeightage
			this.showTotalKpisWeightage = valid;

			this.showPopup = false;
			this.v$.$reset();
			} else {
			// this.validtaionWeightageError = true;
			}
			} else {
			toast.error(`error`, {
				timeout: 1000,
				closeOnClick: true,
				closeButton: "button",
				icon: true,
			});
			}
		},
		// async addOrUpdateKpiForControlManual(kpi: any) {
		// 	this.v$.$touch();
		// 	if (!this.v$.$invalid) {
		// 		if (Object.keys(kpi).includes('_id')) {
		// 			await this.updateKpiForControl(kpi);
		// 		} else {
		// 			await this.saveKpisForPresentRootControl(kpi);
		// 		}
		// 		this.showPopup = false;
		// 		this.v$.$reset();
		// 	} else {
		// 		toast.error(`error`, {
		// 			timeout: 1000,
		// 			closeOnClick: true,
		// 			closeButton: 'button',
		// 			icon: true
		// 		});
		// 	}
		// },
		async getAllRootControls() {
			this.presentControlObject = {};
			this.kpisListForPresentControl = [];
			try {
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/controls/getall`).then((result: { data: any }) => {
					this.allControls = result.data;
					let routeControlId = this.$route.params.id;
					let mainObj = result.data.find((eachItem: any) => {
						return routeControlId == eachItem._id;
					});
					if(mainObj != undefined){
						this.presentControlObject = mainObj;
					}
					if (mainObj != undefined && Object.keys(mainObj).includes('kpis')) {
						// this.presentControlObject = mainObj;
						this.kpisListForPresentControl = mainObj.kpis;

						let mappedKpis: any = [];
						this.kpisListForPresentControl.map((obj: any) => {
							mappedKpis.push(obj.description);
						});

						if (mappedKpis.length > 0) {
							let filteredRootKpis = this.allRootKpis.filter((obj: any) => {
								return !mappedKpis.includes(obj.description);
							});
							this.allRootKpis = filteredRootKpis;
						}
					} else {
						this.kpisListForPresentControl = [];
					}
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		doFalse() {
			this.pop = false;
		},
		async getAllRootKpis() {
			this.allRootKpis = [];
			this.allRootKpisUnfiltered = [];

			try {
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/kpis/get`).then((result: { data: any }) => {
					this.allRootKpisUnfiltered = [...result.data];
					this.allRootKpis = result.data;
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		async toggleTabs(navIndex: any) {
			this.selectedTabIndex = navIndex;
			this.calculateWeightAge(0);
			this.selectedRootKpis = [];
			this.showFilters = false;
			this.filters = {};
		},
		async saveKpisForPresentRootControl(kpiObj: any) {
			let finalArr: any[] = [];
			let routeControlId = this.$route.params.id;
			let controlObj;
			controlObj = this.allControls.find((item: any) => routeControlId === item._id);
			if (this.showTotalKpisWeightage <= 100) {
				if (this.selectedTabIndex == 1) {
					this.selectedRootKpis.map((obj: any) => {
						obj.referenceKpiId = obj._id;
						delete obj._id;
						finalArr.push(obj);
					});
				} else {
					finalArr.push(kpiObj);
				}
				let controlPayLoadFramework;
				if (this.$route.query.from == 'frameworks') {
					controlPayLoadFramework = controlObj.controlFrameWork;
				} else if (this.$route.query.from == 'internalControls') {
					controlPayLoadFramework = 'InternalControls';
				}
				await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/control/${this.$route.params.id}/kpis/add?frameWork=${controlPayLoadFramework}`, finalArr).then(async () => {
					toast.info('Updated', {
						timeout: 5000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.selectedRootKpis = [];
					await this.getAllRootControls();
					await this.toggleTabs(0);
				});
			} else {
				toast.error('Total Weightage should be less than 100', {
					timeout: 5000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		calculateWeightAge(tabIndex: any, e: any, weightage: any) {
			if (tabIndex == 1) {
				const checked = e.target.checked;
				this.showTotalKpisWeightage == 0 ? (this.showTotalKpisWeightage = 0) : '';

				if (checked) {
					this.showTotalKpisWeightage = this.showTotalKpisWeightage + weightage;
				} else {
					this.showTotalKpisWeightage = this.showTotalKpisWeightage - weightage;
				}
				// this.selectedRootKpis.map((obj:any)=>{this.showTotalKpisWeightage = this.showTotalKpisWeightage + obj.weightage})
			} else {
				this.showTotalKpisWeightage = 0;
				this.kpisListForPresentControl.map((obj: any) => {
					this.showTotalKpisWeightage = this.showTotalKpisWeightage + obj.weightage;
				});
			}
		},
		async updateKpiForControl(kpiObj: any) {
			let routeControlId = this.$route.params.id;
			let controlObj;
			controlObj = this.allControls.find((item: any) => routeControlId === item._id);
			let kpiId = kpiObj._id;
			delete kpiObj._id;
			let controlPayLoadFramework;
			if (this.$route.query.from == 'frameworks') {
				controlPayLoadFramework = controlObj.controlFrameWork;
			} else if (this.$route.query.from == 'internalControls') {
				controlPayLoadFramework = 'InternalControls';
			}
			await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/control/${routeControlId}/kpi/${kpiId}/update?frameWork=${controlPayLoadFramework}`, kpiObj).then(async () => {
				toast.info('Updated', {
					timeout: 5000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				this.selectedRootKpis = [];
				await this.getAllRootControls();
				await this.toggleTabs(0);
				this.showPopup = false;
			});
		}
	},
	mounted() {
    window.addEventListener('resize', this.updateCarouselClass);
    this.updateCarouselClass(); 
},
});
</script>