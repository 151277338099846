<template>
    <div class="rootContainer" v-if="!loading">
        <component v-bind:is="layout">
     <router-view />
        </component>
    </div>
    <div v-else class="fixed left-2/4 top-2/4" >
        <div>
        <!-- <spinner/> -->
        <loader />
        </div>
    </div>
</template>
<style  scoped>
.rootContainer {
    background-color: #F9F9FB;
    height: 100vh;
    overflow-y:hidden;
}
</style>
<script lang="ts">
import emptylayout from './pages/emptyLayout.vue';
import loader from './components/loader.vue';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
    components: {
        loader,
        emptylayout
    },
    computed: {
        layout() {
            return this.$route.meta.layout || emptylayout;
        },
        ...mapGetters({ loading: 'loading' })
    }
});
</script>